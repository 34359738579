import React from 'react';

import PrivateRoute from 'src/components/PrivateRoute';
import UsersEditContainer from 'src/containers/Users/UsersEdit';
import UsersListContainer from 'src/containers/Users/UsersList';
import UsersNewContainer from 'src/containers/Users/UsersNew';

const UserRoutes = (isUserLogged: boolean) => {
  return [
    <PrivateRoute
      key="list"
      path="/users"
      exact
      component={UsersListContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="new"
      path="/users/new"
      component={UsersNewContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="edit"
      path="/users/edit/:id"
      component={UsersEditContainer}
      isUserLogged={isUserLogged}
    />,
  ];
};

export default UserRoutes;
