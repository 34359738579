import { FormControl, FormHelperText, SelectChangeEvent } from '@mui/material';
import i18next from 'i18next';
import React, { useState } from 'react';

import TooltipHelp from 'src/components/TooltipHelp';

import './i18n';
import { Input, InputOption, Label, WrapperSelectInput } from './styles';
import { ISelectInputProps } from './types';

const SelectInput = (props: ISelectInputProps) => {
  const {
    options,
    id,
    name,
    label,
    value,
    error = '',
    disabled = false,
    defaultOpen = false,
    pathI18nHelp = '',
    onChange,
  } = props;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    onChange(event);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <WrapperSelectInput>
      <FormControl fullWidth>
        <Label id={`${id}-label`}>{label}</Label>
        <Input
          fullWidth
          data-testid="select-field"
          inputProps={{ 'data-testid': 'select-input' }}
          id={id}
          labelId={`${id}-label`}
          name={name}
          value={value}
          label={label}
          open={isOpen}
          disabled={disabled}
          error={error !== ''}
          onChange={handleChange}
          onOpen={handleOpen}
          onClose={handleClose}
        >
          {options.map((op) => {
            return (
              <InputOption data-testid="select-input-option" key={op.value} value={op.value}>
                {op.text}
              </InputOption>
            );
          })}
        </Input>
        {error !== '' && (
          <FormHelperText data-testid="select-input-error-block" error={true} variant="outlined">
            {error}
          </FormHelperText>
        )}
      </FormControl>
      {i18next.t(`SELECT_INPUT:${pathI18nHelp}`) !== '' && (
        <TooltipHelp text={i18next.t(`SELECT_INPUT:${pathI18nHelp}`)} />
      )}
    </WrapperSelectInput>
  );
};

export default SelectInput;
