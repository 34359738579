import { SelectChangeEvent } from '@mui/material';
import i18next from 'i18next';
import React from 'react';

import Form from 'src/components/Form';
import SelectInput from 'src/components/Inputs/SelectInput';
import { InisghtType } from 'src/helpers/enums/insights';

import './i18n';
import { FormWrapper, SelectWrapper, Title } from './styles';
import { IInsightsNewProps } from './types';

const InsightsNew = (props: IInsightsNewProps) => {
  const {
    loading,
    inputsTip,
    inputsComment,
    onSubmitForm,
    formType,
    onChangeFormType,
    inputsVideo,
  } = props;

  const handleChange = (event: SelectChangeEvent<any>) => {
    onChangeFormType(event.target.value);
  };

  const handleInputs = () => {
    switch (formType) {
      case InisghtType.COMMENT:
        return inputsComment;
      case InisghtType.VIDEO:
        return inputsVideo;
      default:
        return inputsTip;
    }
  };

  return (
    <FormWrapper>
      <Title> {i18next.t('INSIGHTS_NEW:TITLE')}</Title>
      <SelectWrapper>
        <SelectInput
          id="type"
          name="type"
          label="Insight Type"
          value={formType}
          options={[
            {
              value: InisghtType.TIP,
              text: InisghtType.TIP,
            },
            { value: InisghtType.COMMENT, text: InisghtType.COMMENT },
            { value: InisghtType.VIDEO, text: InisghtType.VIDEO },
          ]}
          onChange={handleChange}
        />
      </SelectWrapper>
      {formType !== '' && (
        <Form
          inputs={handleInputs()}
          onSubmit={onSubmitForm}
          isLoading={loading}
          submitText={i18next.t('INSIGHTS_NEW:SUBMIT_BUTTON')}
        />
      )}
    </FormWrapper>
  );
};

export default InsightsNew;
