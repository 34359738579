import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const materialityListSelector = () =>
  createSelector([(state: IReduxState) => state.materiality.materiality], (materiality) => {
    if (!materiality) return [];
    return materiality;
  });

export default materialityListSelector;
