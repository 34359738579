import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const isUserLoggedSelector = () =>
  createSelector([(state: IReduxState) => state.auth.user], (user) => {
    return user !== null;
  });

export default isUserLoggedSelector;
