import i18next from 'i18next';
import React from 'react';

import Form from 'src/components/Form';

import './i18n';
import { FormWrapper, Title } from './styles';
import { INewNewsQueryProps } from './types';

const NewNewsQuery = (props: INewNewsQueryProps) => {
  const { loading, inputs, onSubmitForm } = props;

  return (
    <FormWrapper>
      <Title> {i18next.t('NEW_NEWS_QUERY:TITLE')}</Title>
      <Form
        inputs={inputs}
        onSubmit={onSubmitForm}
        isLoading={loading}
        submitText={i18next.t('NEW_NEWS_QUERY:SUBMIT_BUTTON')}
      />
    </FormWrapper>
  );
};

export default NewNewsQuery;
