import * as newsQueryConstants from 'src/redux/constants/newsQuery';

import { ICompany } from 'src/types/company';
import { INewQuery, INewsQuery } from 'src/types/newsQuery';

export const initializeNewsQuerysListView = () => ({
  type: newsQueryConstants.INITIALIZE_NEWS_QUERYS_LIST_VIEW_REQUESTED,
});

export const initializeNewsQuerysListViewSucceeded = (newsQuerys: INewsQuery[]) => ({
  type: newsQueryConstants.INTIIALIZE_NEWS_QUERYS_LIST_VIEW_SUCCEEDED,
  newsQuerys,
});

export const initializeNewsQuerysListViewFailed = (errorMessage: string) => ({
  type: newsQueryConstants.INTIIALIZE_NEWS_QUERYS_LIST_VIEW_FAILED,
  errorMessage,
});

export const onChangeActiveQuery = (value: boolean, newsQuerysId: string) => ({
  type: newsQueryConstants.NEWS_QUERYS_ON_CHANGE_ACTIVE_QUERY,
  value,
  newsQuerysId,
});

export const onChangeActiveQuerySucceeded = (newsQuerys: INewsQuery) => ({
  type: newsQueryConstants.NEWS_QUERYS_ON_CHANGE_ACTIVE_QUERY_SUCCEEDED,
  newsQuerys,
});

export const onChangeActiveQueryFailed = (errorMessage: string) => ({
  type: newsQueryConstants.NEWS_QUERYS_ON_CHANGE_ACTIVE_QUERY_FAILED,
  errorMessage,
});

export const onDeleteOneRequested = (newQueryId: string) => ({
  type: newsQueryConstants.NEWS_QUERYS_ON_DELETE_ONE_REQUESTED,
  newQueryId,
});

export const onDeleteOneSucceeded = (newQueryId: string) => ({
  type: newsQueryConstants.NEWS_QUERYS_ON_DELETE_ONE_SUCCEEDED,
  newQueryId,
});

export const onDeleteOneFailed = (errorMessage: string) => ({
  type: newsQueryConstants.NEWS_QUERYS_ON_DELETE_ONE_FAILED,
  errorMessage,
});

export const onDeleteManyRequested = (newsQueryIds: string[]) => ({
  type: newsQueryConstants.NEWS_QUERYS_ON_DELETE_MANY_REQUESTED,
  newsQueryIds,
});

export const onDeleteManySucceeded = (newQueryIds: string[], countDeleted: number) => ({
  type: newsQueryConstants.NEWS_QUERYS_ON_DELETE_MANY_SUCCEEDED,
  newQueryIds,
  countDeleted,
});

export const onDeleteManyFailed = (errorMessage: string) => ({
  type: newsQueryConstants.NEWS_QUERYS_ON_DELETE_MANY_FAILED,
  errorMessage,
});

export const onCreateOneRequested = (newsQuery: INewQuery) => ({
  type: newsQueryConstants.NEWS_QUERYS_ON_CREATE_ONE_REQUESTED,
  newsQuery,
});

export const onCreateOneSucceeded = (newsQuery: INewsQuery[]) => ({
  type: newsQueryConstants.NEWS_QUERYS_ON_CREATE_ONE_SUCCEEDED,
  newsQuery,
});

export const onCreateOneFailed = (errorMessage: string) => ({
  type: newsQueryConstants.NEWS_QUERYS_ON_CREATE_ONE_FAILED,
  errorMessage,
});

export const initializeNewsQueryCreateView = () => ({
  type: newsQueryConstants.INTIALIZE_NEWS_QUERYS_CREATE_VIEW_REQUESTED,
});

export const initializeNewsQueryCreateViewSucceeded = (companies: ICompany[]) => ({
  type: newsQueryConstants.INTIALIZE_NEWS_QUERYS_CREATE_VIEW_SUCCEEDED,
  companies,
});

export const initializeNewsQueryCreateViewFailed = (errorMessage: string) => ({
  type: newsQueryConstants.INTIALIZE_NEWS_QUERYS_CREATE_VIEW_FAILED,
  errorMessage,
});

export const initializeNewsQueryEditView = (newId: string) => ({
  type: newsQueryConstants.INTIALIZE_NEWS_QUERYS_EDIT_VIEW_REQUESTED,
  newId,
});

export const initializeNewsQueryEditViewSucceeded = (
  newsQuery: INewsQuery,
  companies: ICompany[],
) => ({
  type: newsQueryConstants.INTIALIZE_NEWS_QUERYS_EDIT_VIEW_SUCCEEDED,
  newsQuery,
  companies,
});

export const initializeNewsQueryEditViewFailed = (errorMessage: string) => ({
  type: newsQueryConstants.INTIALIZE_NEWS_QUERYS_EDIT_VIEW_FAILED,
  errorMessage,
});

export const onEditOneRequested = (newsQueryId: string, newsQuery: INewQuery) => ({
  type: newsQueryConstants.NEWS_QUERYS_ON_EDIT_ONE_REQUESTED,
  newsQueryId,
  newsQuery,
});

export const onEditOneSucceeded = (newsQuery: INewsQuery) => ({
  type: newsQueryConstants.NEWS_QUERYS_ON_EDIT_ONE_SUCCEEDED,
  newsQuery,
});

export const onEditOneFailed = (errorMessage: string) => ({
  type: newsQueryConstants.NEWS_QUERYS_ON_EDIT_ONE_FAILED,
  errorMessage,
});

export const onRunQueryRequested = (newsQueryId: string) => ({
  type: newsQueryConstants.NEWS_QUERY_RUN_REQUESTED,
  newsQueryId,
});

export const onRunQuerySucceeded = (newsQuerys: INewsQuery) => ({
  type: newsQueryConstants.NEWS_QUERY_RUN_SUCCEEDED,
  newsQuerys,
});

export const onRunQueryFailed = (errorMessage: string) => ({
  type: newsQueryConstants.NEWS_QUERY_RUN_FAILED,
  errorMessage,
});
