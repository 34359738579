import { GridCellParams, GridColumns, GridRowId, GridRowParams } from '@mui/x-data-grid';
import React, { useState, ReactElement } from 'react';

import { MAX_DOCUMENTS_PER_PAGE } from 'src/helpers/constants';
import { ActionTypes } from 'src/helpers/enums/tableActions';

import CustomActionButton from './components/Custom';
import DeleteActionButton from './components/Delete';
import EditActionButton from './components/Edit';
import Filter from './components/Filter';
import ShowActionButton from './components/Show';
import Toolbar from './components/Toolbar';
import { DataTable, TableWrapper } from './styles';
import { ITableProps } from './types';

const Table = (props: ITableProps) => {
  const {
    columns,
    rows,
    title,
    loading,
    totalRows,
    page,
    filter = [],
    includeCheckboxes = true,
    rowActions = [ActionTypes.EDIT, ActionTypes.DELETE],
    toolbarActions = [ActionTypes.CREATE, ActionTypes.BULK_DELETE],
    customRowActions = [],
    error = null,
    pageSize = MAX_DOCUMENTS_PER_PAGE,
    rowHeight = 52,
    autoHeight = false,
    disableVirtualization = false,
    toolbar = true,
    editMode = 'row',
    onNextPage = () => {},
    onAddNew = () => {},
    onDeleteOne = () => {},
    onDeleteMany = () => {},
    onEdit = () => {},
    onCellEdit = () => {},
    onShow = () => {},
  } = props;
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const handleShowRow = (row: GridRowParams) => {
    onShow(row.id.toString());
  };

  const handleEditRow = (row: GridRowParams) => {
    onEdit(row.id.toString());
  };

  const handleDeleteRow = (row: GridRowParams) => {
    onDeleteOne(row.id.toString());
  };

  const handleBulkDelete = () => {
    onDeleteMany(selectedRows);
  };

  const handleRowSelected = (selectedRows: GridRowId[]) => {
    setSelectedRows(selectedRows.map((id) => id.toString()));
  };

  const handleCellEdit = (params: GridCellParams) => {
    onCellEdit(params.row.id, params.field, params.getValue(params.row.id, params.field));
  };

  const actionColumns: GridColumns = [
    ...columns,
    {
      field: 'Actions',
      type: 'actions',
      flex: 1,
      align: 'center',
      getActions: (row: GridRowParams) => {
        let actions: ReactElement[] = [];
        const customActions = customRowActions.map((customAction) => {
          return (
            <CustomActionButton
              rowId={row.id.toString()}
              disabled={customAction.disabled(row.id.toString())}
              loading={customAction.loading}
              button={customAction.button}
              tooltip={customAction.tooltip}
              onClick={customAction.onClick}
            />
          );
        });
        rowActions.forEach((rowAction) => {
          switch (rowAction) {
            case ActionTypes.DELETE:
              actions.push(<DeleteActionButton onClick={() => handleDeleteRow(row)} />);
              break;
            case ActionTypes.EDIT:
              actions.push(<EditActionButton onClick={() => handleEditRow(row)} />);
              break;
            case ActionTypes.SHOW:
              actions.push(<ShowActionButton onClick={() => handleShowRow(row)} />);
              break;
          }
        });
        return actions.concat(customActions);
      },
    },
  ];

  return (
    <TableWrapper>
      <DataTable
        disableColumnFilter
        disableVirtualization={disableVirtualization}
        paginationMode={totalRows ? 'server' : 'client'}
        rowCount={totalRows || rows.length}
        columns={rowActions.length > 0 ? actionColumns : columns}
        rows={rows}
        rowHeight={rowHeight}
        pageSize={pageSize}
        page={page}
        loading={loading}
        editMode={editMode}
        onCellEditStop={editMode === 'cell' ? handleCellEdit : undefined}
        error={error}
        rowsPerPageOptions={[pageSize]}
        onCellDoubleClick={(params, event) => {
          if (!event.ctrlKey && editMode === 'row') {
            event.defaultMuiPrevented = true;
          }
        }}
        onPageChange={onNextPage}
        onSelectionModelChange={handleRowSelected}
        checkboxSelection={includeCheckboxes}
        disableSelectionOnClick
        autoHeight={autoHeight}
        components={{
          Toolbar: () =>
            toolbar ? (
              <Toolbar
                onAddNew={onAddNew}
                onBulkDelete={handleBulkDelete}
                title={title}
                toolbarActions={toolbarActions}
              />
            ) : null,
        }}
      />
      {filter.length > 0 && <Filter filter={filter} />}
    </TableWrapper>
  );
};

export default Table;
