import { AnyAction } from 'redux';
import { takeLatest, all, call, put } from 'redux-saga/effects';

import * as userActions from 'src/redux/actions/user';
import * as userConstants from 'src/redux/constants/user';
import * as authService from 'src/redux/services/auth';
import * as companyService from 'src/redux/services/company';
import * as userService from 'src/redux/services/user';

import { ICompany } from 'src/types/company';
import { IUser } from 'src/types/user';

function* listUsers() {
  try {
    const users: IUser[] = yield call(userService.getAll);
    yield put(userActions.initializeUsersListViewSucceeded(users));
  } catch (error: any) {
    yield put(userActions.initializeUsersListViewFailed(error.data.message));
  }
}

function* deleteUser(action: AnyAction) {
  try {
    yield call(userService.deleteOne, action.userId);
    yield put(userActions.onDeleteOneSucceeded(action.userId));
  } catch (error: any) {
    yield put(userActions.onDeleteOneFailed(error.data.message));
  }
}

function* deleteManyUsers(action: AnyAction) {
  try {
    const { countDeleted } = yield call(userService.deleteMany, action.userIds);
    yield put(userActions.onDeleteManySucceeded(action.userIds, countDeleted));
  } catch (error: any) {
    yield put(userActions.onDeleteManyFailed(error.data.message));
  }
}

function* editUser(action: AnyAction) {
  try {
    const editedUser: IUser = yield call(userService.editOne, action.userId, action.newUser);
    yield put(userActions.onEditOneSucceeded(editedUser));
  } catch (error: any) {
    yield put(userActions.onEditOneFailed(error.data.message));
  }
}

function* createUser(action: AnyAction) {
  try {
    yield call(userService.createOne, action.user, action.companyCompetitors);
    const users: IUser[] = yield call(userService.getAll);
    yield put(userActions.onCreateOneSucceeded(users));
  } catch (error: any) {
    yield put(userActions.onCreateOneFailed(error.data.message));
  }
}

function* initializeEditView(action: AnyAction) {
  try {
    const [user, companies]: [IUser, ICompany[]] = yield all([
      call(userService.getOne, action.userId),
      call(companyService.getAll),
    ]);
    yield put(userActions.initializeUsersEditViewSucceeded(user, companies));
  } catch (error: any) {
    yield put(userActions.initializeUsersEditViewFailed(error.data.message));
  }
}

function* initializeCreateView(action: AnyAction) {
  try {
    const companies: ICompany[] = yield call(companyService.getAll);
    yield put(userActions.initializeUsersCreateViewSucceeded(companies));
  } catch (error: any) {
    yield put(userActions.initializeUsersCreateViewFailed(error.data.message));
  }
}

function* reSendEmail(action: AnyAction) {
  try {
    const sentUrl: string = yield call(authService.reSendEmailToChangePassword, action.userId);
    yield put(userActions.onReSendEmailToChangePasswordSucceeded(action.userId, sentUrl));
  } catch (error: any) {
    yield put(userActions.onReSendEmailToChangePasswordFailed(error.data.message));
  }
}

export default all([
  takeLatest(userConstants.INITIALIZE_USERS_LIST_VIEW_REQUESTED, listUsers),
  takeLatest(userConstants.INTIALIZE_USERS_EDIT_VIEW_REQUESTED, initializeEditView),
  takeLatest(userConstants.INTIALIZE_USERS_CREATE_VIEW_REQUESTED, initializeCreateView),
  takeLatest(userConstants.USERS_ON_EDIT_ONE_REQUESTED, editUser),
  takeLatest(userConstants.USERS_ON_DELETE_ONE_REQUESTED, deleteUser),
  takeLatest(userConstants.USERS_ON_CREATE_ONE_REQUESTED, createUser),
  takeLatest(userConstants.USERS_ON_DELETE_MANY_REQUESTED, deleteManyUsers),
  takeLatest(userConstants.USER_ON_RE_SEND_EMAIL_TO_CHANGE_PASSWORD_REQUESTED, reSendEmail),
]);
