import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const companiesListSelector = () =>
  createSelector([(state: IReduxState) => state.company.companies], (companies) => {
    if (!companies) return [];
    return companies.map((c) => ({
      id: c.id,
      name: c.name,
      industry: c.industry,
      logo: c.logo,
    }));
  });

export default companiesListSelector;
