import React from 'react';

import Modal from '../Modal';
import { DataLabel, DataText, InputsWrapper, UserData } from './styles';
import { IShowModalProps } from './types';

const ShowModal = (props: IShowModalProps) => {
  const { open, onClose, data } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <InputsWrapper>
        {data &&
          Object.keys(data).map((input) => {
            return (
              <UserData key={input}>
                <DataLabel>
                  {input.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                </DataLabel>
                <DataText>{data[input]}</DataText>
              </UserData>
            );
          })}
      </InputsWrapper>
    </Modal>
  );
};

export default ShowModal;
