export const INITIALIZE_USERS_LIST_VIEW_REQUESTED = 'INITIALIZE_USERS_LIST_VIEW_REQUESTED';

export const INTIIALIZE_USERS_LIST_VIEW_SUCCEEDED = 'INTIIALIZE_USERS_LIST_VIEW_SUCCEEDED';

export const INTIIALIZE_USERS_LIST_VIEW_FAILED = 'INTIIALIZE_USERS_LIST_VIEW_FAILED';

export const USERS_ON_DELETE_ONE_REQUESTED = 'USERS_ON_DELETE_ONE_REQUESTED';

export const USERS_ON_DELETE_ONE_SUCCEEDED = 'USERS_ON_DELETE_ONE_SUCCEEDED';

export const USERS_ON_DELETE_ONE_FAILED = 'USERS_ON_DELETE_ONE_FAILED';

export const USERS_ON_DELETE_MANY_REQUESTED = 'USERS_ON_DELETE_MANY_REQUESTED';

export const USERS_ON_DELETE_MANY_SUCCEEDED = 'USERS_ON_DELETE_MANY_SUCCEEDED';

export const USERS_ON_DELETE_MANY_FAILED = 'USERS_ON_DELETE_MANY_FAILED';

export const USERS_ON_EDIT_ONE_REQUESTED = 'USERS_ON_EDIT_ONE_REQUESTED';

export const USERS_ON_EDIT_ONE_SUCCEEDED = 'USERS_ON_EDIT_ONE_SUCCEEDED';

export const USERS_ON_EDIT_ONE_FAILED = 'USERS_ON_EDIT_ONE_FAILED';

export const USERS_ON_CREATE_ONE_REQUESTED = 'USERS_ON_CREATE_ONE_REQUESTED';

export const USERS_ON_CREATE_ONE_SUCCEEDED = 'USERS_ON_CREATE_ONE_SUCCEEDED';

export const USERS_ON_CREATE_ONE_FAILED = 'USERS_ON_CREATE_ONE_FAILED';

export const INTIALIZE_USERS_EDIT_VIEW_REQUESTED = 'INTIALIZE_USERS_EDIT_VIEW_REQUESTED';

export const INTIALIZE_USERS_EDIT_VIEW_SUCCEEDED = 'INTIALIZE_USERS_EDIT_VIEW_SUCCEEDED';

export const INTIALIZE_USERS_EDIT_VIEW_FAILED = 'INTIALIZE_USERS_EDIT_VIEW_FAILED';

export const INTIALIZE_USERS_CREATE_VIEW_REQUESTED = 'INTIALIZE_USERS_CREATE_VIEW_REQUESTED';

export const INTIALIZE_USERS_CREATE_VIEW_SUCCEEDED = 'INTIALIZE_USERS_CREATE_VIEW_SUCCEEDED';

export const INTIALIZE_USERS_CREATE_VIEW_FAILED = 'INTIALIZE_USERS_CREATE_VIEW_FAILED';

export const USER_ON_RE_SEND_EMAIL_TO_CHANGE_PASSWORD_REQUESTED =
  'USER_ON_RE_SEND_EMAIL_TO_CHANGE_PASSWORD_REQUESTED';

export const USER_ON_RE_SEND_EMAIL_TO_CHANGE_PASSWORD_SUCCEEDED =
  'USER_ON_RE_SEND_EMAIL_TO_CHANGE_PASSWORD_SUCCEEDED';

export const USER_ON_RE_SEND_EMAIL_TO_CHANGE_PASSWORD_FAILED =
  'USER_ON_RE_SEND_EMAIL_TO_CHANGE_PASSWORD_FAILED';

export const USER_ON_SET_INVALID_COMPANY = 'USER_ON_SET_INVALID_COMPANY';
