import { Close, ExitToApp, ExpandLess, ExpandMore, Menu } from '@mui/icons-material';
import {
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import i18next from 'i18next';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import './i18n';
import {
  HeaderContainer,
  ToolbarContainer,
  DrawerContainer,
  DrawerHeader,
  MenuItemText,
  RightMenuWrapper,
  LeftMenuWrapper,
  LogoWrapper,
  WrapperSubItems,
  ListSubItemText,
} from './styles';
import { IHeaderProps } from './types';

const Header = (props: IHeaderProps) => {
  const { isMenuOpened, items, onCloseMenu, onLogout, onOpenMenu, user } = props;
  const [expandSubMenu, setExpandSubMenu] = useState('');
  const onLogoutClick = () => {
    onLogout();
  };

  return (
    <>
      <HeaderContainer data-testid="header" position="fixed">
        <ToolbarContainer>
          {user && (
            <LeftMenuWrapper>
              <IconButton
                color="inherit"
                data-testid="header-menu-icon-button"
                onClick={onOpenMenu}
              >
                <Menu />
              </IconButton>
              <LogoWrapper data-testid="logo">
                {i18next.t('HEADER:TITLE', { name: user.firstName })}
              </LogoWrapper>
            </LeftMenuWrapper>
          )}
          {user && (
            <RightMenuWrapper data-testid="header-account-button-container">
              <MenuItem data-testid="header-logout-button" onClick={onLogoutClick}>
                <ExitToApp fontSize="small" />{' '}
                <MenuItemText>{i18next.t('HEADER:LOGOUT')}</MenuItemText>
              </MenuItem>
            </RightMenuWrapper>
          )}
        </ToolbarContainer>
      </HeaderContainer>
      <DrawerContainer
        anchor="left"
        data-testid="header-drawer"
        open={isMenuOpened}
        onClose={onCloseMenu}
        onOpen={onOpenMenu}
      >
        <DrawerHeader>
          <IconButton
            color="inherit"
            data-testid="header-close-icon-button"
            onClick={onCloseMenu}
            edge="start"
          >
            <Close />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {items.map(({ icon: Icon, key, label, to, subItems }) =>
            subItems ? (
              <>
                <ListItemButton
                  onClick={() => {
                    expandSubMenu === '' ? setExpandSubMenu(key) : setExpandSubMenu('');
                  }}
                >
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={label} />
                  {expandSubMenu === key ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <WrapperSubItems>
                  {subItems.map((subItem) => (
                    <Collapse in={expandSubMenu === key} timeout="auto" unmountOnExit>
                      <ListItem
                        activeClassName="active"
                        component={NavLink}
                        data-testid={`header-drawer-item-${subItem.key}`}
                        key={subItem.key}
                        to={subItem.to}
                      >
                        <ListSubItemText primary={subItem.label} />
                      </ListItem>
                    </Collapse>
                  ))}
                </WrapperSubItems>
              </>
            ) : (
              <ListItem
                activeClassName="active"
                component={NavLink}
                data-testid={`header-drawer-item-${key}`}
                key={key}
                onClick={onCloseMenu}
                to={to}
              >
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            ),
          )}
        </List>
      </DrawerContainer>
    </>
  );
};

export default Header;
