import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import i18next from 'i18next';
import React, { useEffect } from 'react';

import './i18n';
import { CloseButton, Message, Title } from './styles';
import { IConfirmationDialogProps } from './types';

const ConfirmationDialog = (props: IConfirmationDialogProps) => {
  const {
    message,
    onCancelClick,
    onConfirmClick,
    open,
    title = i18next.t('CONFIRMATION_DIALOG:DEFAULT_TITLE'),
  } = props;

  useEffect(() => {
    // 27: Escape key
    const handleKeyUp = (event: KeyboardEvent) => event.keyCode === 27 && onCancelClick();
    window.addEventListener('keyup', handleKeyUp, false);
    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  });

  return (
    <Dialog data-testid="confirmation-dialog" fullWidth onBackdropClick={onCancelClick} open={open}>
      <DialogTitle>
        <Title>{title}</Title>
      </DialogTitle>
      <CloseButton data-testid="confirmation-dialog-close-button" onClick={onCancelClick}>
        <Close />
      </CloseButton>
      <DialogContent>
        <Message>{message}</Message>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="confirmation-dialog-cancel-button"
          color="error"
          onClick={onCancelClick}
          variant="contained"
        >
          {i18next.t('CONFIRMATION_DIALOG:CANCEL')}
        </Button>
        <Button
          data-testid="confirmation-dialog-confirm-button"
          onClick={onConfirmClick}
          color="success"
          variant="contained"
        >
          {i18next.t('CONFIRMATION_DIALOG:CONFIRM')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
