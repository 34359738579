import { ICompanyScore, IProviderScore, ScoreProviders } from 'src/types/score';

export const addNewScore = (
  oldScores: ICompanyScore[],
  provider: ScoreProviders,
  newScore: IProviderScore,
) => {
  const providerIndex = oldScores.findIndex((sc) => sc.provider === provider);
  if (providerIndex >= 0) {
    return oldScores.map((sc, i) => {
      if (i === providerIndex) {
        return {
          ...sc,
          scores: [...sc.scores, newScore],
        };
      }
      return sc;
    });
  }
  return [
    ...oldScores,
    {
      provider,
      scores: [newScore],
    },
  ];
};

export const editScore = (
  oldScores: ICompanyScore[],
  provider: ScoreProviders,
  newScore: IProviderScore,
) => {
  return oldScores.map((scoreProvider) => {
    if (scoreProvider.provider === provider) {
      return {
        ...scoreProvider,
        scores: scoreProvider.scores.map((score) => {
          if (score.id === newScore.id) {
            return newScore;
          }
          return score;
        }),
      };
    }
    return scoreProvider;
  });
};

export const deleteScore = (oldScores: ICompanyScore[], scoreId: string) => {
  return oldScores.map((scoreProvider) => {
    if (scoreProvider.scores.some((score) => score.id === scoreId)) {
      return {
        ...scoreProvider,
        scores: scoreProvider.scores.filter((score) => score.id !== scoreId),
      };
    }
    return scoreProvider;
  });
};
