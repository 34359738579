import { Autocomplete, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';

import TextInput from 'src/components/Inputs/TextInput';
import { ISubmitAutoCompleteProps } from 'src/views/News/components/Companies/NewCompanyWizard/components/StepCompany/types';

import { Title } from '../Toolbar/styles';
import { FilterItemWrapper, FilterWrapper } from './styles';
import { IFilterProps } from './types';

const Filter = (props: IFilterProps) => {
  const { filter } = props;

  return (
    <FilterWrapper>
      <Title>Filters</Title>
      {filter.map((filterItem) => {
        return (
          <FilterItemWrapper key={filterItem.label}>
            {filterItem.type === 'text' && (
              <TextInput
                id={filterItem.label}
                name={filterItem.label}
                placeholder={filterItem.label}
                value={filterItem.value}
                isDisabled={false}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  filterItem.onChange(event.target.value)
                }
              />
            )}
            {filterItem.type === 'select' && (
              <Autocomplete
                id={filterItem.label}
                componentName={filterItem.label}
                noOptionsText="No options"
                options={
                  filterItem.options
                    ? filterItem.options.map((o) => ({
                        value: o.value as string,
                        label: o.text!,
                      }))
                    : []
                }
                sx={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField name={filterItem.label} {...params} label={filterItem.label} />
                )}
                onChange={(
                  event: React.SyntheticEvent<Element, Event>,
                  selectedOption: ISubmitAutoCompleteProps | null,
                ) => {
                  if (selectedOption !== null) {
                    filterItem.onChange(selectedOption!.value as string);
                  }
                }}
                onInputChange={(event, searchValue) => {
                  if (searchValue === '') {
                    filterItem.onChange('');
                  }
                }}
              />
            )}
          </FilterItemWrapper>
        );
      })}
    </FilterWrapper>
  );
};

export default Filter;
