import { AnyAction } from 'redux';
import { takeLatest, all, call, put } from 'redux-saga/effects';

import * as companyActions from 'src/redux/actions/company';
import * as companyConstants from 'src/redux/constants/company';
import * as companyService from 'src/redux/services/company';

import { MAX_DOCUMENTS_PER_PAGE } from 'src/helpers/constants';
import { IFilterResponse } from 'src/helpers/interfaces/http';
import { ICompany } from 'src/types/company';

function* listCompanies() {
  try {
    const response: IFilterResponse = yield call(companyService.getBy, {}, MAX_DOCUMENTS_PER_PAGE);
    yield put(companyActions.initializeCompaniesListViewSucceeded(response.items, response.total));
  } catch (error: any) {
    yield put(companyActions.initializeCompaniesListViewFailed(error.data.message));
  }
}

function* filter(action: AnyAction) {
  try {
    const response: IFilterResponse = yield call(
      companyService.getBy,
      action.filter,
      MAX_DOCUMENTS_PER_PAGE,
      action.page * MAX_DOCUMENTS_PER_PAGE,
    );
    yield put(companyActions.onFilterSucceeded(response.items, response.total));
  } catch (error: any) {
    yield put(companyActions.onFilterFailed(action.data.message));
  }
}

function* deleteCompany(action: AnyAction) {
  try {
    yield call(companyService.deleteOne, action.companyId);
    yield put(companyActions.onDeleteOneSucceeded(action.companyId));
  } catch (error: any) {
    yield put(companyActions.onDeleteOneFailed(error.data.message));
  }
}

function* deleteManyCompanies(action: AnyAction) {
  try {
    const { countDeleted } = yield call(companyService.deleteMany, action.companyIds);
    yield put(companyActions.onDeleteManySucceeded(action.companyIds, countDeleted));
  } catch (error: any) {
    yield put(companyActions.onDeleteManyFailed(error.data.message));
  }
}

function* editCompany(action: AnyAction) {
  try {
    const editedCompany: ICompany = yield call(
      companyService.editOne,
      action.companyId,
      action.newCompany,
    );
    yield put(companyActions.onEditOneSucceeded(editedCompany));
  } catch (error: any) {
    yield put(companyActions.onEditOneFailed(error.data.message));
  }
}

function* createCompany(action: AnyAction) {
  try {
    yield call(companyService.createOne, action.company);
    const companies: ICompany[] = yield call(companyService.getAll);
    yield put(companyActions.onCreateOneSucceeded(companies));
  } catch (error: any) {
    yield put(companyActions.onCreateOneFailed(error.data.message));
  }
}

function* initializeEditView(action: AnyAction) {
  try {
    const [company, allCompanies]: [ICompany, ICompany[]] = yield all([
      call(companyService.getOne, action.companyId),
      call(companyService.getAll),
    ]);

    yield put(companyActions.initializeCompaniesEditViewSucceeded(company, allCompanies));
  } catch (error: any) {
    yield put(companyActions.initializeCompaniesEditViewFailed(error.data.message));
  }
}

function* initializeNewCompanyView() {
  try {
    const companies: ICompany[] = yield call(companyService.getAll);
    yield put(companyActions.onInitializeCompaniesNewViewSucceeded(companies));
  } catch (error: any) {
    yield put(companyActions.onInitializeCompaniesNewViewFailed(error.data.message));
  }
}

export default all([
  takeLatest(companyConstants.INITIALIZE_COMPANIES_LIST_VIEW_REQUESTED, listCompanies),
  takeLatest(companyConstants.INTIALIZE_COMPANIES_EDIT_VIEW_REQUESTED, initializeEditView),
  takeLatest(companyConstants.COMPANIES_ON_EDIT_ONE_REQUESTED, editCompany),
  takeLatest(companyConstants.COMPANIES_ON_DELETE_ONE_REQUESTED, deleteCompany),
  takeLatest(companyConstants.COMPANIES_ON_CREATE_ONE_REQUESTED, createCompany),
  takeLatest(companyConstants.COMPANIES_ON_DELETE_MANY_REQUESTED, deleteManyCompanies),
  takeLatest(companyConstants.INITIALIZE_NEW_COMPANIES_VIEW_REQUESTED, initializeNewCompanyView),
  takeLatest(companyConstants.COMPANIES_FILTER_REQUESTED, filter),
]);
