import React from 'react';

import { Button } from './styles';
import { ITextButtonProps } from './types';

const TextButton = (props: ITextButtonProps) => {
  const { isDisabled, isLoading, text, onSubmit = () => {} } = props;

  return (
    <Button
      fullWidth
      data-testid="button"
      disabled={isDisabled || isLoading}
      loading={isLoading}
      loadingPosition="center"
      onClick={onSubmit}
    >
      {!isLoading && <>{text}</>}{' '}
    </Button>
  );
};

export default TextButton;
