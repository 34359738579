import arrayUnique from 'array-unique';
import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const industriesSelector = () =>
  createSelector([(state: IReduxState) => state.company.companies], (companies) => {
    if (!companies) return [];
    const industries = companies.map((c) => c.industry);
    return arrayUnique(industries);
  });

export default industriesSelector;
