import * as newsConstants from 'src/redux/constants/news';

import { NewsNavigationTabs } from 'src/helpers/enums/news';
import { ICompany } from 'src/types/company';
import { INews, INewNotice, INewsDraft, IEditNoticeDraft } from 'src/types/news';

export const initializeNewsCreateView = () => ({
  type: newsConstants.INTIALIZE_NEWS_CREATE_VIEW_REQUESTED,
});

export const initializeNewsCreateViewSucceeded = (companies: ICompany[]) => ({
  type: newsConstants.INTIALIZE_NEWS_CREATE_VIEW_SUCCEEDED,
  companies,
});

export const initializeNewsCreateViewFailed = (errorMessage: string) => ({
  type: newsConstants.INTIALIZE_NEWS_CREATE_VIEW_FAILED,
  errorMessage,
});

export const initializeNewsListView = () => ({
  type: newsConstants.INITIALIZE_NEWS_LIST_VIEW_REQUESTED,
});

export const initializeNewsListViewSucceeded = (news: INews[], totalNews: number) => ({
  type: newsConstants.INTIIALIZE_NEWS_LIST_VIEW_SUCCEEDED,
  totalNews,
  news,
});

export const initializeNewsListViewFailed = (errorMessage: string) => ({
  type: newsConstants.INTIIALIZE_NEWS_LIST_VIEW_FAILED,
  errorMessage,
});

export const initializeNewsDraftListView = () => ({
  type: newsConstants.INITIALIZE_NEWS_DRAFT_LIST_VIEW_REQUESTED,
});

export const initializeNewsDraftListViewSucceeded = (
  newsDraft: INewsDraft[],
  totalNewsDraft: number,
) => ({
  type: newsConstants.INTIIALIZE_NEWS_DRAFT_LIST_VIEW_SUCCEEDED,
  newsDraft,
  totalNewsDraft,
});

export const initializeNewsDraftListViewFailed = (errorMessage: string) => ({
  type: newsConstants.INTIIALIZE_NEWS_DRAFT_LIST_VIEW_FAILED,
  errorMessage,
});

export const initializeNewsEditView = (newId: string) => ({
  type: newsConstants.INTIALIZE_NEWS_EDIT_VIEW_REQUESTED,
  newId,
});

export const initializeNewsEditViewSucceeded = (news: INews, companies: ICompany[]) => ({
  type: newsConstants.INTIALIZE_NEWS_EDIT_VIEW_SUCCEEDED,
  news,
  companies,
});

export const initializeNewsEditViewFailed = (errorMessage: string) => ({
  type: newsConstants.INTIALIZE_NEWS_EDIT_VIEW_FAILED,
  errorMessage,
});

export const initializeNewsDraftEditView = (newId: string) => ({
  type: newsConstants.INTIALIZE_NEWS_DRAFT_EDIT_VIEW_REQUESTED,
  newId,
});

export const initializeNewsDraftEditViewSucceeded = (news: INewsDraft) => ({
  type: newsConstants.INTIALIZE_NEWS_DRAFT_EDIT_VIEW_SUCCEEDED,
  news,
});

export const initializeNewsDraftEditViewFailed = (errorMessage: string) => ({
  type: newsConstants.INTIALIZE_NEWS_DRAFT_EDIT_VIEW_FAILED,
  errorMessage,
});

export const onDeleteOneRequested = (newId: string) => ({
  type: newsConstants.NEWS_ON_DELETE_ONE_REQUESTED,
  newId,
});

export const onDeleteOneSucceeded = (newId: string) => ({
  type: newsConstants.NEWS_ON_DELETE_ONE_SUCCEEDED,
  newId,
});

export const onDeleteOneFailed = (errorMessage: string) => ({
  type: newsConstants.NEWS_ON_DELETE_ONE_FAILED,
  errorMessage,
});

export const onDeleteManyRequested = (newsIds: string[]) => ({
  type: newsConstants.NEWS_ON_DELETE_MANY_REQUESTED,
  newsIds,
});

export const onDeleteManySucceeded = (newsIds: string[], countDeleted: number) => ({
  type: newsConstants.NEWS_ON_DELETE_MANY_SUCCEEDED,
  newsIds,
  countDeleted,
});

export const onDeleteManyFailed = (errorMessage: string) => ({
  type: newsConstants.NEWS_ON_DELETE_MANY_FAILED,
  errorMessage,
});

export const onEditOneRequested = (newId: string, newNotice: INewNotice) => ({
  type: newsConstants.NEWS_ON_EDIT_ONE_REQUESTED,
  newId,
  newNotice,
});

export const onEditOneSucceeded = (news: INews) => ({
  type: newsConstants.NEWS_ON_EDIT_ONE_SUCCEEDED,
  news,
});

export const onEditOneFailed = (errorMessage: string) => ({
  type: newsConstants.NEWS_ON_EDIT_ONE_FAILED,
  errorMessage,
});

export const onNewsDraftEditOneRequested = (
  newId: string,
  newNotice: IEditNoticeDraft,
  publish: boolean,
) => ({
  type: newsConstants.NEWS_DRAFT_ON_EDIT_ONE_REQUESTED,
  newId,
  newNotice,
  publish,
});

export const onNewsDraftEditOneSucceeded = (news: INewsDraft, publish: boolean) => ({
  type: newsConstants.NEWS_DRAFT_ON_EDIT_ONE_SUCCEEDED,
  news,
  publish,
});

export const onNewsDraftEditOneFailed = (errorMessage: string) => ({
  type: newsConstants.NEWS_DRAFT_ON_EDIT_ONE_FAILED,
  errorMessage,
});

export const onCreateOneRequested = (news: INewNotice) => ({
  type: newsConstants.NEWS_ON_CREATE_ONE_REQUESTED,
  news,
});

export const onCreateOneSucceeded = (news: INews[]) => ({
  type: newsConstants.NEWS_ON_CREATE_ONE_SUCCEEDED,
  news,
});

export const onCreateOneFailed = (errorMessage: string) => ({
  type: newsConstants.NEWS_ON_CREATE_ONE_FAILED,
  errorMessage,
});

export const onNewsFilterRequested = (filter: Partial<INews>, page: number) => ({
  type: newsConstants.NEWS_FILTER_REQUESTED,
  filter,
  page,
});

export const onNewsFilterSucceeded = (news: INews[], totalNews: number) => ({
  type: newsConstants.NEWS_FILTER_SUCCEEDED,
  news,
  totalNews,
});

export const onNewsFilterFailed = (errorMessage: string) => ({
  type: newsConstants.NEWS_FILTER_FAILED,
  errorMessage,
});

export const onNewsDraftFilterRequested = (filter: Partial<INewsDraft>, page: number) => ({
  type: newsConstants.NEWS_DRAFT_FILTER_REQUESTED,
  filter,
  page,
});

export const onNewsDraftFilterSucceeded = (newsDraft: INewsDraft[], totalNewsDraft: number) => ({
  type: newsConstants.NEWS_DRAFT_FILTER_SUCCEEDED,
  newsDraft,
  totalNewsDraft,
});

export const onNewsDraftFilterFailed = (errorMessage: string) => ({
  type: newsConstants.NEWS_DRAFT_FILTER_FAILED,
  errorMessage,
});

export const onNewsPublishRequested = (newsDraftId: string) => ({
  type: newsConstants.NEWS_PUBLISH_REQUESTED,
  newsDraftId,
});

export const onNewsPublishSucceeded = (newsDraftId: string) => ({
  type: newsConstants.NEWS_PUBLISH_SUCCEEDED,
  newsDraftId,
});

export const onNewsPublishFailed = (errorMessage: string) => ({
  type: newsConstants.NEWS_PUBLISH_FAILED,
  errorMessage,
});

export const onNewsTabChange = (tab: NewsNavigationTabs) => ({
  type: newsConstants.NEWS_ON_NEWS_TAB_CHANGE,
  tab,
});

export const onPurgeNewsDraft = () => ({
  type: newsConstants.NEWS_DRAFT_ON_PURGE_REQUESTED,
});

export const onPurgeNewsDraftSucceeded = (newsDraft: INewsDraft[], totalNewsDraft: number) => ({
  type: newsConstants.NEWS_DRAFT_ON_PURGE_SUCCEEDED,
  newsDraft,
  totalNewsDraft,
});

export const onPurgeNewsDraftFailed = (errorMessage: string) => ({
  type: newsConstants.NEWS_DRAFT_ON_PURGE_FAILED,
  errorMessage,
});
