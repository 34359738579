import { Email } from '@mui/icons-material';
import i18next from 'i18next';
import React, { useState } from 'react';

import ShowModal from 'src/components/ShowModal';
import Table from 'src/components/Table';
import { ICustomRowAction } from 'src/components/Table/types';
import { ActionTypes } from 'src/helpers/enums/tableActions';
import { IUserToShow } from 'src/types/user';

import { mapToUserToShow } from '../common/formProvider';
import './i18n';
import { UsersContainer, UsersContent } from './styles';
import { IUsersListProps } from './types';

const UsersList = (props: IUsersListProps) => {
  const {
    columns,
    error,
    isLoading,
    users,
    onAddNew,
    onEdit,
    onDeleteMany,
    onDeleteOne,
    onResendPasswordEmail,
  } = props;
  const [showRow, setShowRow] = useState<IUserToShow | null>(null);

  const handleShowRow = (rowId: string) => {
    const userToShow = users.find((user) => user.id === rowId)!;
    setShowRow(mapToUserToShow(userToShow));
  };

  const customRowActions: ICustomRowAction[] = [
    {
      button: <Email color="inherit" />,
      disabled: (userId) => {
        const user = users.find((u) => u.id === userId);
        return user!.changedPassword!;
      },
      loading: isLoading,
      onClick: onResendPasswordEmail,
      tooltip: i18next.t('USERS_LIST:EMAIL_TOOLTIP'),
    },
  ];

  return (
    <UsersContainer data-testid="users-view">
      <ShowModal open={showRow !== null} onClose={() => setShowRow(null)} data={showRow} />
      <UsersContent>
        <Table
          columns={columns}
          error={error}
          rows={users}
          title={i18next.t('USERS_LIST:TITLE')}
          loading={isLoading}
          rowActions={[ActionTypes.SHOW, ActionTypes.EDIT, ActionTypes.DELETE]}
          customRowActions={customRowActions}
          autoHeight
          onAddNew={onAddNew}
          onEdit={onEdit}
          onDeleteOne={onDeleteOne}
          onDeleteMany={onDeleteMany}
          onShow={handleShowRow}
        />
      </UsersContent>
    </UsersContainer>
  );
};

export default UsersList;
