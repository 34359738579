import i18next from 'i18next';
import React, { ChangeEvent, useState } from 'react';

import TextButton from 'src/components/Buttons/TextButton';
import TextInput from 'src/components/Inputs/TextInput';

import './i18n';
import { ButtonWrapper, InputsWrapper, InputWrapper, Title } from './styles';
import { IStepScoreProps } from './types';

const StepScore = (props: IStepScoreProps) => {
  const { defaultScore, onSubmit } = props;
  const [scoreName, setScoreName] = useState<string>(
    defaultScore ? defaultScore.scoreLabel : i18next.t('STEP_SCORE:DEFAULT_SCORE_LABEL'),
  );
  const [score, setScore] = useState(defaultScore ? defaultScore.score : '');

  const handleScoreSubmit = () => {
    onSubmit(score, scoreName);
  };

  const handleScoreNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setScoreName(event.target.value);
  };

  const handleScoreChange = (event: ChangeEvent<HTMLInputElement>) => {
    setScore(event.target.value);
  };

  return (
    <>
      <Title>{i18next.t('STEP_SCORE:TITLE')}</Title>
      <InputsWrapper>
        <InputWrapper>
          <TextInput
            id="scoreName"
            name="scoreName"
            placeholder={i18next.t('STEP_SCORE:INPUT_NAME_LABEL')}
            value={scoreName}
            isDisabled={false}
            onChange={handleScoreNameChange}
          />
        </InputWrapper>
        <InputWrapper>
          <TextInput
            id="score"
            name="score"
            placeholder={i18next.t('STEP_SCORE:INPUT_SCORE_LABEL')}
            value={score}
            isDisabled={false}
            onChange={handleScoreChange}
          />
        </InputWrapper>
      </InputsWrapper>
      <ButtonWrapper>
        <TextButton
          text={i18next.t('STEP_SCORE:BUTTON_TEXT')}
          isDisabled={score === ''}
          isLoading={false}
          onSubmit={handleScoreSubmit}
        />
      </ButtonWrapper>
    </>
  );
};

export default StepScore;
