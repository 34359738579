import { enum2array } from 'enum2array';
import i18next from 'i18next';

import { locales } from 'src/configs/i18n';
import { ScoreProviders } from 'src/types/score';

i18next.addResources(locales.EN, 'SCORE_LIST', {
  TITLE: 'Scores',
  ADD_NEW_BUTTON: 'Add score',
  HELP_TEXT: `Add, edit or remove scores for providers: ${enum2array(ScoreProviders)
    .map((provider) => provider.value)
    .join(', ')}. These scores are displayed in the Rating Coverage section.`,
});
