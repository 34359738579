import React from 'react';

import { StepItem, StepWrapper, StyledStepper, WizardWrapper } from './styles';
import { IStepWizardProps } from './types';

const StepWizard = (props: IStepWizardProps) => {
  const { steps, activeStep } = props;

  return (
    <WizardWrapper data-testid="wizard-block">
      <StyledStepper data-testid="wizard-stepper" activeStep={activeStep} alternativeLabel>
        {steps.map((label) => {
          return (
            <StepWrapper key={label}>
              <StepItem>{label}</StepItem>
            </StepWrapper>
          );
        })}
      </StyledStepper>
    </WizardWrapper>
  );
};

export default StepWizard;
