import { Autocomplete, TextField } from '@mui/material';
import i18next from 'i18next';
import React, { useState } from 'react';

import TextButton from 'src/components/Buttons/TextButton';

import './i18n';
import { ButtonWrapper, InputsWrapper, InputWrapper, Title } from './styles';
import { IStepCompanyProps, ISubmitAutoCompleteProps } from './types';

const StepCompany = (props: IStepCompanyProps) => {
  const { allCompanies, onSubmit } = props;
  const [company, setCompany] = useState<string>('');
  const handleCompanySubmit = () => {
    onSubmit(company);
  };
  const handleCompanyNameChange = (
    event: React.SyntheticEvent<Element, Event>,
    selectedCompany: ISubmitAutoCompleteProps | null,
  ) => {
    setCompany(selectedCompany!.value);
  };

  return (
    <>
      <Title>{i18next.t('STEP_COMPANY:TITLE')}</Title>
      <InputsWrapper>
        <InputWrapper>
          <Autocomplete
            id="company"
            componentName="company"
            noOptionsText={i18next.t('STEP_COMPANY:NO_RESULTS')}
            value={null}
            options={
              allCompanies
                ? allCompanies.map((c) => ({
                    value: c.id,
                    label: c.name,
                  }))
                : []
            }
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField name="company" {...params} label="Companies" />}
            onChange={handleCompanyNameChange}
          />
        </InputWrapper>
      </InputsWrapper>
      <ButtonWrapper>
        <TextButton
          text={i18next.t('STEP_COMPANY:BUTTON_TEXT')}
          isDisabled={company === ''}
          isLoading={false}
          onSubmit={handleCompanySubmit}
        />
      </ButtonWrapper>
    </>
  );
};

export default StepCompany;
