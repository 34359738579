import { CircularProgress } from '@mui/material';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { FOOTER_HEIGHT, HEADER_HEIGHT, HEADER_HEIGHT_RESPONSIVE, SIZES } from 'src/helpers/devices';

import HeaderContainer from './Header';

interface ILayoutContainerProps {
  children: ReactNode;
  loading?: boolean;
}

const GlobalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadingSpinner = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.secondary} !important;
  position: absolute;
  left: 50%;
  top: 50%;
`;

const ViewContainer = styled.div`
  @media (max-width: ${SIZES.smallTablet}px) {
    min-height: calc(100vh - ${HEADER_HEIGHT_RESPONSIVE + FOOTER_HEIGHT}px);
    margin-top: ${HEADER_HEIGHT_RESPONSIVE}px;
  }
  width: 100%;
  min-height: calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px);
  margin-top: ${HEADER_HEIGHT}px;
`;

const LayoutContainer = (props: ILayoutContainerProps) => {
  const { children, loading = false } = props;

  return (
    <GlobalContainer>
      <HeaderContainer />
      <ViewContainer>{!loading ? children : <LoadingSpinner />}</ViewContainer>
    </GlobalContainer>
  );
};

export default LayoutContainer;
