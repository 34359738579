import styled from 'styled-components';

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const InputWrapper = styled.div`
  padding: 5px 0px;
`;

export const InputsWrapper = styled.div`
  flex: 1 1;
`;

export const SubmitButtonWrapper = styled.div`
  margin: 30px auto;
  width: 120px;
`;
