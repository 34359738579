import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const newsDraftToEditSelector = () =>
  createSelector(
    [(state: IReduxState) => state.newsDraft.newsDraftToEdit],
    (newsToEdit) => newsToEdit,
  );

export default newsDraftToEditSelector;
