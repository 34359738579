import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const newsQueryToEditSelector = () =>
  createSelector(
    [(state: IReduxState) => state.newsQuerys.newsQuerysToEdit],
    (newsQuerysToEdit) => newsQuerysToEdit,
  );

export default newsQueryToEditSelector;
