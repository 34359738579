import i18next from 'i18next';
import React from 'react';

import Table from 'src/components/Table';
import { IFilterInput } from 'src/components/Table/components/Filter/types';
import { useFilter } from 'src/hooks/useFilter';

import './i18n';
import { MaterialityContainer, MaterialityContent } from './styles';
import { IMateriality, IMaterialityListProps } from './types';

const MaterialityList = (props: IMaterialityListProps) => {
  const {
    columns,
    error,
    isLoading,
    materiality,
    totalMateriality,
    industries,
    onFilter,
    onCellEdit,
  } = props;

  const [filter, page, setFilter, setPage] = useFilter<Partial<IMateriality>>(onFilter);

  const filtersToApply: IFilterInput[] = [
    {
      label: 'Industry',
      value: filter.industry || '',
      type: 'select',
      options: industries.map((i) => ({
        value: i,
        text: i,
      })),
      onChange: (value: string) => {
        if (value !== '') {
          setFilter({ industry: value });
        } else {
          setFilter({});
        }
      },
    },
  ];

  return (
    <MaterialityContainer data-testid="materiality-view">
      <MaterialityContent>
        <Table
          columns={columns}
          error={error}
          rows={materiality}
          title={i18next.t('MATERIALITY_LIST:TITLE')}
          toolbarActions={[]}
          page={page}
          onNextPage={(currentPage: number) => setPage(currentPage)}
          totalRows={totalMateriality}
          loading={isLoading}
          rowActions={[]}
          filter={filtersToApply}
          autoHeight
          editMode="cell"
          onCellEdit={onCellEdit}
          includeCheckboxes={false}
        />
      </MaterialityContent>
    </MaterialityContainer>
  );
};

export default MaterialityList;
