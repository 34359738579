import { AnyAction } from 'redux';

import { notifyError, notifySuccess } from 'src/helpers/notifications';

import * as appConstants from '../constants/app';
import * as authConstants from '../constants/auth';
import * as companyConstants from '../constants/company';
import * as insightsConstants from '../constants/insights';
import * as materialityConstants from '../constants/materiality';
import * as newsConstants from '../constants/news';
import * as newsQuerysConstants from '../constants/newsQuery';
import * as userConstants from '../constants/user';

const notificationsMiddleware =
  () => (next: (action: AnyAction) => void) => (action: AnyAction) => {
    switch (action.type) {
      case authConstants.USER_ON_LOGIN_SUCCEEDED:
        notifySuccess('Success!', 'Welcome back!');
        break;
      case authConstants.USER_ON_LOGIN_FAILED:
        notifyError('Error', action.errorMessage);
        break;
      case userConstants.INTIIALIZE_USERS_LIST_VIEW_FAILED:
      case userConstants.INTIALIZE_USERS_EDIT_VIEW_FAILED:
      case userConstants.INTIALIZE_USERS_CREATE_VIEW_FAILED:
      case userConstants.USERS_ON_DELETE_ONE_FAILED:
      case userConstants.USERS_ON_DELETE_MANY_FAILED:
      case userConstants.USERS_ON_EDIT_ONE_FAILED:
      case userConstants.USERS_ON_CREATE_ONE_FAILED:
      case companyConstants.INTIIALIZE_COMPANIES_LIST_VIEW_FAILED:
      case companyConstants.INTIALIZE_COMPANIES_EDIT_VIEW_FAILED:
      case companyConstants.COMPANIES_ON_EDIT_ONE_FAILED:
      case companyConstants.COMPANIES_ON_DELETE_ONE_FAILED:
      case companyConstants.COMPANIES_ON_CREATE_ONE_FAILED:
      case companyConstants.COMPANIES_ON_DELETE_MANY_FAILED:
      case newsConstants.INTIALIZE_NEWS_CREATE_VIEW_FAILED:
      case newsConstants.NEWS_ON_CREATE_ONE_FAILED:
      case newsConstants.NEWS_ON_EDIT_ONE_FAILED:
      case newsConstants.INTIIALIZE_NEWS_DRAFT_LIST_VIEW_FAILED:
      case newsConstants.INTIALIZE_NEWS_DRAFT_EDIT_VIEW_FAILED:
      case newsConstants.NEWS_DRAFT_FILTER_FAILED:
      case newsConstants.NEWS_FILTER_FAILED:
      case newsConstants.NEWS_PUBLISH_FAILED:
      case insightsConstants.INSIGHTS_ON_DELETE_MANY_FAILED:
      case insightsConstants.INSIGHTS_ON_DELETE_ONE_FAILED:
      case insightsConstants.INITIALIZE_INSIGHTS_LIST_VIEW_FAILED:
      case insightsConstants.INSIGHTS_ON_EDIT_ONE_FAILED:
      case newsQuerysConstants.INTIIALIZE_NEWS_QUERYS_LIST_VIEW_FAILED:
      case newsQuerysConstants.NEWS_QUERYS_ON_CHANGE_ACTIVE_QUERY_FAILED:
      case newsQuerysConstants.NEWS_QUERYS_ON_DELETE_ONE_FAILED:
      case newsQuerysConstants.NEWS_QUERYS_ON_DELETE_MANY_FAILED:
      case newsQuerysConstants.NEWS_QUERYS_ON_CREATE_ONE_FAILED:
      case newsQuerysConstants.INTIALIZE_NEWS_QUERYS_CREATE_VIEW_FAILED:
      case newsQuerysConstants.INTIALIZE_NEWS_QUERYS_EDIT_VIEW_FAILED:
      case newsQuerysConstants.NEWS_QUERYS_ON_EDIT_ONE_FAILED:
      case newsQuerysConstants.NEWS_QUERY_RUN_FAILED:
      case materialityConstants.MATERIALITY_ON_EDIT_ONE_FAILED:
        notifyError('Error', action.errorMessage);
        break;
      case newsConstants.NEWS_ON_DELETE_ONE_SUCCEEDED:
        notifySuccess('Success!', 'New deleted');
        break;
      case newsConstants.NEWS_ON_DELETE_MANY_SUCCEEDED:
        notifySuccess('Success!', `${action.countDeleted} news deleted`);
        break;
      case newsConstants.NEWS_ON_EDIT_ONE_SUCCEEDED:
        notifySuccess('Success!', 'New edited');
        break;
      case newsConstants.NEWS_DRAFT_ON_EDIT_ONE_SUCCEEDED:
        notifySuccess('Success!', action.publish ? 'Notice edited & published' : 'New edited');
        break;
      case newsConstants.NEWS_ON_CREATE_ONE_SUCCEEDED:
        notifySuccess('Success!', 'New created');
        break;
      case newsConstants.NEWS_PUBLISH_SUCCEEDED:
        notifySuccess('Success!', 'Notice published!');
        break;
      case newsQuerysConstants.NEWS_QUERYS_ON_CREATE_ONE_SUCCEEDED:
        notifySuccess('Success!', 'News query created');
        break;
      case newsQuerysConstants.NEWS_QUERYS_ON_EDIT_ONE_SUCCEEDED:
        notifySuccess('Success!', 'News query edited');
        break;
      case newsQuerysConstants.NEWS_QUERY_RUN_SUCCEEDED:
        notifySuccess('Success!', 'New notices imported');
        break;
      case userConstants.USERS_ON_DELETE_ONE_SUCCEEDED:
        notifySuccess('Success!', 'User deleted');
        break;
      case userConstants.USERS_ON_DELETE_MANY_SUCCEEDED:
        notifySuccess('Success!', `${action.countDeleted} users deleted`);
        break;
      case userConstants.USERS_ON_EDIT_ONE_SUCCEEDED:
        notifySuccess('Success!', 'User edited');
        break;
      case userConstants.USERS_ON_CREATE_ONE_SUCCEEDED:
        notifySuccess('Success!', 'User created');
        break;
      case companyConstants.COMPANIES_ON_DELETE_ONE_SUCCEEDED:
        notifySuccess('Success!', 'Company deleted');
        break;
      case companyConstants.COMPANIES_ON_DELETE_MANY_SUCCEEDED:
        notifySuccess('Success!', `${action.countDeleted} companies deleted`);
        break;
      case companyConstants.COMPANIES_ON_EDIT_ONE_SUCCEEDED:
        notifySuccess('Success!', 'Company edited');
        break;
      case companyConstants.COMPANIES_ON_CREATE_ONE_SUCCEEDED:
        notifySuccess('Success!', 'Company created');
        break;
      case insightsConstants.INSIGHTS_ON_CREATE_ONE_SUCCEEDED:
        notifySuccess('Success!', 'Insight created');
        break;
      case insightsConstants.INSIGHTS_ON_DELETE_MANY_SUCCEEDED:
        notifySuccess('Success!', `${action.countDeleted} insights deleted`);
        break;
      case insightsConstants.INSIGHTS_ON_DELETE_ONE_SUCCEEDED:
        notifySuccess('Success!', 'Insight deleted');
        break;
      case insightsConstants.INSIGHTS_ON_EDIT_ONE_SUCCEEDED:
        notifySuccess('Success!', 'Insight edited');
        break;
      case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_FAILED:
      case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_FAILED:
        if (action.error.statusCode === 401) {
          notifyError('Error', 'Token expired');
        }
        break;
      case userConstants.USER_ON_RE_SEND_EMAIL_TO_CHANGE_PASSWORD_FAILED:
        notifyError('Error', 'The email could not be sent');
        break;
      case userConstants.USER_ON_SET_INVALID_COMPANY:
        notifyError('Error', 'This role cannot be associated with a company without ratings');
        break;
      case userConstants.USER_ON_RE_SEND_EMAIL_TO_CHANGE_PASSWORD_SUCCEEDED:
        notifySuccess('Success!', 'The email was sent');
        break;
      default:
        break;
    }
    next(action);
  };

export default notificationsMiddleware;
