import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import styled from 'styled-components';

export const Input = styled(TextField)`
  .MuiInputLabel-root {
    color: ${(props) => props.theme.palette.secondary};
  }
  label.Mui-focused {
    color: ${(props) => props.theme.palette.secondary};
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.palette.common.white} inset !important;
  }
`;

export const ToggleButton = styled(IconButton)``;

export const EyeIcon = styled(Visibility)`
  color: ${(props) => props.theme.palette.secondary};
`;

export const SlashedEyeIcon = styled(VisibilityOff)`
  color: ${(props) => props.theme.palette.secondary};
`;

export const LockIcon = styled(Lock)`
  color: ${(props) => props.theme.palette.secondary};
`;
