import i18next from 'i18next';

import { locales } from 'src/configs/i18n';
import { NewsTypes } from 'src/helpers/enums/news';

i18next.addResources(locales.EN, 'NEWS_LIST', {
  TITLE: 'Published News',
  TOOLTIP_TITLE: 'Show to ',
});

i18next.addResources(locales.EN, `NEWS_LIST_TOOLTIP_${NewsTypes.ALL}`, {
  DESCRIPTION: 'all companies',
});

i18next.addResources(locales.EN, `NEWS_LIST_TOOLTIP_${NewsTypes.SPECIFIC}`, {
  DESCRIPTION: 'companies: {{companies}}',
});

i18next.addResources(locales.EN, `NEWS_LIST_TOOLTIP_${NewsTypes.INDUSTRY}`, {
  DESCRIPTION: 'industries: {{industries}}',
});
