import i18next from 'i18next';
import React, { useState } from 'react';

import Table from 'src/components/Table';
import { InisghtType, InsightNavigationTabs } from 'src/helpers/enums/insights';

import InsightsNavigation from '../InsightsNavigation';
import './i18n';
import { InsightsContainer, InsightsContent, NavigationWrapper } from './styles';
import { IInsightsListProps } from './types';

const InsightsList = (props: IInsightsListProps) => {
  const {
    textInsightColumns,
    videoInsightColumns,
    error,
    isLoading,
    insights,
    onAddNew,
    onEdit,
    onDeleteMany,
    onDeleteOne,
  } = props;
  const [currentTab, setCurrentTab] = useState(InsightNavigationTabs.TEXT_INSIGHT);

  return (
    <InsightsContainer data-testid="insights-view">
      <NavigationWrapper>
        <InsightsNavigation
          currentTab={currentTab}
          onChangeTab={(tab: InsightNavigationTabs) => {
            setCurrentTab(tab);
          }}
        />
      </NavigationWrapper>
      <InsightsContent>
        <Table
          columns={
            currentTab === InsightNavigationTabs.TEXT_INSIGHT
              ? textInsightColumns
              : videoInsightColumns
          }
          error={error}
          rows={
            currentTab === InsightNavigationTabs.TEXT_INSIGHT
              ? insights.filter((insight) => insight.type !== InisghtType.VIDEO)
              : insights.filter((insight) => insight.type === InisghtType.VIDEO)
          }
          title={i18next.t('INSIGHTS_LIST:TITLE')}
          loading={isLoading}
          customRowActions={[]}
          autoHeight
          onAddNew={onAddNew}
          onEdit={onEdit}
          onDeleteOne={onDeleteOne}
          onDeleteMany={onDeleteMany}
        />
      </InsightsContent>
    </InsightsContainer>
  );
};

export default InsightsList;
