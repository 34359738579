import { AnyAction } from 'redux';
import { takeLatest, all, call, put } from 'redux-saga/effects';

import * as newsActions from 'src/redux/actions/news';
import * as newsConstants from 'src/redux/constants/news';
import * as companyService from 'src/redux/services/company';
import * as newsService from 'src/redux/services/news';

import { MAX_DOCUMENTS_PER_PAGE } from 'src/helpers/constants';
import { IFilterResponse } from 'src/helpers/interfaces/http';
import { ICompany } from 'src/types/company';
import { INews } from 'src/types/news';

function* listNews() {
  try {
    const response: IFilterResponse = yield call(newsService.getBy, {}, MAX_DOCUMENTS_PER_PAGE);
    yield put(newsActions.initializeNewsListViewSucceeded(response.items, response.total));
  } catch (error: any) {
    yield put(newsActions.initializeNewsListViewFailed(error.data.message));
  }
}

function* deleteNews(action: AnyAction) {
  try {
    yield call(newsService.deleteOne, action.newId);
    yield put(newsActions.onDeleteOneSucceeded(action.newId));
  } catch (error: any) {
    yield put(newsActions.onDeleteOneFailed(error.data.message));
  }
}

function* deleteManyNews(action: AnyAction) {
  try {
    const { countDeleted } = yield call(newsService.deleteMany, action.newsIds);
    yield put(newsActions.onDeleteManySucceeded(action.newsIds, countDeleted));
  } catch (error: any) {
    yield put(newsActions.onDeleteManyFailed(error.data.message));
  }
}

function* editNews(action: AnyAction) {
  try {
    const parseCompaniesId = action.newNotice.companies.map((element: ICompany) => {
      return element.id;
    });
    const editedNews: INews = yield call(newsService.editOne, action.newId, {
      ...action.newNotice,
      companies: parseCompaniesId,
    });
    yield put(newsActions.onEditOneSucceeded(editedNews));
  } catch (error: any) {
    yield put(newsActions.onEditOneFailed(error.data.message));
  }
}

function* createNews(action: AnyAction) {
  try {
    yield call(newsService.createOne, action.news);
    const news: INews[] = yield call(newsService.getAll);
    yield put(newsActions.onCreateOneSucceeded(news));
  } catch (error: any) {
    yield put(newsActions.onCreateOneFailed(error.data.message));
  }
}

function* initializeEditView(action: AnyAction) {
  try {
    const [news, companies]: [INews, ICompany[]] = yield all([
      call(newsService.getOne, action.newId),
      call(companyService.getAll),
    ]);
    yield put(newsActions.initializeNewsEditViewSucceeded(news, companies));
  } catch (error: any) {
    yield put(newsActions.initializeNewsEditViewFailed(error.data.message));
  }
}

function* initializeCreateView(action: AnyAction) {
  try {
    const companies: ICompany[] = yield call(companyService.getAll);
    yield put(newsActions.initializeNewsCreateViewSucceeded(companies));
  } catch (error: any) {
    yield put(newsActions.initializeNewsCreateViewFailed(error.data.message));
  }
}

function* filter(action: AnyAction) {
  try {
    const response: IFilterResponse = yield call(
      newsService.getBy,
      action.filter,
      MAX_DOCUMENTS_PER_PAGE,
      action.page * MAX_DOCUMENTS_PER_PAGE,
    );
    yield put(newsActions.onNewsFilterSucceeded(response.items, response.total));
  } catch (error: any) {
    yield put(newsActions.onNewsFilterFailed(action.data.message));
  }
}

export default all([
  takeLatest(newsConstants.INITIALIZE_NEWS_LIST_VIEW_REQUESTED, listNews),
  takeLatest(newsConstants.INTIALIZE_NEWS_EDIT_VIEW_REQUESTED, initializeEditView),
  takeLatest(newsConstants.NEWS_ON_EDIT_ONE_REQUESTED, editNews),
  takeLatest(newsConstants.NEWS_ON_DELETE_ONE_REQUESTED, deleteNews),
  takeLatest(newsConstants.NEWS_ON_CREATE_ONE_REQUESTED, createNews),
  takeLatest(newsConstants.NEWS_ON_DELETE_MANY_REQUESTED, deleteManyNews),
  takeLatest(newsConstants.INTIALIZE_NEWS_CREATE_VIEW_REQUESTED, initializeCreateView),
  takeLatest(newsConstants.NEWS_FILTER_REQUESTED, filter),
]);
