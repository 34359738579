import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'HEADER', {
  TITLE: 'Welcome {{name}}!',
  CONFIRMATION_DIALOG_MESSAGE: 'Do you really want to logout?',
  LOGOUT: 'Log Out',
  USERS: 'Users',
  COMPANIES: 'Companies',
  NEWS: 'News',
  INSIGHTS: 'Insights',
  MATERIALITY: 'Materiality',
  EXPLORE_NEWS: 'Search Queries',
  MANAGE_NEWS: 'Manage News',
});
