import { InputLabel } from '@mui/material';
import styled from 'styled-components';

export const IndustyWrapper = styled.div``;

export const IndustyList = styled.div``;

export const Label = styled(InputLabel)`
  margin-bottom: 5px;
  color: ${(props) => props.theme.palette.secondary} !important;
`;
