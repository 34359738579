import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as insightsActions from 'src/redux/actions/insights';
import companiesSelector from 'src/redux/selectors/company/companies';
import industriesSelector from 'src/redux/selectors/company/industries';
import insightInfoSelector from 'src/redux/selectors/insights/insightInfo';

import { InisghtType } from 'src/helpers/enums/insights';
import { getInsightType } from 'src/helpers/insights';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { ICompany } from 'src/types/company';
import { INewInsights } from 'src/types/insights';
import InsightsNew from 'src/views/Insights/InsightsNew';
import {
  getInitialValuesInsightsCommentForm,
  getInputsFormCommentInsights,
  validationSchemaComment,
} from 'src/views/Insights/common/formProviderComment';
import {
  getInitialValuesInsightsTipForm,
  getInputsFormTipInsights,
  validationSchemaTip,
} from 'src/views/Insights/common/formProviderTip';
import {
  getInitialValuesInsightsVideoForm,
  getInputsFormVideoInsights,
  validationSchemaVideo,
} from 'src/views/Insights/common/formProviderVideo';

import LayoutContainer from '../Layout/Layout';

const InsightsNewContainer = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState('');

  const insightsInfo = useTypedSelector(insightInfoSelector());
  const companies: ICompany[] | null = useTypedSelector(companiesSelector());
  const industries: string[] = useTypedSelector(industriesSelector());

  const formTip = useFormik({
    initialValues: getInitialValuesInsightsTipForm(),
    validationSchema: validationSchemaTip,
    onSubmit: () => {
      onSubmit(type);
    },
  });

  const formComment = useFormik({
    initialValues: getInitialValuesInsightsCommentForm(),
    validationSchema: validationSchemaComment,
    onSubmit: () => {
      onSubmit(type);
    },
  });

  const formVideo = useFormik({
    initialValues: getInitialValuesInsightsVideoForm(),
    validationSchema: validationSchemaVideo,
    onSubmit: () => {
      onSubmit(type);
    },
  });

  const onSubmit = (type: string) => {
    const newInsight: INewInsights = getInsightType(
      type as InisghtType,
      formTip.values,
      formComment.values,
      formVideo.values,
    );
    dispatch(insightsActions.onCreateOneRequested(newInsight));
  };

  const handleSubmitForm = () => {
    switch (type) {
      case InisghtType.COMMENT:
        return formComment.handleSubmit;
      case InisghtType.VIDEO:
        return formVideo.handleSubmit;
      default:
        return formTip.handleSubmit;
    }
  };

  const onInitializeInsightView = useCallback(() => {
    dispatch(insightsActions.initializeInsightsCreateView());
  }, [dispatch]);

  useEffect(() => {
    onInitializeInsightView();
  }, [onInitializeInsightView]);

  return (
    <LayoutContainer loading={!companies}>
      <InsightsNew
        onSubmitForm={handleSubmitForm()}
        loading={insightsInfo.info.loading}
        inputsTip={getInputsFormTipInsights(formTip, companies!, industries)}
        inputsComment={getInputsFormCommentInsights(formComment, companies!, industries)}
        inputsVideo={getInputsFormVideoInsights(formVideo, companies!, industries)}
        formType={type}
        onChangeFormType={setType}
      />
    </LayoutContainer>
  );
};

export default InsightsNewContainer;
