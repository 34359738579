export const INITIALIZE_NEWS_QUERYS_LIST_VIEW_REQUESTED =
  'INITIALIZE_NEWS_QUERYS_LIST_VIEW_REQUESTED';

export const INTIIALIZE_NEWS_QUERYS_LIST_VIEW_SUCCEEDED =
  'INTIIALIZE_NEWS_QUERYS_LIST_VIEW_SUCCEEDED';

export const INTIIALIZE_NEWS_QUERYS_LIST_VIEW_FAILED = 'INTIIALIZE_NEWS_QUERYS_LIST_VIEW_FAILED';

export const NEWS_QUERYS_ON_CHANGE_ACTIVE_QUERY = 'NEWS_QUERYS_ON_CHANGE_ACTIVE_QUERY';

export const NEWS_QUERYS_ON_CHANGE_ACTIVE_QUERY_SUCCEEDED =
  'NEWS_QUERYS_ON_CHANGE_ACTIVE_QUERY_SUCCEEDED';

export const NEWS_QUERYS_ON_CHANGE_ACTIVE_QUERY_FAILED =
  'NEWS_QUERYS_ON_CHANGE_ACTIVE_QUERY_FAILED';

export const NEWS_QUERYS_ON_DELETE_ONE_REQUESTED = 'NEWS_QUERYS_ON_DELETE_ONE_REQUESTED';

export const NEWS_QUERYS_ON_DELETE_ONE_SUCCEEDED = 'NEWS_QUERYS_ON_DELETE_ONE_SUCCEEDED';

export const NEWS_QUERYS_ON_DELETE_ONE_FAILED = 'NEWS_QUERYS_ON_DELETE_ONE_FAILED';

export const NEWS_QUERYS_ON_DELETE_MANY_REQUESTED = 'NEWS_QUERYS_ON_DELETE_MANY_REQUESTED';

export const NEWS_QUERYS_ON_DELETE_MANY_SUCCEEDED = 'NEWS_QUERYS_ON_DELETE_MANY_SUCCEEDED';

export const NEWS_QUERYS_ON_DELETE_MANY_FAILED = 'NEWS_QUERYS_ON_DELETE_MANY_FAILED';

export const NEWS_QUERYS_ON_CREATE_ONE_REQUESTED = 'NEWS_QUERYS_ON_CREATE_ONE_REQUESTED';

export const NEWS_QUERYS_ON_CREATE_ONE_SUCCEEDED = 'NEWS_QUERYS_ON_CREATE_ONE_SUCCEEDED';

export const NEWS_QUERYS_ON_CREATE_ONE_FAILED = 'NEWS_QUERYS_ON_CREATE_ONE_FAILED';

export const INTIALIZE_NEWS_QUERYS_CREATE_VIEW_REQUESTED =
  'INTIALIZE_NEWS_QUERYS_CREATE_VIEW_REQUESTED';

export const INTIALIZE_NEWS_QUERYS_CREATE_VIEW_SUCCEEDED =
  'INTIALIZE_NEWS_QUERYS_CREATE_VIEW_SUCCEEDED';

export const INTIALIZE_NEWS_QUERYS_CREATE_VIEW_FAILED = 'INTIALIZE_NEWS_QUERYS_CREATE_VIEW_FAILED';

export const INTIALIZE_NEWS_QUERYS_EDIT_VIEW_REQUESTED =
  'INTIALIZE_NEWS_QUERYS_EDIT_VIEW_REQUESTED';

export const INTIALIZE_NEWS_QUERYS_EDIT_VIEW_SUCCEEDED =
  'INTIALIZE_NEWS_QUERYS_EDIT_VIEW_SUCCEEDED';

export const INTIALIZE_NEWS_QUERYS_EDIT_VIEW_FAILED = 'INTIALIZE_NEWS_QUERYS_EDIT_VIEW_FAILED';

export const NEWS_QUERYS_ON_EDIT_ONE_REQUESTED = 'NEWS_QUERYS_ON_EDIT_ONE_REQUESTED';

export const NEWS_QUERYS_ON_EDIT_ONE_SUCCEEDED = 'NEWS_QUERYS_ON_EDIT_ONE_SUCCEEDED';

export const NEWS_QUERYS_ON_EDIT_ONE_FAILED = 'NEWS_QUERYS_ON_EDIT_ONE_FAILED';

export const NEWS_QUERY_RUN_REQUESTED = 'NEWS_QUERY_RUN_REQUESTED';

export const NEWS_QUERY_RUN_SUCCEEDED = 'NEWS_QUERY_RUN_SUCCEEDED';

export const NEWS_QUERY_RUN_FAILED = 'NEWS_QUERY_RUN_FAILED';
