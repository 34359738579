import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'SELECT_INPUT', {
  HELP_TEXT_SHOW_TYPES: `Select who will see this information. If "Specific" is selected, the input "Add Company" will be enabled and you will be able to select what companies will this be visible to. If "Industry" is selected, the input "Add Industry" will be enabled and you will be able to select which industry will this be visible to. If "All" is selected, all of the existing companies will inmediately see this.`,
  HELP_TEXT_TIP_CATEGORY:
    'This selection also defines what icon will the Insight have (each category is linked to its unique icon).',
  HELP_TEXT_NEWS_QUERY_MARKETING: 'Country and language to use in the Bing search process.',
});
