import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

export const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const Message = styled.span`
  font-size: 16px;
`;
