import { serialize } from 'object-to-formdata';

import { INewInsights } from 'src/types/insights';
import { removeExtraData } from 'src/views/Insights/common/helpers';

import { apiDelete, get, post, put } from './api';

export const getAll = () => {
  return get('insights');
};

export const deleteMany = (insightsIds: string[]) => {
  return apiDelete('insights', {}, { insightsIds });
};

export const deleteOne = (insightId: string) => {
  return apiDelete(`insights/${insightId}`);
};

export const createOne = (insight: INewInsights) => {
  const newInsight = removeExtraData(insight);

  return post(
    'insights',
    serialize({ ...newInsight, companies: newInsight.companies.map((element) => element.id) }),
    {
      'Content-Type': 'multipart/form-data',
    },
  );
};

export const getOne = (insightId: string) => {
  return get(`insights/${insightId}`);
};

export const editOne = async (insightId: string, insights: INewInsights) => {
  const editInsight = removeExtraData(insights);

  return put(`insights/${insightId}`, serialize(editInsight), {
    'Content-Type': 'multipart/form-data',
  });
};
