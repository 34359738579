import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'TEXT_INPUT', {
  HELP_TEXT_SEARCH_NEWS_QUERY: `Keywords to use in the Bing search process.`,
  HELP_TEXT_NEWS_QUERY_NAME:
    "The name entered here will be the one you'll use to identify this search. Make sure it's descriptive.",
  HELP_TEXT_COMMENT_PERSON_NAME:
    'This field represents the name of the person who gave this insight. It will be displayed below the comment, to the right.',
  HELP_TEXT_COMMENT_JOB_TITLE:
    "This field represents the position of the person signing this insight. It will be displayed below the comment, next to the name, on the right. It's an optional field.",
});
