import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const totalMaterialitySelector = () =>
  createSelector(
    [(state: IReduxState) => state.materiality.totalMateriality],
    (totalMateriality) => totalMateriality,
  );

export default totalMaterialitySelector;
