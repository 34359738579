import DownloadIcon from '@mui/icons-material/Download';
import styled from 'styled-components';

export const NewsQuerysContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
`;

export const NewsQuerysContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const RunQuery = styled(DownloadIcon)`
  fill: ${(props) => props.theme.palette.secondary} !important;
`;
