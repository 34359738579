import React from 'react';

import PrivateRoute from 'src/components/PrivateRoute';
import MaterialityListContainer from 'src/containers/Materiality/MaterialityList';

const MaterialityRoutes = (isUserLogged: boolean) => {
  return [
    <PrivateRoute
      key="list"
      path="/materiality"
      exact
      component={MaterialityListContainer}
      isUserLogged={isUserLogged}
    />,
  ];
};

export default MaterialityRoutes;
