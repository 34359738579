import { Feed } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

import { IShowActionButtonProps } from './types';

const ShowActionButton = (props: IShowActionButtonProps) => {
  const { onClick } = props;

  return (
    <IconButton color="inherit" data-testid="action-buttons-show-button" onClick={onClick}>
      <Feed />
    </IconButton>
  );
};

export default ShowActionButton;
