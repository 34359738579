import { GridColumns } from '@mui/x-data-grid';
import i18next from 'i18next';
import React, { useState } from 'react';

import Modal from 'src/components/Modal';
import Table from 'src/components/Table';
import AddActionButton from 'src/components/Table/components/Add';
import { ActionTypes } from 'src/helpers/enums/tableActions';
import { ICompany } from 'src/types/company';

import NewCompanyWizard from '../NewCompanyWizard';
import './i18n';
import { CompanyWrapper, CompaniesList, TableWrapper } from './styles';
import { ICompanyListProps } from './types';

const CompanyList = (props: ICompanyListProps) => {
  const {
    companies,
    allCompaniesArray,
    onAddNew,
    disabled,
    showTable,
    onDelete,
    pathI18n,
    pathI18nHelp = '',
  } = props;
  const [showWizard, setShowWizard] = useState(false);

  const handleAddNew = (company: ICompany) => {
    onAddNew(company);
    setShowWizard(false);
  };

  const columns: GridColumns = [
    {
      field: 'company',
      headerName: 'Company name',
      flex: 1,
    },
  ];
  const rows = companies
    .map((element) => {
      return {
        id: element.id,
        company: element.name,
      };
    })
    .flat();

  const handleDelete = (companyId: string) => {
    onDelete(companyId);
  };

  return (
    <CompanyWrapper>
      <Modal open={showWizard} onClose={() => setShowWizard(false)}>
        <NewCompanyWizard companies={allCompaniesArray} onAddNew={handleAddNew} />
      </Modal>
      <CompaniesList>
        {rows.length > 0 && showTable && (
          <TableWrapper data-testid="companies-table">
            <Table
              columns={columns}
              rows={rows}
              title={i18next.t('COMPANY:TITLE')}
              loading={rows.some((row) => !row.id)}
              toolbar={false}
              pageSize={5}
              includeCheckboxes={false}
              autoHeight
              rowActions={[ActionTypes.DELETE]}
              onDeleteOne={handleDelete}
            />
          </TableWrapper>
        )}
      </CompaniesList>
      <AddActionButton
        label={i18next.t(`COMPANY:${pathI18n}`)}
        onClick={() => setShowWizard(true)}
        disabled={disabled}
        helpText={i18next.t(`COMPANY:${pathI18nHelp}`)}
      />
    </CompanyWrapper>
  );
};

export default CompanyList;
