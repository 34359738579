import { AnyAction } from 'redux';

import { ICompany } from 'src/types/company';
import IReducerInfo from 'src/types/reducer';

import * as companyConstants from '../constants/company';
import * as insightsConstants from '../constants/insights';
import * as materialityConstants from '../constants/materiality';
import * as newsConstants from '../constants/news';
import * as newsQuerysConstants from '../constants/newsQuery';
import * as userConstants from '../constants/user';

export interface ICompanyReducerState {
  companies: ICompany[] | null;
  companyToEdit: ICompany | null;
  companyInfo: IReducerInfo;
  totalCompanies: number;
}

const defaultState: ICompanyReducerState = {
  companies: null,
  companyToEdit: null,
  totalCompanies: 0,
  companyInfo: {
    info: {
      error: false,
      success: false,
      loading: false,
    },
    message: '',
  },
};

const setCompanies = (state: ICompanyReducerState, companies: ICompany[] | null) => ({
  ...state,
  companies,
});

const setCompanyToEdit = (state: ICompanyReducerState, companyToEdit: ICompany | null) => ({
  ...state,
  companyToEdit,
});

const removeCompanies = (state: ICompanyReducerState, idsToRemove: string[]) => {
  const newCompanies = state.companies?.filter((u) => !idsToRemove.includes(u.id)) || null;
  return {
    ...state,
    companies: newCompanies,
  };
};

const setCompanyInfo = (
  state: ICompanyReducerState,
  error: boolean,
  success: boolean,
  loading: boolean,
  message: string,
) => {
  return {
    ...state,
    companyInfo: {
      info: {
        error,
        success,
        loading,
      },
      message,
    },
  };
};

const setTotalCompanies = (state: ICompanyReducerState, totalCompanies: number) => ({
  ...state,
  totalCompanies,
});

const companyReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case companyConstants.INITIALIZE_COMPANIES_LIST_VIEW_REQUESTED:
      return setCompanyInfo(
        setCompanies(setTotalCompanies(state, 0), null),
        false,
        false,
        true,
        '',
      );
    case companyConstants.INTIALIZE_COMPANIES_EDIT_VIEW_REQUESTED:
    case companyConstants.COMPANIES_ON_DELETE_ONE_REQUESTED:
    case companyConstants.COMPANIES_ON_DELETE_MANY_REQUESTED:
    case companyConstants.COMPANIES_ON_EDIT_ONE_REQUESTED:
    case companyConstants.COMPANIES_ON_CREATE_ONE_REQUESTED:
    case companyConstants.INITIALIZE_NEW_COMPANIES_VIEW_REQUESTED:
    case companyConstants.COMPANIES_FILTER_REQUESTED:
      return setCompanyInfo(state, false, false, true, '');
    case companyConstants.INTIIALIZE_COMPANIES_LIST_VIEW_SUCCEEDED:
    case companyConstants.COMPANIES_FILTER_SUCCEEDED:
      return setCompanyInfo(
        setCompanies(setTotalCompanies(state, action.totalCompanies), action.companies),
        false,
        true,
        false,
        '',
      );
    case userConstants.INTIALIZE_USERS_CREATE_VIEW_SUCCEEDED:
    case userConstants.INTIALIZE_USERS_EDIT_VIEW_SUCCEEDED:
    case materialityConstants.INITIALIZE_MATERIALITY_LIST_VIEW_SUCCEEDED:
    case newsConstants.INTIALIZE_NEWS_CREATE_VIEW_SUCCEEDED:
    case newsConstants.INTIALIZE_NEWS_EDIT_VIEW_SUCCEEDED:
    case insightsConstants.INTIALIZE_INSIGHTS_EDIT_VIEW_SUCCEEDED:
    case insightsConstants.INTIALIZE_INSIGHTS_CREATE_VIEW_SUCCEEDED:
    case companyConstants.INITIALIZE_NEW_COMPANIES_VIEW_SUCCEEDED:
    case newsQuerysConstants.INTIALIZE_NEWS_QUERYS_CREATE_VIEW_SUCCEEDED:
    case newsQuerysConstants.INTIALIZE_NEWS_QUERYS_EDIT_VIEW_SUCCEEDED:
      return setCompanyInfo(setCompanies(state, action.companies), false, true, false, '');
    case companyConstants.INTIALIZE_COMPANIES_EDIT_VIEW_SUCCEEDED:
      return setCompanyInfo(
        setCompanyToEdit(setCompanies(state, action.allCompanies), action.company),
        false,
        true,
        false,
        '',
      );
    case companyConstants.COMPANIES_ON_DELETE_ONE_SUCCEEDED:
      return setCompanyInfo(removeCompanies(state, [action.companyId]), false, true, false, '');
    case companyConstants.COMPANIES_ON_DELETE_MANY_SUCCEEDED:
      return setCompanyInfo(removeCompanies(state, action.companyIds), false, true, false, '');
    case companyConstants.COMPANIES_ON_EDIT_ONE_SUCCEEDED:
      return setCompanyInfo(setCompanyToEdit(state, action.company), false, true, false, '');
    case companyConstants.COMPANIES_ON_CREATE_ONE_SUCCEEDED:
      return setCompanyInfo(setCompanies(state, action.companies), false, true, false, '');
    case companyConstants.COMPANIES_ON_DELETE_ONE_FAILED:
    case companyConstants.COMPANIES_ON_DELETE_MANY_FAILED:
    case companyConstants.COMPANIES_ON_EDIT_ONE_FAILED:
    case companyConstants.COMPANIES_ON_CREATE_ONE_FAILED:
      return setCompanyInfo(state, false, false, false, '');
    case companyConstants.INTIIALIZE_COMPANIES_LIST_VIEW_FAILED:
    case companyConstants.INTIALIZE_COMPANIES_EDIT_VIEW_FAILED:
    case companyConstants.INITIALIZE_NEW_COMPANIES_VIEW_FAILED:
    case companyConstants.COMPANIES_FILTER_FAILED:
      return setCompanyInfo(state, true, false, false, action.errorMessage);
    default:
      return state;
  }
};

export default companyReducer;
