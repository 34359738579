import styled from 'styled-components';

export const InputWrapper = styled.div`
  flex: 1;
`;

export const ButtonWrapper = styled.div``;

export const Title = styled.h2`
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  color: ${(props) => props.theme.palette.secondary};
`;
