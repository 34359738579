import React from 'react';

import PrivateRoute from 'src/components/PrivateRoute';
import NewsEditContainer from 'src/containers/News/NewsEdit';
import NewsListContainer from 'src/containers/News/NewsList';
import NoticeNewContainer from 'src/containers/News/NoticeNew';
import NewsDraftEditContainer from 'src/containers/NewsDraft/NewsDraftEdit';
import NewsDraftListContainer from 'src/containers/NewsDraft/NewsDraftList';
import BingQuerysEditContainer from 'src/containers/NewsQuery/Bing/BingQueryEdit';
import BingQueryNewContainer from 'src/containers/NewsQuery/Bing/BingQueryNew';
import NewsQueryListContainer from 'src/containers/NewsQuery/NewsQueryList';

const NewsRoutes = (isUserLogged: boolean) => {
  return [
    <PrivateRoute
      key="list"
      path="/news"
      exact
      component={NewsListContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="new"
      path="/news/new"
      component={NoticeNewContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="edit"
      path="/news/edit/:id"
      component={NewsEditContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="newNewsQuery"
      path="/news-explore/bing/new"
      component={BingQueryNewContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="editNewsQuery"
      path="/news-explore/bing/edit/:id"
      component={BingQuerysEditContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="listNewsQuery"
      exact
      path="/news-explore"
      component={NewsQueryListContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      exact
      key="list-draft"
      path="/news/draft"
      component={NewsDraftListContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="edit-draft"
      path="/news/draft/edit/:id"
      component={NewsDraftEditContainer}
      isUserLogged={isUserLogged}
    />,
  ];
};

export default NewsRoutes;
