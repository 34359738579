import * as insightsConstants from 'src/redux/constants/insights';

import { ICompany } from 'src/types/company';
import { IInsights, INewInsights } from 'src/types/insights';

export const initializeInsightsListView = () => ({
  type: insightsConstants.INITIALIZE_INSIGHTS_LIST_VIEW_REQUESTED,
});

export const initializeInsightsListViewSucceeded = (insights: IInsights[]) => ({
  type: insightsConstants.INITIALIZE_INSIGHTS_LIST_VIEW_SUCCEEDED,
  insights,
});

export const initializeInsightsListViewFailed = (errorMessage: string) => ({
  type: insightsConstants.INITIALIZE_INSIGHTS_LIST_VIEW_FAILED,
  errorMessage,
});

export const onDeleteOneRequested = (insightId: string) => ({
  type: insightsConstants.INSIGHTS_ON_DELETE_ONE_REQUESTED,
  insightId,
});

export const onDeleteOneSucceeded = (insightId: string) => ({
  type: insightsConstants.INSIGHTS_ON_DELETE_ONE_SUCCEEDED,
  insightId,
});

export const onDeleteOneFailed = (errorMessage: string) => ({
  type: insightsConstants.INSIGHTS_ON_DELETE_ONE_FAILED,
  errorMessage,
});

export const onDeleteManyRequested = (insightIds: string[]) => ({
  type: insightsConstants.INSIGHTS_ON_DELETE_MANY_REQUESTED,
  insightIds,
});

export const onDeleteManySucceeded = (insightIds: string[], countDeleted: number) => ({
  type: insightsConstants.INSIGHTS_ON_DELETE_MANY_SUCCEEDED,
  insightIds,
  countDeleted,
});

export const onDeleteManyFailed = (errorMessage: string) => ({
  type: insightsConstants.INSIGHTS_ON_DELETE_MANY_FAILED,
  errorMessage,
});

export const initializeInsightsCreateView = () => ({
  type: insightsConstants.INTIALIZE_INSIGHTS_CREATE_VIEW_REQUESTED,
});

export const initializeInsightsCreateViewSucceeded = (companies: ICompany[]) => ({
  type: insightsConstants.INTIALIZE_INSIGHTS_CREATE_VIEW_SUCCEEDED,
  companies,
});

export const initializeInsightsCreateViewFailed = (errorMessage: string) => ({
  type: insightsConstants.INTIALIZE_INSIGHTS_CREATE_VIEW_FAILED,
  errorMessage,
});

export const onCreateOneRequested = (insights: INewInsights) => ({
  type: insightsConstants.INSIGHTS_ON_CREATE_ONE_REQUESTED,
  insights,
});

export const onCreateOneSucceeded = (insights: INewInsights[]) => ({
  type: insightsConstants.INSIGHTS_ON_CREATE_ONE_SUCCEEDED,
  insights,
});

export const onCreateOneFailed = (errorMessage: string) => ({
  type: insightsConstants.INSIGHTS_ON_CREATE_ONE_FAILED,
  errorMessage,
});

export const initializeInsightsEditView = (insightsId: string) => ({
  type: insightsConstants.INTIALIZE_INSIGHTS_EDIT_VIEW_REQUESTED,
  insightsId,
});

export const initializeInsightsEditViewSucceeded = (
  insights: IInsights,
  companies: ICompany[],
) => ({
  type: insightsConstants.INTIALIZE_INSIGHTS_EDIT_VIEW_SUCCEEDED,
  insights,
  companies,
});

export const initializeInsightsEditViewFailed = (errorMessage: string) => ({
  type: insightsConstants.INTIALIZE_INSIGHTS_EDIT_VIEW_FAILED,
  errorMessage,
});

export const onEditOneRequested = (insightId: string, newInsights: INewInsights) => ({
  type: insightsConstants.INSIGHTS_ON_EDIT_ONE_REQUESTED,
  insightId,
  newInsights,
});

export const onEditOneSucceeded = (insights: IInsights) => ({
  type: insightsConstants.INSIGHTS_ON_EDIT_ONE_SUCCEEDED,
  insights,
});

export const onEditOneFailed = (errorMessage: string) => ({
  type: insightsConstants.INSIGHTS_ON_EDIT_ONE_FAILED,
  errorMessage,
});
