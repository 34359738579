import { Step, StepLabel, Stepper } from '@mui/material';
import styled from 'styled-components';

export const WizardWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledStepper = styled(Stepper)`
  .MuiSvgIcon-root {
    color: ${(props) => props.theme.palette.common.black};
  }
  .Mui-active {
    color: ${(props) => props.theme.palette.secondary} !important;
  }
  .Mui-completed {
    color: ${(props) => props.theme.palette.primary} !important;
  }
`;

export const StepWrapper = styled(Step)``;

export const StepItem = styled(StepLabel)``;
