import { FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { IFormInput } from 'src/components/Form/types';
import FileInput from 'src/components/Inputs/FileInput';
import TextInput from 'src/components/Inputs/TextInput';
import { IEditNoticeDraft, INewsDraft } from 'src/types/news';

export const validationSchema = Yup.object({
  title: Yup.string().required('Title is required').trim(),
  description: Yup.string().required('Description is required').trim(),
  link: Yup.string().required('Link is required').trim(),
  thumbnail: Yup.string().trim(),
});

export const getInitialValuesNewsDraftForm = (newsToEdit?: INewsDraft | null) => {
  return {
    title: newsToEdit ? newsToEdit.title : '',
    description: newsToEdit ? newsToEdit.description : '',
    link: newsToEdit ? newsToEdit.link : '',
    thumbnail: newsToEdit ? newsToEdit.thumbnail : ('' as string | File),
  };
};

export const getInputsFormNewsDraft = (config: FormikProps<IEditNoticeDraft>) => {
  const inputs: IFormInput[] = [
    {
      key: 'title',
      input: (
        <TextInput
          id="title"
          name="title"
          placeholder="Title"
          error={
            config.touched.title && Boolean(config.errors.title)
              ? (config.errors.title as string)
              : ''
          }
          value={config.values.title}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'description',
      input: (
        <TextInput
          id="description"
          name="description"
          placeholder="Description"
          multiline={true}
          multilineRows={5}
          error={
            config.touched.description && Boolean(config.errors.description)
              ? (config.errors.description as string)
              : ''
          }
          value={config.values.description}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'link',
      input: (
        <TextInput
          id="link"
          name="link"
          placeholder="Link"
          error={
            config.touched.link && Boolean(config.errors.link) ? (config.errors.link as string) : ''
          }
          value={config.values.link}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'thumbnail',
      input: (
        <FileInput
          id="thumbnail"
          name="thumbnail"
          value={config.values.thumbnail}
          placeholder="Thumbnail"
          error={
            config.touched.thumbnail && Boolean(config.errors.thumbnail)
              ? (config.errors.thumbnail as string)
              : ''
          }
          onChange={(newFile: File | string) => config.setFieldValue('thumbnail', newFile)}
        />
      ),
    },
  ];
  return inputs;
};
