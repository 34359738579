import { Checkbox } from '@mui/material';
import React from 'react';

import { WrapperCheckbox } from './styles';
import { ICheckboxInput } from './types';

const CheckBoxInput = (props: ICheckboxInput) => {
  const { id, value, isChecked, onChange } = props;

  const handleChange = () => {
    onChange(value, id);
  };
  return (
    <WrapperCheckbox>
      <Checkbox
        id={id}
        inputProps={{
          // @ts-ignore
          'data-testid': 'checkBoxInput',
        }}
        checked={isChecked}
        onChange={handleChange}
      />
    </WrapperCheckbox>
  );
};

export default CheckBoxInput;
