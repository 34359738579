import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as newsActions from 'src/redux/actions/news';
import companiesSelector from 'src/redux/selectors/company/companies';
import industriesSelector from 'src/redux/selectors/company/industries';
import newsInfoSelector from 'src/redux/selectors/news/newsInfo';
import newsToEditSelector from 'src/redux/selectors/news/newsToEdit';

import { NewsTypes } from 'src/helpers/enums/news';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { ICompany } from 'src/types/company';
import { INewNotice } from 'src/types/news';
import NoticeEdit from 'src/views/News/NewsEdit';
import {
  getInitialValuesNewsForm,
  getInputsFormNews,
  validationSchema,
} from 'src/views/News/common/formProvider';

import LayoutContainer from '../Layout/Layout';

const NewsEditContainer = () => {
  const dispatch = useDispatch();
  const routeParams = useRouteMatch<{ id: string }>();

  const newsInfo = useTypedSelector(newsInfoSelector());
  const newsToEdit = useSelector(newsToEditSelector());
  const companies: ICompany[] | null = useTypedSelector(companiesSelector());
  const industries: string[] = useTypedSelector(industriesSelector());

  const form = useFormik({
    initialValues: getInitialValuesNewsForm(newsToEdit),
    validationSchema,
    onSubmit: () => {
      onSubmit(newsToEdit!.id, form.values);
    },
  });

  const onSubmit = (id: string, newNotice: INewNotice) => {
    let showTypeError = '';
    switch (form.values.showType) {
      case NewsTypes.INDUSTRY:
        showTypeError =
          form.values.industry.length === 0 ? 'One industry is at least required' : '';
        break;
      case NewsTypes.SPECIFIC:
        showTypeError =
          form.values.companies.length === 0 ? 'One company is at least required' : '';
        break;
    }
    if (showTypeError !== '') {
      form.setFieldError('showType', showTypeError);
    } else {
      dispatch(newsActions.onEditOneRequested(id, newNotice));
    }
  };

  const onInitializeNewsView = useCallback(() => {
    dispatch(newsActions.initializeNewsEditView(routeParams.params.id));
  }, [dispatch, routeParams.params.id]);

  useEffect(() => {
    if (newsToEdit) {
      form.setValues(getInitialValuesNewsForm(newsToEdit));
    }
    // eslint-disable-next-line
  }, [newsToEdit]);

  useEffect(() => {
    onInitializeNewsView();
  }, [onInitializeNewsView]);

  return (
    <LayoutContainer loading={!companies || !newsToEdit}>
      <NoticeEdit
        loading={newsInfo.info.loading}
        inputs={getInputsFormNews(form, companies!, industries)}
        onSubmitForm={form.handleSubmit}
      />
    </LayoutContainer>
  );
};

export default NewsEditContainer;
