import styled from 'styled-components';

export const ModelContent = styled.div`
  width: 90%;
  max-width: 650px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.palette.common.white};
`;
