import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as insightsActions from 'src/redux/actions/insights';
import companiesSelector from 'src/redux/selectors/company/companies';
import industriesSelector from 'src/redux/selectors/company/industries';
import insightInfoSelector from 'src/redux/selectors/insights/insightInfo';
import insightToEditSelector from 'src/redux/selectors/insights/insightToEdit';

import { InisghtType } from 'src/helpers/enums/insights';
import { getInsightType } from 'src/helpers/insights';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { ICompany } from 'src/types/company';
import {
  INewInsights,
  INewInsightsComment,
  INewInsightsTip,
  INewInsightsVideo,
} from 'src/types/insights';
import InsightsEdit from 'src/views/Insights/InsightsEdit';
import {
  getInitialValuesInsightsCommentForm,
  validationSchemaComment,
  getInputsFormCommentInsights,
} from 'src/views/Insights/common/formProviderComment';
import {
  getInitialValuesInsightsTipForm,
  getInputsFormTipInsights,
  validationSchemaTip,
} from 'src/views/Insights/common/formProviderTip';
import {
  getInitialValuesInsightsVideoForm,
  getInputsFormVideoInsights,
  validationSchemaVideo,
} from 'src/views/Insights/common/formProviderVideo';

import LayoutContainer from '../Layout/Layout';

const InsightsEditContainer = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState('');
  const routeParams = useRouteMatch<{ id: string }>();

  const insightToEdit = useTypedSelector(insightToEditSelector());
  const insightsInfo = useTypedSelector(insightInfoSelector());
  const companies: ICompany[] | null = useTypedSelector(companiesSelector());
  const industries: string[] = useTypedSelector(industriesSelector());

  const formTip = useFormik({
    initialValues: getInitialValuesInsightsTipForm(insightToEdit as INewInsightsTip),
    validationSchema: validationSchemaTip,
    onSubmit: () => {
      onSubmit(type);
    },
  });
  const formComment = useFormik({
    initialValues: getInitialValuesInsightsCommentForm(insightToEdit as INewInsightsComment),
    validationSchema: validationSchemaComment,
    onSubmit: () => {
      onSubmit(type);
    },
  });

  const formVideo = useFormik({
    initialValues: getInitialValuesInsightsVideoForm(insightToEdit as INewInsightsVideo),
    validationSchema: validationSchemaVideo,
    onSubmit: () => {
      onSubmit(type);
    },
  });

  const onSubmit = (type: string) => {
    const newInsight: INewInsights = getInsightType(
      type as InisghtType,
      formTip.values,
      formComment.values,
      formVideo.values,
    );

    dispatch(insightsActions.onEditOneRequested(routeParams.params.id, newInsight));
  };

  const onInitializeInsightsEdit = useCallback(() => {
    dispatch(insightsActions.initializeInsightsEditView(routeParams.params.id));
  }, [dispatch, routeParams.params.id]);

  useEffect(() => {
    onInitializeInsightsEdit();
  }, [onInitializeInsightsEdit]);

  /* eslint-disable */
  useEffect(() => {
    if (insightToEdit) {
      switch (insightToEdit.type) {
        case InisghtType.COMMENT:
          formComment.setValues(
            getInitialValuesInsightsCommentForm(insightToEdit as INewInsightsComment),
          );
          break;
        case InisghtType.VIDEO:
          formVideo.setValues(
            getInitialValuesInsightsVideoForm(insightToEdit as INewInsightsVideo),
          );
          break;
        default:
          formTip.setValues(getInitialValuesInsightsTipForm(insightToEdit as INewInsightsTip));
          break;
      }
      setType(insightToEdit.type!);
    }
  }, [
    insightToEdit?.type,
    insightToEdit?.companies,
    insightToEdit?.industries,
    insightToEdit?.showType,
  ]);
  /* eslint-enable */

  const handleSubmitForm = () => {
    switch (type) {
      case InisghtType.COMMENT:
        return formComment.handleSubmit;
      case InisghtType.VIDEO:
        return formVideo.handleSubmit;
      default:
        return formTip.handleSubmit;
    }
  };

  return (
    <LayoutContainer loading={!insightToEdit || !companies}>
      <InsightsEdit
        onSubmitForm={handleSubmitForm()}
        loading={insightsInfo.info.loading}
        inputsTip={getInputsFormTipInsights(formTip, companies!, industries)}
        inputsComment={getInputsFormCommentInsights(formComment, companies!, industries)}
        inputsVideo={getInputsFormVideoInsights(formVideo, companies!, industries)}
        formType={type}
      />
    </LayoutContainer>
  );
};

export default InsightsEditContainer;
