export const markets = [
  {
    region: 'Argentina',
    language: 'Spanish',
    mktCode: 'es-AR',
  },
  {
    region: 'Australia',
    language: 'English',
    mktCode: 'en-AU',
  },
  {
    region: 'Austria',
    language: 'German',
    mktCode: 'de-AT',
  },
  {
    region: 'Belgium',
    language: 'Dutch',
    mktCode: 'nl-BE',
  },
  {
    region: 'Belgium',
    language: 'French',
    mktCode: 'fr-BE',
  },
  {
    region: 'Brazil',
    language: 'Portuguese',
    mktCode: 'pt-BR',
  },
  {
    region: 'Canada',
    language: 'English',
    mktCode: 'en-CA',
  },
  {
    region: 'Canada',
    language: 'French',
    mktCode: 'fr-CA',
  },
  {
    region: 'Chile',
    language: 'Spanish',
    mktCode: 'es-CL',
  },
  {
    region: 'Denmark',
    language: 'Danish',
    mktCode: 'da-DK',
  },
  {
    region: 'Finland',
    language: 'Finnish',
    mktCode: 'fi-FI',
  },
  {
    region: 'France',
    language: 'French',
    mktCode: 'fr-FR',
  },
  {
    region: 'Germany',
    language: 'German',
    mktCode: 'de-DE',
  },
  {
    region: 'Hong Kong SAR',
    language: 'Traditional Chinese',
    mktCode: 'zh-HK',
  },
  {
    region: 'India',
    language: 'English',
    mktCode: 'en-IN',
  },
  {
    region: 'Indonesia',
    language: 'English',
    mktCode: 'en-ID',
  },
  {
    region: 'Italy',
    language: 'Italian',
    mktCode: 'it-IT',
  },
  {
    region: 'Korea',
    language: 'Korean',
    mktCode: 'ko-KR',
  },
  {
    region: 'Malaysia',
    language: 'English',
    mktCode: 'en-MY',
  },
  {
    region: 'Mexico',
    language: 'Spanish',
    mktCode: 'es-MX',
  },
  {
    region: 'Netherlands',
    language: 'Dutch',
    mktCode: 'nl-NL',
  },
  {
    region: 'New Zealand',
    language: 'English',
    mktCode: 'en-NZ',
  },
  {
    region: 'Norway',
    language: 'Norwegian',
    mktCode: 'no-NO',
  },
  {
    region: "People's republic of China",
    language: 'Chinese',
    mktCode: 'zh-CN',
  },
  {
    region: 'Poland',
    language: 'Polish',
    mktCode: 'pl-PL',
  },
  {
    region: 'Republic of the Philippines',
    language: 'English',
    mktCode: 'en-PH',
  },
  {
    region: 'Russia',
    language: 'Russian',
    mktCode: 'ru-RU',
  },
  {
    region: 'South Africa',
    language: 'English',
    mktCode: 'en-ZA',
  },
  {
    region: 'Spain',
    language: 'Spanish',
    mktCode: 'es-ES',
  },
  {
    region: 'Sweden',
    language: 'Swedish',
    mktCode: 'sv-SE',
  },
  {
    region: 'Switzerland',
    language: 'French',
    mktCode: 'fr-CH',
  },
  {
    region: 'Switzerland',
    language: 'German',
    mktCode: 'de-CH',
  },
  {
    region: 'Taiwan',
    language: 'Traditional Chinese',
    mktCode: 'zh-TW',
  },
  {
    region: 'Turkey',
    language: 'Turkish',
    mktCode: 'tr-TR',
  },
  {
    region: 'United Kingdom',
    language: 'English',
    mktCode: 'en-GB',
  },
  {
    region: 'United States',
    language: 'English',
    mktCode: 'en-US',
  },
  {
    region: 'United States',
    language: 'Spanish',
    mktCode: 'es-US',
  },
];
