import i18next from 'i18next';
import React from 'react';

import Table from 'src/components/Table';
import { IFilterInput } from 'src/components/Table/components/Filter/types';
import { NewsNavigationTabs } from 'src/helpers/enums/news';
import { useFilter } from 'src/hooks/useFilter';
import { INews } from 'src/types/news';

import NewsNavigation from '../components/NewsNavigation';
import './i18n';
import { NavigationTabsWrapper, NewsContainer, NewsContent } from './styles';
import { INewsListProps } from './types';

const NewsList = (props: INewsListProps) => {
  const {
    columns,
    error,
    isLoading,
    news,
    totalNews,
    onFilter,
    onTabChange,
    onAddNew,
    onEdit,
    onDeleteMany,
    onDeleteOne,
  } = props;

  const [filter, page, setFilter, setPage] = useFilter<Partial<INews>>(onFilter);

  const filtersToApply: IFilterInput[] = [
    {
      label: 'Title',
      value: filter.title || '',
      type: 'text',
      onChange: (value: string) => setFilter({ title: value }),
    },
  ];

  return (
    <NewsContainer data-testid="news-view">
      <NavigationTabsWrapper>
        <NewsNavigation currentTab={NewsNavigationTabs.PUBLISHED_NEWS} onChangeTab={onTabChange} />
      </NavigationTabsWrapper>
      <NewsContent>
        <Table
          columns={columns}
          error={error}
          rows={news}
          title={i18next.t('NEWS_LIST:TITLE')}
          loading={isLoading}
          autoHeight
          filter={filtersToApply}
          rowHeight={100}
          page={page}
          totalRows={totalNews}
          onNextPage={(currentPage: number) => setPage(currentPage)}
          onAddNew={onAddNew}
          onEdit={onEdit}
          onDeleteOne={onDeleteOne}
          onDeleteMany={onDeleteMany}
        />
      </NewsContent>
    </NewsContainer>
  );
};

export default NewsList;
