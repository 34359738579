import { AnyAction } from 'redux';

import { INews } from 'src/types/news';
import IReducerInfo from 'src/types/reducer';

import * as newsConstants from '../constants/news';

export interface INewsReducerState {
  news: INews[] | null;
  newsToEdit: INews | null;
  newsInfo: IReducerInfo;
  totalNews: number;
}

const defaultState: INewsReducerState = {
  news: null,
  newsToEdit: null,
  totalNews: 0,
  newsInfo: {
    info: {
      error: false,
      success: false,
      loading: false,
    },
    message: '',
  },
};

const setNews = (state: INewsReducerState, news: INews[] | null) => ({
  ...state,
  news,
});

const setNewToEdit = (state: INewsReducerState, newsToEdit: INews | null) => ({
  ...state,
  newsToEdit,
});

const removeNews = (state: INewsReducerState, idsToRemove: string[]) => {
  const newNotice = state.news?.filter((u) => !idsToRemove.includes(u.id)) || null;
  return {
    ...state,
    news: newNotice,
  };
};

const setTotalNews = (state: INewsReducerState, totalNews: number) => ({
  ...state,
  totalNews,
});

const setNewsInfo = (
  state: INewsReducerState,
  error: boolean,
  success: boolean,
  loading: boolean,
  message: string,
) => {
  return {
    ...state,
    newsInfo: {
      info: {
        error,
        success,
        loading,
      },
      message,
    },
  };
};

const newsReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case newsConstants.INITIALIZE_NEWS_LIST_VIEW_REQUESTED:
    case newsConstants.INTIALIZE_NEWS_EDIT_VIEW_REQUESTED:
    case newsConstants.NEWS_ON_DELETE_ONE_REQUESTED:
    case newsConstants.NEWS_ON_DELETE_MANY_REQUESTED:
    case newsConstants.NEWS_ON_EDIT_ONE_REQUESTED:
    case newsConstants.NEWS_ON_CREATE_ONE_REQUESTED:
      return setNewsInfo(setTotalNews(state, 0), false, false, true, '');
    case newsConstants.NEWS_FILTER_REQUESTED:
      return setNewsInfo(state, false, false, true, '');
    case newsConstants.INTIIALIZE_NEWS_LIST_VIEW_SUCCEEDED:
    case newsConstants.NEWS_FILTER_SUCCEEDED:
      return setNewsInfo(
        setNews(setTotalNews(state, action.totalNews), action.news),
        false,
        true,
        false,
        '',
      );
    case newsConstants.INTIALIZE_NEWS_EDIT_VIEW_SUCCEEDED:
      return setNewsInfo(setNewToEdit(state, action.news), false, true, false, '');
    case newsConstants.NEWS_ON_DELETE_ONE_SUCCEEDED:
      return setNewsInfo(removeNews(state, [action.newId]), false, true, false, '');
    case newsConstants.NEWS_ON_DELETE_MANY_SUCCEEDED:
      return setNewsInfo(removeNews(state, action.newsIds), false, true, false, '');
    case newsConstants.NEWS_ON_EDIT_ONE_SUCCEEDED:
      return setNewsInfo(setNewToEdit(state, action.news), false, true, false, '');
    case newsConstants.NEWS_ON_CREATE_ONE_SUCCEEDED:
      return setNewsInfo(setNews(state, action.news), false, true, false, '');
    case newsConstants.NEWS_ON_DELETE_ONE_FAILED:
    case newsConstants.NEWS_ON_DELETE_MANY_FAILED:
    case newsConstants.NEWS_ON_EDIT_ONE_FAILED:
    case newsConstants.NEWS_ON_CREATE_ONE_FAILED:
      return setNewsInfo(state, false, false, false, '');
    case newsConstants.INTIIALIZE_NEWS_LIST_VIEW_FAILED:
    case newsConstants.INTIALIZE_NEWS_EDIT_VIEW_FAILED:
    case newsConstants.NEWS_FILTER_FAILED:
      return setNewsInfo(state, true, false, false, action.errorMessage);
    default:
      return state;
  }
};

export default newsReducer;
