import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components';

export const DataTable = styled(DataGrid)`
  flex: 1;
  .MuiCircularProgress-root {
    color: ${(props) => props.theme.palette.secondary};
  }
  .MuiCheckbox-root {
    color: ${(props) => props.theme.palette.secondary} !important;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
`;
