import { NewsTypes } from 'src/helpers/enums/news';
import { ICompany } from 'src/types/company';
import { INewNotice } from 'src/types/news';

export const deleteCompanies = (oldCompanies: ICompany[], companyId: string) => {
  return oldCompanies.filter((element) => element.id !== companyId);
};

export const deleteIndustry = (oldIndustries: string[], industryId: string) => {
  return oldIndustries.filter((element) => element !== industryId);
};

export const removeExtraData = (notice: INewNotice) => {
  switch (notice.showType) {
    case NewsTypes.SPECIFIC:
      return { ...notice, industry: [] };
    case NewsTypes.INDUSTRY:
      return { ...notice, companies: [] };
    default:
      return { ...notice, industry: [], companies: [] };
  }
};
