import { AnyAction } from 'redux';

import IReducerInfo from 'src/types/reducer';
import { IUser } from 'src/types/user';

import * as appConstants from '../constants/app';
import * as authConstants from '../constants/auth';

export interface IAuthReducerState {
  user: IUser | null;
  authInfo: IReducerInfo;
}

const defaultState: IAuthReducerState = {
  user: null,
  authInfo: {
    info: {
      error: false,
      success: false,
      loading: false,
    },
    message: '',
  },
};

const setAuthInfo = (
  state: IAuthReducerState,
  error: boolean,
  success: boolean,
  loading: boolean,
  message: string,
) => {
  return {
    ...state,
    authInfo: {
      info: {
        error,
        success,
        loading,
      },
      message,
    },
  };
};

const setUserLogged = (state: IAuthReducerState, userLoggeed: IUser) => {
  return {
    ...state,
    user: userLoggeed,
  };
};

const authReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_SUCCEDED:
    case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_SUCCEDED:
      return setUserLogged(state, action.user);
    case authConstants.USER_ON_INITIALIZE_LOGIN_VIEW:
      return setAuthInfo(state, false, false, false, '');
    case authConstants.USER_ON_LOGIN_REQUESTED:
      return setAuthInfo(state, false, false, true, '');
    case authConstants.USER_ON_LOGIN_SUCCEEDED:
      return setAuthInfo(state, false, true, false, '');
    case authConstants.USER_ON_LOGIN_FAILED:
      return setAuthInfo(state, true, false, false, action.errorMessage);
    default:
      return state;
  }
};

export default authReducer;
