import { SelectChangeEvent } from '@mui/material';
import i18next from 'i18next';
import React, { useState } from 'react';

import TextButton from 'src/components/Buttons/TextButton';
import SelectInput from 'src/components/Inputs/SelectInput';

import './i18n';
import { ButtonWrapper, InputsWrapper, InputWrapper, Title } from './styles';
import { IStepIndustryProps } from './types';

const StepIndustry = (props: IStepIndustryProps) => {
  const { onSubmit, industries } = props;
  const [industry, setIndustry] = useState<string>('');

  const handleIndustrySubmit = () => {
    onSubmit(industry);
  };

  const handleIndustryNameChange = (event: SelectChangeEvent<unknown>) => {
    setIndustry(event.target.value as string);
  };

  return (
    <>
      <Title>{i18next.t('STEP_INDUSTRY:TITLE')}</Title>
      <InputsWrapper>
        <InputWrapper>
          <SelectInput
            id="industry"
            name="industry"
            label={i18next.t('STEP_INDUSTRY:INPUT_INDUSTRY_LABEL')}
            value={industry}
            disabled={false}
            onChange={handleIndustryNameChange}
            options={industries.map((i) => ({
              value: i,
              text: i,
            }))}
          />
        </InputWrapper>
      </InputsWrapper>
      <ButtonWrapper>
        <TextButton
          text={i18next.t('STEP_INDUSTRY:BUTTON_TEXT')}
          isDisabled={industry === ''}
          isLoading={false}
          onSubmit={handleIndustrySubmit}
        />
      </ButtonWrapper>
    </>
  );
};

export default StepIndustry;
