import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';

import * as appActions from 'src/redux/actions/app';

import { getAuthToken } from 'src/helpers/user';

interface IPublicRouteProps extends RouteProps {
  restricted: boolean;
  isUserLogged: boolean;
}

const PublicRoute: FunctionComponent<IPublicRouteProps> = ({
  component: Component,
  restricted,
  isUserLogged,
  ...rest
}) => {
  const dispatch = useDispatch();

  const initializePublicRoute = useCallback(() => {
    dispatch(appActions.initializePublicRoute(restricted));
  }, [dispatch, restricted]);

  useEffect(() => {
    initializePublicRoute();
  }, [initializePublicRoute]);

  if (!Component) return null;

  return !getAuthToken() || isUserLogged ? (
    <Route data-testid="route" {...rest} render={(props) => <Component {...props} />} />
  ) : null;
};

export default PublicRoute;
