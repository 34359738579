export const INITIALIZE_NEWS_LIST_VIEW_REQUESTED = 'INITIALIZE_NEWS_LIST_VIEW_REQUESTED';

export const INTIIALIZE_NEWS_LIST_VIEW_SUCCEEDED = 'INTIIALIZE_NEWS_LIST_VIEW_SUCCEEDED';

export const INTIIALIZE_NEWS_LIST_VIEW_FAILED = 'INTIIALIZE_NEWS_LIST_VIEW_FAILED';

export const INITIALIZE_NEWS_DRAFT_LIST_VIEW_REQUESTED =
  'INITIALIZE_NEWS_DRAFT_LIST_VIEW_REQUESTED';

export const INTIIALIZE_NEWS_DRAFT_LIST_VIEW_SUCCEEDED =
  'INTIIALIZE_NEWS_DRAFT_LIST_VIEW_SUCCEEDED';

export const INTIIALIZE_NEWS_DRAFT_LIST_VIEW_FAILED = 'INTIIALIZE_NEWS_DRAFT_LIST_VIEW_FAILED';

export const NEWS_ON_DELETE_ONE_REQUESTED = 'NEWS_ON_DELETE_ONE_REQUESTED';

export const NEWS_ON_DELETE_ONE_SUCCEEDED = 'NEWS_ON_DELETE_ONE_SUCCEEDED';

export const NEWS_ON_DELETE_ONE_FAILED = 'NEWS_ON_DELETE_ONE_FAILED';

export const NEWS_ON_DELETE_MANY_REQUESTED = 'NEWS_ON_DELETE_MANY_REQUESTED';

export const NEWS_ON_DELETE_MANY_SUCCEEDED = 'NEWS_ON_DELETE_MANY_SUCCEEDED';

export const NEWS_ON_DELETE_MANY_FAILED = 'NEWS_ON_DELETE_MANY_FAILED';

export const NEWS_ON_EDIT_ONE_REQUESTED = 'NEWS_ON_EDIT_ONE_REQUESTED';

export const NEWS_ON_EDIT_ONE_SUCCEEDED = 'NEWS_ON_EDIT_ONE_SUCCEEDED';

export const NEWS_ON_EDIT_ONE_FAILED = 'NEWS_ON_EDIT_ONE_FAILED';

export const NEWS_ON_CREATE_ONE_REQUESTED = 'NEWS_ON_CREATE_ONE_REQUESTED';

export const NEWS_ON_CREATE_ONE_SUCCEEDED = 'NEWS_ON_CREATE_ONE_SUCCEEDED';

export const NEWS_ON_CREATE_ONE_FAILED = 'NEWS_ON_CREATE_ONE_FAILED';

export const INTIALIZE_NEWS_EDIT_VIEW_REQUESTED = 'INTIALIZE_NEWS_EDIT_VIEW_REQUESTED';

export const INTIALIZE_NEWS_EDIT_VIEW_SUCCEEDED = 'INTIALIZE_NEWS_EDIT_VIEW_SUCCEEDED';

export const INTIALIZE_NEWS_EDIT_VIEW_FAILED = 'INTIALIZE_NEWS_EDIT_VIEW_FAILED';

export const INTIALIZE_NEWS_DRAFT_EDIT_VIEW_REQUESTED = 'INTIALIZE_NEWS_DRAFT_EDIT_VIEW_REQUESTED';

export const INTIALIZE_NEWS_DRAFT_EDIT_VIEW_SUCCEEDED = 'INTIALIZE_NEWS_DRAFT_EDIT_VIEW_SUCCEEDED';

export const INTIALIZE_NEWS_DRAFT_EDIT_VIEW_FAILED = 'INTIALIZE_NEWS_DRAFT_EDIT_VIEW_FAILED';

export const INTIALIZE_NEWS_CREATE_VIEW_REQUESTED = 'INTIALIZE_NEWS_CREATE_VIEW_REQUESTED';

export const INTIALIZE_NEWS_CREATE_VIEW_SUCCEEDED = 'INTIALIZE_NEWS_CREATE_VIEW_SUCCEEDED';

export const INTIALIZE_NEWS_CREATE_VIEW_FAILED = 'INTIALIZE_NEWS_CREATE_VIEW_FAILED';

export const NEWS_FILTER_REQUESTED = 'NEWS_FILTER_REQUESTED';

export const NEWS_FILTER_SUCCEEDED = 'NEWS_FILTER_SUCCEEDED';

export const NEWS_FILTER_FAILED = 'NEWS_FILTER_FAILED';

export const NEWS_DRAFT_FILTER_REQUESTED = 'NEWS_DRAFT_FILTER_REQUESTED';

export const NEWS_DRAFT_FILTER_SUCCEEDED = 'NEWS_DRAFT_FILTER_SUCCEEDED';

export const NEWS_DRAFT_FILTER_FAILED = 'NEWS_DRAFT_FILTER_FAILED';

export const NEWS_PUBLISH_REQUESTED = 'NEWS_PUBLISH_REQUESTED';

export const NEWS_PUBLISH_SUCCEEDED = 'NEWS_PUBLISH_SUCCEEDED';

export const NEWS_PUBLISH_FAILED = 'NEWS_PUBLISH_FAILED';

export const NEWS_ON_NEWS_TAB_CHANGE = 'NEWS_ON_NEWS_TAB_CHANGE';

export const NEWS_DRAFT_ON_EDIT_ONE_REQUESTED = 'NEWS_DRAFT_ON_EDIT_ONE_REQUESTED';

export const NEWS_DRAFT_ON_EDIT_ONE_SUCCEEDED = 'NEWS_DRAFT_ON_EDIT_ONE_SUCCEEDED';

export const NEWS_DRAFT_ON_EDIT_ONE_FAILED = 'NEWS_DRAFT_ON_EDIT_ONE_FAILED';

export const NEWS_DRAFT_ON_PURGE_REQUESTED = 'NEWS_DRAFT_ON_PURGE_REQUESTED';

export const NEWS_DRAFT_ON_PURGE_SUCCEEDED = 'NEWS_DRAFT_ON_PURGE_SUCCEEDED';

export const NEWS_DRAFT_ON_PURGE_FAILED = 'NEWS_DRAFT_ON_PURGE_FAILED';
