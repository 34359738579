import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'STEP_SCORE', {
  BUTTON_TEXT: 'Add score',
  TITLE: 'Score details',
  DEFAULT_SCORE_LABEL: '--',
  INPUT_NAME_LABEL: 'Score label',
  INPUT_SCORE_LABEL: 'Score',
});
