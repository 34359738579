import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

import { InisghtType } from 'src/helpers/enums/insights';
import {
  IComment,
  INewInsightsComment,
  INewInsightsTip,
  INewInsightsVideo,
  ITip,
  IVideo,
} from 'src/types/insights';

const insightToEditSelector = () =>
  createSelector([(state: IReduxState) => state.insights.insightToEdit], (insightToEdit) => {
    switch (insightToEdit?.type) {
      case InisghtType.COMMENT:
        const dataComment = insightToEdit?.data as IComment;
        return {
          type: insightToEdit.type,
          showType: insightToEdit.showType,
          companies: insightToEdit.companies,
          industries: insightToEdit.industries,
          description: dataComment.description,
          signatureName: dataComment.signatureName,
          signaturePosition: dataComment.signaturePosition,
        } as INewInsightsComment;
      case InisghtType.TIP:
        const data = insightToEdit?.data as ITip;
        return {
          type: insightToEdit.type,
          showType: insightToEdit.showType,
          companies: insightToEdit.companies,
          industries: insightToEdit.industries,
          category: data.category,
          description: data.description,
        } as INewInsightsTip;
      case InisghtType.VIDEO:
        const dataVideo = insightToEdit?.data as IVideo;
        return {
          type: insightToEdit.type,
          showType: insightToEdit.showType,
          companies: insightToEdit.companies,
          industries: insightToEdit.industries,
          link: dataVideo.link,
          title: dataVideo.title,
          description: dataVideo.description,
          videoImage: dataVideo.videoImage,
          subCategory: dataVideo.subCategory,
        } as INewInsightsVideo;
      default:
        return;
    }
  });

export default insightToEditSelector;
