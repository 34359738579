import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as newsActions from 'src/redux/actions/news';
import newsDraftInfoSelector from 'src/redux/selectors/newsDraft/newsDraftInfo';
import newsDraftToEditSelector from 'src/redux/selectors/newsDraft/newsDraftToEdit';

import { useTypedSelector } from 'src/hooks/typeSelector';
import { validationSchema } from 'src/views/News/common/formProvider';
import NewsDraftEdit from 'src/views/NewsDraft/NewsDraftEdit';
import {
  getInitialValuesNewsDraftForm,
  getInputsFormNewsDraft,
} from 'src/views/NewsDraft/common/formProvider';

import LayoutContainer from '../Layout/Layout';

const NewsDraftEditContainer = () => {
  const dispatch = useDispatch();
  const routeParams = useRouteMatch<{ id: string }>();

  const newsInfo = useTypedSelector(newsDraftInfoSelector());
  const newsToEdit = useSelector(newsDraftToEditSelector());

  const onSubmit = (publish: boolean) => {
    dispatch(newsActions.onNewsDraftEditOneRequested(newsToEdit!.id, form.values, publish));
  };

  const form = useFormik({
    initialValues: getInitialValuesNewsDraftForm(newsToEdit),
    validationSchema,
    onSubmit: () => onSubmit(false),
  });

  const onInitializeNewsDraftEditView = useCallback(() => {
    dispatch(newsActions.initializeNewsDraftEditView(routeParams.params.id));
  }, [dispatch, routeParams.params.id]);

  useEffect(() => {
    if (newsToEdit) {
      form.setValues(getInitialValuesNewsDraftForm(newsToEdit));
    }
    // eslint-disable-next-line
  }, [newsToEdit]);

  useEffect(() => {
    onInitializeNewsDraftEditView();
  }, [onInitializeNewsDraftEditView]);

  return (
    <LayoutContainer loading={!newsToEdit}>
      <NewsDraftEdit
        loading={newsInfo.info.loading}
        inputs={getInputsFormNewsDraft(form)}
        onSubmitForm={onSubmit}
      />
    </LayoutContainer>
  );
};

export default NewsDraftEditContainer;
