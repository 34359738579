import { GridColumns, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid';
import i18next from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as newsActions from 'src/redux/actions/news';
import newsDraftInfoSelector from 'src/redux/selectors/newsDraft/newsDraftInfo';
import newsDraftListSelector from 'src/redux/selectors/newsDraft/newsDraftList';
import totalNewsDraftSelector from 'src/redux/selectors/newsDraft/totalNewsDraft';

import TooltipField from 'src/components/TableFields/TooltipField';
import { NewsNavigationTabs } from 'src/helpers/enums/news';
import history from 'src/helpers/history';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { ICompany } from 'src/types/company';
import { INewsDraft } from 'src/types/news';
import IReducerInfo from 'src/types/reducer';
import NewsDraftList from 'src/views/NewsDraft/NewsDraftList';
import 'src/views/NewsDraft/NewsDraftList/i18n';
import { NewsDraftThumbnail } from 'src/views/NewsDraft/NewsDraftList/styles';

import LayoutContainer from '../Layout/Layout';

const NewsDraftListContainer = () => {
  const dispatch = useDispatch();

  const totalNewsDraft = useTypedSelector(totalNewsDraftSelector());
  const news: GridRowModel[] = useTypedSelector(newsDraftListSelector());
  const newsInfo: IReducerInfo = useTypedSelector(newsDraftInfoSelector());

  const columns: GridColumns = [
    {
      field: 'queryName',
      headerName: 'Query',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => (
        <TooltipField
          value={params.value}
          tooltip={`${i18next.t(`NEWS_DRAFT_LIST:TOOLTIP_TITLE`)} ${i18next.t(
            `NEWS_DRAFT_LIST_TOOLTIP_${params.row.showType}:DESCRIPTION`,
            {
              industries: params.row.industries
                .map((industry: string) => industry.replace(/\//g, '\\'))
                .join(', '),
              companies: params.row.companies.map((company: ICompany) => company.name).join(', '),
            },
          )}`}
        />
      ),
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'thumbnail',
      headerName: 'Thumbnail',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      renderCell: (params: GridRenderCellParams<string>) => (
        <NewsDraftThumbnail src={params.value} />
      ),
    },
  ];

  const onEdit = (rowId: string) => {
    history.push({
      pathname: `/news/draft/edit/${rowId}`,
    });
  };

  const onFilter = (filter: Partial<INewsDraft>, page: number) => {
    dispatch(newsActions.onNewsDraftFilterRequested(filter, page));
  };

  const onPublish = (rowId: string) => {
    dispatch(newsActions.onNewsPublishRequested(rowId));
  };

  const onNewsTabChange = (tab: NewsNavigationTabs) => {
    dispatch(newsActions.onNewsTabChange(tab));
  };

  const onPurgeNews = () => {
    dispatch(newsActions.onPurgeNewsDraft());
  };

  const onInitializeNewsDraftListView = useCallback(() => {
    dispatch(newsActions.initializeNewsDraftListView());
  }, [dispatch]);

  useEffect(() => {
    onInitializeNewsDraftListView();
  }, [onInitializeNewsDraftListView]);

  return (
    <LayoutContainer>
      <NewsDraftList
        columns={columns}
        isLoading={newsInfo.info.loading}
        news={news}
        totalNews={totalNewsDraft}
        onTabChange={onNewsTabChange}
        onPublish={onPublish}
        onFilter={onFilter}
        onEdit={onEdit}
        onPurgeNews={onPurgeNews}
      />
    </LayoutContainer>
  );
};

export default NewsDraftListContainer;
