import { COLORS } from '../theme';

export const DefaultTheme = {
  palette: {
    common: {
      black: COLORS.black,
      white: COLORS.white,
    },
    primary: COLORS.shadowGreen,
    secondary: COLORS.rhino,
  },
  fontFamilies: {
    regular: '',
    semiBold: '',
    bold: '',
  },
  fontSizes: {
    small: '12px',
    medium: '14px',
    large: '16px',
  },
};

export type ITheme = typeof DefaultTheme;
