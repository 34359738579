import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'COMPANY', {
  TITLE: 'Company',
  ADD_NEW_COMPANY_BUTTON: 'Add Company',
  ADD_NEW_COMPETITORS_BUTTON: 'Add Competitors',
  HELP_TEXT_COMPETITORS:
    'Add, edit or remove competitors for the company. You can add up to 5 competitors. These will be used as default competitors for all users in this company.',
  HELP_TEXT_COMPANIES_LIST:
    'Add, edit or remove the companies that will see this information. It must be at least one if the showType is "Specific".',
});
