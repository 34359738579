export enum TipsCategory {
  ENVIRONMENT = 'Environment',
  SOCIAL = 'Social',
  GOVERNANCE = 'Governance',
  URGENT = 'Urgent',
  NEWS = 'News',
  CONSUMERS = 'Consumers',
  INVESTORS = 'Investors',
  REGULATIONSFRAMEWORKS = 'Regulations & Frameworks',
  COMPETITORS = 'Competitors',
  MOTIVEUPDATE = 'Motive Update',
}

export enum InisghtType {
  TIP = 'Tip',
  COMMENT = 'Comment',
  VIDEO = 'Video',
}

export enum InsightNavigationTabs {
  TEXT_INSIGHT,
  VIDEO_INSIGHT,
}
