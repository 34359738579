import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as userActions from 'src/redux/actions/user';
import companiesSelector from 'src/redux/selectors/company/companies';
import userInfoSelector from 'src/redux/selectors/user/userInfo';
import userToEditSelector from 'src/redux/selectors/user/userToEdit';

import { Roles } from 'src/helpers/enums/roles';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { INewUser } from 'src/types/user';
import UsersEdit from 'src/views/Users/UsersEdit';
import {
  getInitialValuesUserForm,
  getInputsFormUser,
  validationSchema,
} from 'src/views/Users/common/formProvider';

import LayoutContainer from '../Layout/Layout';

const UsersEditContainer = () => {
  const dispatch = useDispatch();
  const routeParams = useRouteMatch<{ id: string }>();

  const userToEdit = useSelector(userToEditSelector());
  const userInfo = useTypedSelector(userInfoSelector());
  const companies = useTypedSelector(companiesSelector());

  const form = useFormik({
    initialValues: getInitialValuesUserForm(userToEdit),
    validationSchema,
    onSubmit: () => {
      onSubmit(userToEdit!.id, form.values);
    },
  });

  const onSubmit = (id: string, user: INewUser) => {
    const userCompany = companies!.find((element) => element.id === user.company);
    if (user.role !== Roles.SALES && !userCompany?.lastUpdatedScore) {
      return dispatch(userActions.onSetInvalidUser());
    }
    dispatch(userActions.onEditOneRequested(id, user));
  };

  const onInitializeUsersEdit = useCallback(() => {
    dispatch(userActions.initializeUsersEditView(routeParams.params.id));
  }, [dispatch, routeParams.params.id]);

  useEffect(() => {
    onInitializeUsersEdit();
  }, [onInitializeUsersEdit]);

  useEffect(() => {
    form.setValues(getInitialValuesUserForm(userToEdit));
    // eslint-disable-next-line
  }, [userToEdit]);

  return (
    <LayoutContainer loading={!userToEdit}>
      <UsersEdit
        loading={userInfo.info.loading}
        onFormSubmit={form.handleSubmit}
        inputs={getInputsFormUser(form, companies || [])}
      />
    </LayoutContainer>
  );
};

export default UsersEditContainer;
