import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import React from 'react';

import { ICustomActionButtonProps } from './types';

const CustomActionButton = (props: ICustomActionButtonProps) => {
  const { button, rowId, disabled, loading, tooltip, onClick } = props;

  const handleClick = () => {
    onClick(rowId);
  };

  return (
    <Tooltip title={tooltip} placement="top" arrow>
      <IconButton onClick={handleClick} disabled={disabled || loading}>
        {loading ? <CircularProgress size={20} /> : button}
      </IconButton>
    </Tooltip>
  );
};

export default CustomActionButton;
