import { serialize } from 'object-to-formdata';
import { stringify } from 'query-string';

import { ICompany, INewCompany } from 'src/types/company';

import { apiDelete, get, post, put } from './api';

export const getAll = () => {
  return get('companies');
};

export const getBy = (filter: Partial<ICompany>, limit = 10, offset = 0) => {
  return get(`companies/by?${stringify(filter)}&limit=${limit}&offset=${offset}`);
};

export const deleteMany = (companyIds: string[]) => {
  return apiDelete('companies', {}, { companyIds });
};

export const getOne = (companyId: string) => {
  return get(`companies/${companyId}`);
};

export const deleteOne = (companyId: string) => {
  return apiDelete(`companies/${companyId}`);
};

export const editOne = async (companyId: string, newCompany: INewCompany) => {
  return put(
    `companies/${companyId}`,
    serialize({
      ...newCompany,
      competitors: JSON.stringify(newCompany.competitors.map((element) => element.id)),
      scores: JSON.stringify(newCompany.scores),
    }),
    {
      'Content-Type': 'multipart/form-data',
    },
  );
};

export const createOne = (newCompany: INewCompany) => {
  return post(
    `companies`,
    serialize({
      ...newCompany,
      competitors: JSON.stringify(newCompany.competitors.map((element) => element.id)),
      scores: JSON.stringify(newCompany.scores),
    }),
    {
      'Content-Type': 'multipart/form-data',
    },
  );
};
