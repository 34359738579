import React from 'react';

import { Field, FieldWrapper } from './styles';
import { IReferenceFieldProps } from './types';

const ReferenceField = (props: IReferenceFieldProps) => {
  const { redirect, value } = props;

  return (
    <FieldWrapper data-testid="reference-field">
      <Field data-testid="reference-field-link" href={redirect}>
        {value}
      </Field>
    </FieldWrapper>
  );
};

export default ReferenceField;
