import { AnyAction, MiddlewareAPI } from 'redux';

import { APP_URL } from 'src/configs/configs';
import { NewsNavigationTabs, NewsTabsMapper } from 'src/helpers/enums/news';
import history from 'src/helpers/history';
import { getAuthToken } from 'src/helpers/user';

import * as appConstants from '../constants/app';
import * as authConstants from '../constants/auth';
import * as companyConstants from '../constants/company';
import * as insightsConstants from '../constants/insights';
import * as newsConstants from '../constants/news';
import * as newsQueryConstants from '../constants/newsQuery';
import * as userConstants from '../constants/user';

const redirectAfterLogin = () => {
  if (history.location.state?.from) {
    history.replace({
      pathname: history.location.state.from,
      search: history.location.state.params,
    });
  } else {
    history.push({
      pathname: 'users',
    });
  }
};

const redirectToLogin = () => {
  history.replace({
    pathname: '/login',
    state: {
      from: history.location.pathname,
      params: history.location.search,
    },
    search: history.location.search,
  });
};

const redirectMiddleware =
  (store: MiddlewareAPI) => (next: (action: AnyAction) => void) => (action: AnyAction) => {
    switch (action.type) {
      // AUTH
      case authConstants.USER_ON_LOGIN_SUCCEEDED:
        redirectAfterLogin();
        break;
      default:
        break;
      case authConstants.USER_ON_SIGN_OUT:
        window.location.href = APP_URL + 'login';
        break;

      // EDIT
      case userConstants.INTIALIZE_USERS_EDIT_VIEW_FAILED:
      case userConstants.INTIALIZE_USERS_CREATE_VIEW_FAILED:
      case userConstants.USERS_ON_EDIT_ONE_SUCCEEDED:
        history.replace('/users');
        break;
      case companyConstants.INTIALIZE_COMPANIES_EDIT_VIEW_FAILED:
      case companyConstants.COMPANIES_ON_EDIT_ONE_SUCCEEDED:
        history.replace('/companies');
        break;
      case newsConstants.NEWS_ON_EDIT_ONE_SUCCEEDED:
        history.push('/news');
        break;
      case newsConstants.NEWS_DRAFT_ON_EDIT_ONE_SUCCEEDED:
        history.push('/news/draft');
        break;
      case newsQueryConstants.NEWS_QUERYS_ON_EDIT_ONE_SUCCEEDED:
        history.replace('/news-explore');
        break;
      // CREATE
      case userConstants.USERS_ON_CREATE_ONE_SUCCEEDED:
        history.push('/users');
        break;
      case companyConstants.COMPANIES_ON_CREATE_ONE_SUCCEEDED:
        history.replace('/companies');
        break;
      case newsConstants.INTIALIZE_NEWS_CREATE_VIEW_FAILED:
      case newsConstants.NEWS_ON_CREATE_ONE_SUCCEEDED:
        history.replace('/news');
        break;
      case newsQueryConstants.INTIALIZE_NEWS_QUERYS_CREATE_VIEW_FAILED:
      case newsQueryConstants.NEWS_QUERYS_ON_CREATE_ONE_SUCCEEDED:
        history.replace('/news-explore');
        break;
      case insightsConstants.INTIALIZE_INSIGHTS_CREATE_VIEW_FAILED:
      case insightsConstants.INSIGHTS_ON_CREATE_ONE_SUCCEEDED:
      case insightsConstants.INSIGHTS_ON_EDIT_ONE_SUCCEEDED:
        history.replace('/insights');
        break;

      // NEWS
      case newsConstants.NEWS_ON_NEWS_TAB_CHANGE:
        history.push(NewsTabsMapper[action.tab as NewsNavigationTabs]);
        break;
      // ROUTES
      case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_REQUESTED:
        if (!getAuthToken()) {
          redirectToLogin();
        }
        break;
      case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_SUCCEDED:
        if (action.restricted && action.user) {
          history.push({
            pathname: '/users',
          });
        }
        break;

      case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_FAILED:
        if (action.error.statusCode === 401) {
          redirectToLogin();
        }
        break;

      case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_FAILED:
        if (action.error.statusCode === 401) {
          history.go(0);
        }
        break;
    }

    next(action);
  };

export default redirectMiddleware;
