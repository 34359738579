import { AnyAction } from 'redux';
import { takeLatest, all, call, put } from 'redux-saga/effects';

import * as materialityActions from 'src/redux/actions/materiality';
import * as materialityConstants from 'src/redux/constants/materiality';
import * as companyService from 'src/redux/services/company';
import * as materilityService from 'src/redux/services/materiality';

import { MAX_DOCUMENTS_PER_PAGE } from 'src/helpers/constants';
import { IFilterResponse } from 'src/helpers/interfaces/http';
import { ICompany } from 'src/types/company';
import { IMateriality } from 'src/views/Materiality/MaterialityList/types';

function* listMateriality() {
  try {
    const [materialityResponse, companies]: [IFilterResponse, ICompany[]] = yield all([
      call(materilityService.getBy, {}, MAX_DOCUMENTS_PER_PAGE),
      call(companyService.getAll),
    ]);
    yield put(
      materialityActions.initializeMaterialityListViewSucceeded(
        materialityResponse.items,
        materialityResponse.total,
        companies,
      ),
    );
  } catch (error: any) {
    yield put(materialityActions.initializeMaterialityListViewFailed(error.data.message));
  }
}

function* filter(action: AnyAction) {
  try {
    const response: IFilterResponse = yield call(
      materilityService.getBy,
      action.filter,
      MAX_DOCUMENTS_PER_PAGE,
      action.page * MAX_DOCUMENTS_PER_PAGE,
    );
    yield put(materialityActions.onFilterSucceeded(response.items, response.total));
  } catch (error: any) {
    yield put(materialityActions.onFilterFailed(action.data.message));
  }
}

function* editMateriality(action: AnyAction) {
  try {
    const editedMateriality: IMateriality = yield call(
      materilityService.editOne,
      action.materialityId,
      action.newMateriality,
    );
    yield put(materialityActions.onEditOneSucceeded(editedMateriality));
  } catch (error: any) {
    yield put(materialityActions.onEditOneFailed(error.data.message));
  }
}

export default all([
  takeLatest(materialityConstants.INITIALIZE_MATERIALITY_LIST_VIEW_REQUESTED, listMateriality),
  takeLatest(materialityConstants.MATERIALITY_ON_FILTER_REQUESTED, filter),
  takeLatest(materialityConstants.MATERIALITY_ON_EDIT_ONE_REQUESTED, editMateriality),
]);
