import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';

import ConfirmationDialog from 'src/components/ConfirmationDialog';

import { Label } from './styles';
import { IDeleteActionButtonProps } from './types';

const DeleteActionButton = (props: IDeleteActionButtonProps) => {
  const { onClick, disabled = false, label = '' } = props;
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleClick = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleConfirm = () => {
    setIsConfirmationModalOpen(false);
    onClick();
  };

  return (
    <>
      <IconButton
        color="inherit"
        data-testid="action-buttons-delete-button"
        disabled={disabled}
        onClick={handleClick}
      >
        <>
          <Delete color={disabled ? 'disabled' : 'error'} />
          {label !== '' && <Label isDisabled={disabled}>{label}</Label>}
        </>
      </IconButton>
      <ConfirmationDialog
        message={'Are you sure you want to delete this records?'}
        onCancelClick={() => setIsConfirmationModalOpen(false)}
        onConfirmClick={handleConfirm}
        open={isConfirmationModalOpen}
      />
    </>
  );
};

export default DeleteActionButton;
