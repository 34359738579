import { GridColumns, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as newsQuerysActions from 'src/redux/actions/newsQuery';
import newsQueryInfoSelector from 'src/redux/selectors/newsQuerys/newsQueryInfo';
import newsQuerysListSelector from 'src/redux/selectors/newsQuerys/newsQuerysList';

import CheckBoxInput from 'src/components/Inputs/CheckBoxInput';
import history from 'src/helpers/history';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { INewsQuery } from 'src/types/newsQuery';
import NewsQueryList from 'src/views/NewsQuerys/NewsQueryList';

import LayoutContainer from '../Layout/Layout';

const NewsQueryListContainer = () => {
  const dispatch = useDispatch();
  const newsQuerys: GridRowModel[] = useTypedSelector(newsQuerysListSelector());
  const newsQueryInfo = useTypedSelector(newsQueryInfoSelector());
  const columns: GridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      disableColumnMenu: true,
    },

    {
      field: 'searchData',
      headerName: 'Search data',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'provider',
      headerName: 'Provider',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'lastProcessedDate',
      headerName: 'Last processed date',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'active',
      headerName: 'Active',
      flex: 0.25,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<INewsQuery>) => (
        <CheckBoxInput
          id={params.row.id}
          isChecked={params.row.active}
          onChange={onChangeActive}
          value={params.row.active}
        />
      ),
    },
  ];

  const onEdit = (rowId: string) => {
    const querySelected = newsQuerys.find((row) => row.id === rowId);
    history.push({
      pathname: `/news-explore/${querySelected!.provider.toLowerCase()}/edit/${rowId}`,
    });
  };

  const onDeleteOne = (rowId: string) => {
    dispatch(newsQuerysActions.onDeleteOneRequested(rowId));
  };

  const onDeleteMany = (rowsId: string[]) => {
    dispatch(newsQuerysActions.onDeleteManyRequested(rowsId));
  };

  const onAddNew = () => {
    history.push({
      pathname: '/news-explore/bing/new',
    });
  };

  const onRunQuery = (rowId: string) => {
    dispatch(newsQuerysActions.onRunQueryRequested(rowId));
  };

  const onChangeActive = (value: boolean, id: string) => {
    dispatch(newsQuerysActions.onChangeActiveQuery(!value, id));
  };

  const onInitializeNewsQuerysView = useCallback(() => {
    dispatch(newsQuerysActions.initializeNewsQuerysListView());
  }, [dispatch]);

  useEffect(() => {
    onInitializeNewsQuerysView();
  }, [onInitializeNewsQuerysView]);

  return (
    <LayoutContainer>
      <NewsQueryList
        columns={columns}
        error={newsQueryInfo.info.error ? newsQueryInfo.message : null}
        isLoading={newsQueryInfo.info.loading}
        newsQuery={newsQuerys}
        onAddNew={onAddNew}
        onDeleteMany={onDeleteMany}
        onDeleteOne={onDeleteOne}
        onEdit={onEdit}
        onRunQuery={onRunQuery}
      />
    </LayoutContainer>
  );
};

export default NewsQueryListContainer;
