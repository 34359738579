import styled from 'styled-components';

export const CompaniesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
`;

export const CompaniesContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CompanyLogo = styled.img`
  height: 75px;
`;
