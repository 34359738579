import { AnyAction } from 'redux';

import { INewsDraft } from 'src/types/news';
import IReducerInfo from 'src/types/reducer';

import * as newsConstants from '../constants/news';

export interface INewsDraftReducerState {
  newsDraft: INewsDraft[] | null;
  newsDraftToEdit: INewsDraft | null;
  newsDraftInfo: IReducerInfo;
  totalNewsDraft: number;
}

const defaultState: INewsDraftReducerState = {
  newsDraft: null,
  newsDraftToEdit: null,
  totalNewsDraft: 0,
  newsDraftInfo: {
    info: {
      error: false,
      success: false,
      loading: false,
    },
    message: '',
  },
};

const setNewsDraft = (state: INewsDraftReducerState, newsDraft: INewsDraft[] | null) => ({
  ...state,
  newsDraft,
});

const setNewsDraftInfo = (
  state: INewsDraftReducerState,
  error: boolean,
  success: boolean,
  loading: boolean,
  message: string,
) => {
  return {
    ...state,
    newsDraftInfo: {
      info: {
        error,
        success,
        loading,
      },
      message,
    },
  };
};

const setTotalNewsDraft = (state: INewsDraftReducerState, totalNewsDraft: number) => ({
  ...state,
  totalNewsDraft,
});

const removeNewsDraft = (state: INewsDraftReducerState, idToRemove: string) => ({
  ...state,
  newsDraft: state.newsDraft?.filter((notice) => notice.id !== idToRemove) || [],
  totalNewsDraft: state.totalNewsDraft - 1,
});

const setNewDraftToEdit = (state: INewsDraftReducerState, newsDraftToEdit: INewsDraft | null) => ({
  ...state,
  newsDraftToEdit,
});

const newsDraftReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case newsConstants.INITIALIZE_NEWS_DRAFT_LIST_VIEW_REQUESTED:
    case newsConstants.NEWS_DRAFT_ON_PURGE_REQUESTED:
      return setNewsDraftInfo(
        setNewsDraft(setTotalNewsDraft(state, 0), null),
        false,
        false,
        true,
        '',
      );
    case newsConstants.INTIIALIZE_NEWS_DRAFT_LIST_VIEW_SUCCEEDED:
    case newsConstants.NEWS_DRAFT_ON_PURGE_SUCCEEDED:
    case newsConstants.NEWS_DRAFT_FILTER_SUCCEEDED:
      return setNewsDraftInfo(
        setNewsDraft(setTotalNewsDraft(state, action.totalNewsDraft), action.newsDraft),
        false,
        true,
        false,
        '',
      );
    case newsConstants.NEWS_PUBLISH_SUCCEEDED:
      return setNewsDraftInfo(removeNewsDraft(state, action.newsDraftId), false, true, false, '');
    case newsConstants.INTIALIZE_NEWS_DRAFT_EDIT_VIEW_REQUESTED:
    case newsConstants.NEWS_DRAFT_FILTER_REQUESTED:
    case newsConstants.NEWS_PUBLISH_REQUESTED:
    case newsConstants.NEWS_DRAFT_ON_EDIT_ONE_REQUESTED:
      return setNewsDraftInfo(state, false, false, true, '');
    case newsConstants.NEWS_DRAFT_ON_EDIT_ONE_SUCCEEDED:
    case newsConstants.INTIALIZE_NEWS_DRAFT_EDIT_VIEW_SUCCEEDED:
      return setNewsDraftInfo(setNewDraftToEdit(state, action.news), false, true, false, '');
    case newsConstants.INTIIALIZE_NEWS_DRAFT_LIST_VIEW_FAILED:
    case newsConstants.INTIALIZE_NEWS_DRAFT_EDIT_VIEW_FAILED:
    case newsConstants.NEWS_DRAFT_FILTER_FAILED:
    case newsConstants.NEWS_PUBLISH_FAILED:
    case newsConstants.NEWS_DRAFT_ON_EDIT_ONE_FAILED:
    case newsConstants.NEWS_DRAFT_ON_PURGE_FAILED:
      return setNewsDraftInfo(state, true, false, false, action.errorMessage);
    default:
      return state;
  }
};

export default newsDraftReducer;
