import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const newsListSelector = () =>
  createSelector([(state: IReduxState) => state.news.news], (news) => {
    if (!news) return [];
    return news.map((n) => ({
      id: n.id,
      title: n.title,
      description: n.description,
      thumbnail: n.thumbnail,
      showType: n.showType,
      companies: n.companies,
      industries: n.industry,
    }));
  });

export default newsListSelector;
