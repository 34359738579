import { serialize } from 'object-to-formdata';
import { stringify } from 'query-string';

import { INewNotice, INewsDraft } from 'src/types/news';
import { removeExtraData } from 'src/views/News/common/helpers';

import { get, post, put } from './api';

export const getBy = (filter: Partial<INewsDraft>, limit = 10, offset = 0) => {
  return get(`newsDraft/by?${stringify(filter)}&limit=${limit}&offset=${offset}`);
};

export const publish = (newsDraftId: string) => {
  return post(`newsDraft/publish/${newsDraftId}`, {});
};

export const purgeNews = () => {
  return post(`purgeNewsDraft`, {});
};

export const editOne = (newsDraftId: string, notice: INewNotice) => {
  return put(`newsDraft/${newsDraftId}`, serialize(removeExtraData(notice)), {
    'Content-Type': 'multipart/form-data',
  });
};

export const getOne = (newId: string) => {
  return get(`newsDraft/${newId}`);
};
