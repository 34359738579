import styled from 'styled-components';

export const FieldWrapper = styled.div`
  width: 100%;
`;

export const Field = styled.a`
  color: ${(props) => props.theme.palette.secondary};
  font-weight: 600;
  text-decoration: none;
  @media (any-hover: hover) {
    &:hover {
      cursor: pointer;
      filter: brightness(120%);
    }
  }
`;
