import { Tab, Tabs } from '@mui/material';
import i18next from 'i18next';
import React from 'react';

import { NewsNavigationTabs } from 'src/helpers/enums/news';

import './i18n';
import { INewsNavigationProps } from './types';

const NewsNavigation = (props: INewsNavigationProps) => {
  const { currentTab, onChangeTab } = props;

  const handleChange = (event: React.SyntheticEvent, newValue: NewsNavigationTabs) => {
    onChangeTab(newValue);
  };

  return (
    <Tabs value={currentTab} onChange={handleChange}>
      <Tab label={i18next.t('NEWS_NAVIGATION_TABS:PUBLISHED')} />
      <Tab label={i18next.t('NEWS_NAVIGATION_TABS:DRAFT')} />
    </Tabs>
  );
};

export default NewsNavigation;
