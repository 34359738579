import { Tooltip } from '@mui/material';
import React from 'react';

import { Field } from './styles';
import { ITooltipFieldProps } from './types';

const TooltipField = (props: ITooltipFieldProps) => {
  const { tooltip, value } = props;

  return (
    <Tooltip placement="right" title={tooltip}>
      <Field data-testid="tooltip-field">{value}</Field>
    </Tooltip>
  );
};

export default TooltipField;
