import { GridAlignment, GridCellValue, GridColumns } from '@mui/x-data-grid';
import i18next from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as materialityActions from 'src/redux/actions/materiality';
import industriesSelector from 'src/redux/selectors/company/industries';
import materialityInfoSelector from 'src/redux/selectors/materiality/materialityInfo';
import materialityListSelector from 'src/redux/selectors/materiality/materialityList';
import totalMaterialitySelector from 'src/redux/selectors/materiality/totalMateriality';

import { useTypedSelector } from 'src/hooks/typeSelector';
import MaterialityList from 'src/views/Materiality/MaterialityList';
import 'src/views/Materiality/MaterialityList/i18n';
import { IMateriality } from 'src/views/Materiality/MaterialityList/types';

import LayoutContainer from '../Layout/Layout';

const Materialities = [
  'ghg',
  'water',
  'biodiversity',
  'waste',
  'naturalResources',
  'energy',
  'community',
  'healthSafety',
  'employment',
  'supplyChain',
  'humanRights',
  'dataProtection',
  'integratedESG',
  'productQualitySafety',
];

const MaterialityListContainer = () => {
  const dispatch = useDispatch();

  const materialityInfo = useTypedSelector(materialityInfoSelector());
  const materiality = useTypedSelector(materialityListSelector());
  const totalMateriality = useTypedSelector(totalMaterialitySelector());
  const industries = useTypedSelector(industriesSelector());

  const columns: GridColumns = [
    {
      field: 'industry',
      headerName: 'Industry',
      align: 'left',
      flex: 3,
      disableColumnMenu: true,
      sortable: false,
    },
    ...Materialities.map((materiality) => ({
      field: materiality,
      headerName: i18next.t(`MATERIALITY_DESCRIPTIONS:${materiality}`),
      description: i18next.t(`MATERIALITY_DESCRIPTIONS:${materiality}`),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      editable: true,
      type: 'number',
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
    })),
  ];

  const onCellEdit = (rowId: string, columnId: string, newValue: GridCellValue) => {
    dispatch(materialityActions.onEditOneRequested(rowId, { [columnId]: newValue }));
  };

  const onFilter = (filter: Partial<IMateriality>, page: number) => {
    dispatch(materialityActions.onFilterRequested(filter, page));
  };

  const onInitializeMaterialityList = useCallback(() => {
    dispatch(materialityActions.initializeMaterialityListView());
  }, [dispatch]);

  useEffect(() => {
    onInitializeMaterialityList();
  }, [onInitializeMaterialityList]);

  return (
    <LayoutContainer>
      <MaterialityList
        columns={columns}
        error={materialityInfo.info.error ? materialityInfo.message : null}
        isLoading={materialityInfo.info.loading}
        materiality={materiality}
        totalMateriality={totalMateriality}
        industries={industries}
        onCellEdit={onCellEdit}
        onFilter={onFilter}
      />
    </LayoutContainer>
  );
};

export default MaterialityListContainer;
