import { FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { IFormInput } from 'src/components/Form/types';
import SelectInput from 'src/components/Inputs/SelectInput';
import TextInput from 'src/components/Inputs/TextInput';
import { markets } from 'src/containers/NewsQuery/Bing/data';
import { NewsProviders, NewsTypes } from 'src/helpers/enums/news';
import { ICompany } from 'src/types/company';
import { INewBingQuery, INewQuery } from 'src/types/newsQuery';
import CompanyList from 'src/views/News/components/Companies/CompanyList';
import IndustryList from 'src/views/News/components/Industry/IndustryList';

import { deleteCompanies, deleteIndustry } from './helpers';

export const validationSchema = Yup.object({
  name: Yup.string().required('Name is required').trim(),
  query: Yup.string().required('Query is required'),
  mkt: Yup.string().required('Market is required'),
  showType: Yup.string().required('Show types is required'),
});

export const getInitialValuesNewsForm = (newsQueryToEdit?: INewQuery | null) => {
  return {
    name: newsQueryToEdit ? newsQueryToEdit.name : '',
    query: newsQueryToEdit ? newsQueryToEdit.data.q : '',
    mkt: newsQueryToEdit ? newsQueryToEdit.data.mkt : '',
    showType: newsQueryToEdit ? newsQueryToEdit.showType : NewsTypes.ALL,
    industry: newsQueryToEdit ? newsQueryToEdit.industry : [],
    companies: newsQueryToEdit ? newsQueryToEdit.companies : [],
    provider: newsQueryToEdit ? newsQueryToEdit.provider : NewsProviders.BING,
  };
};

export const getInputsFormNewsQuerys = (
  config: FormikProps<INewBingQuery>,
  allCompanies: ICompany[],
  allIndustries: string[],
) => {
  const inputs: IFormInput[] = [
    {
      key: 'name',
      input: (
        <TextInput
          id="name"
          name="name"
          placeholder="Name"
          pathI18nHelp={'HELP_TEXT_NEWS_QUERY_NAME'}
          error={
            config.touched.name && Boolean(config.errors.name) ? (config.errors.name as string) : ''
          }
          value={config.values.name}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'query',
      input: (
        <TextInput
          id="query"
          name="query"
          placeholder="Search query"
          pathI18nHelp={'HELP_TEXT_SEARCH_NEWS_QUERY'}
          error={
            config.touched.query && Boolean(config.errors.query)
              ? (config.errors.query as string)
              : ''
          }
          value={config.values.query}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'mkt',
      input: (
        <SelectInput
          id="mkt"
          name="mkt"
          label="Market"
          error={
            config.touched.mkt && Boolean(config.errors.mkt) ? (config.errors.mkt as string) : ''
          }
          value={config.values.mkt}
          pathI18nHelp={'HELP_TEXT_NEWS_QUERY_MARKETING'}
          onChange={config.handleChange}
          options={markets.map((market) => ({
            text: `${market.language}-${market.region}`,
            value: market.mktCode,
          }))}
        />
      ),
    },
    {
      key: 'showType',
      input: (
        <SelectInput
          id="showType"
          name="showType"
          label="Show Types"
          error={
            config.touched.showType && Boolean(config.errors.showType)
              ? (config.errors.showType as string)
              : ''
          }
          value={config.values.showType}
          pathI18nHelp={'HELP_TEXT_SHOW_TYPES'}
          options={[
            {
              value: NewsTypes.ALL,
              text: NewsTypes.ALL,
            },
            { value: NewsTypes.SPECIFIC, text: NewsTypes.SPECIFIC },
            {
              value: NewsTypes.INDUSTRY,
              text: NewsTypes.INDUSTRY,
            },
          ]}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'industry',
      input: (
        <IndustryList
          industry={config.values.industry}
          allIndustries={allIndustries}
          pathI18nHelp={'HELP_TEXT_INDUSTRY_LIST'}
          disabled={
            config.values.showType === NewsTypes.ALL ||
            config.values.showType === NewsTypes.SPECIFIC
          }
          onAddNew={(industry: string) => {
            config.values.industry.push(industry);
            config.setFieldValue('industry', config.values.industry);
          }}
          onDelete={(industryId: string) => {
            config.setFieldValue('industry', deleteIndustry(config.values.industry, industryId));
          }}
        />
      ),
    },
    {
      key: 'companies',
      input: (
        <CompanyList
          companies={config.values.companies}
          allCompaniesArray={allCompanies}
          pathI18n={'ADD_NEW_COMPANY_BUTTON'}
          pathI18nHelp={'HELP_TEXT_COMPANIES_LIST'}
          disabled={
            config.values.showType === NewsTypes.ALL ||
            config.values.showType === NewsTypes.INDUSTRY
          }
          showTable={config.values.showType === NewsTypes.SPECIFIC}
          onAddNew={(company: ICompany) => {
            const isContain = config.values.companies.find((element) => element.id === company.id);
            if (!isContain) {
              config.values.companies.push(company);
              config.setFieldValue('companies', config.values.companies);
            }
          }}
          onDelete={(companyId: string) => {
            config.setFieldValue('companies', deleteCompanies(config.values.companies, companyId));
          }}
        />
      ),
    },
  ];
  return inputs;
};
