import { AnyAction } from 'redux';
import { takeLatest, all, call, put } from 'redux-saga/effects';

import * as newsQuerysActions from 'src/redux/actions/newsQuery';
import * as newsQuerysConstants from 'src/redux/constants/newsQuery';
import * as companyService from 'src/redux/services/company';
import * as newsQuerysService from 'src/redux/services/newsQuery';

import { ICompany } from 'src/types/company';
import { INewsQuery } from 'src/types/newsQuery';

function* listNewsQuerys() {
  try {
    const newsQuerys: INewsQuery[] = yield call(newsQuerysService.getAll);
    yield put(newsQuerysActions.initializeNewsQuerysListViewSucceeded(newsQuerys));
  } catch (error: any) {
    yield put(newsQuerysActions.initializeNewsQuerysListViewFailed(error.data.message));
  }
}

function* changeActive(action: AnyAction) {
  try {
    const newsQuerys: INewsQuery = yield call(newsQuerysService.editOne, action.newsQuerysId, {
      active: action.value,
    });
    yield put(newsQuerysActions.onChangeActiveQuerySucceeded(newsQuerys));
  } catch (error: any) {
    yield put(newsQuerysActions.onChangeActiveQueryFailed(error.data.message));
  }
}

function* deleteNews(action: AnyAction) {
  try {
    yield call(newsQuerysService.deleteOne, action.newQueryId);
    yield put(newsQuerysActions.onDeleteOneSucceeded(action.newQueryId));
  } catch (error: any) {
    yield put(newsQuerysActions.onDeleteOneFailed(error.data.message));
  }
}

function* deleteManyNews(action: AnyAction) {
  try {
    const { countDeleted } = yield call(newsQuerysService.deleteMany, action.newsQueryIds);
    yield put(newsQuerysActions.onDeleteManySucceeded(action.newsQueryIds, countDeleted));
  } catch (error: any) {
    yield put(newsQuerysActions.onDeleteManyFailed(error.data.message));
  }
}

function* initializeCreateView(action: AnyAction) {
  try {
    const companies: ICompany[] = yield call(companyService.getAll);
    yield put(newsQuerysActions.initializeNewsQueryCreateViewSucceeded(companies));
  } catch (error: any) {
    yield put(newsQuerysActions.initializeNewsQueryCreateViewFailed(error.data.message));
  }
}

function* createNewsQuery(action: AnyAction) {
  try {
    yield call(newsQuerysService.createOne, action.newsQuery);
    const newsQuery: INewsQuery[] = yield call(newsQuerysService.getAll);
    yield put(newsQuerysActions.onCreateOneSucceeded(newsQuery));
  } catch (error: any) {
    yield put(newsQuerysActions.onCreateOneFailed(error.data.message));
  }
}

function* initializeEditView(action: AnyAction) {
  try {
    const [newsQuery, companies]: [INewsQuery, ICompany[]] = yield all([
      call(newsQuerysService.getOne, action.newId),
      call(companyService.getAll),
    ]);
    yield put(newsQuerysActions.initializeNewsQueryEditViewSucceeded(newsQuery, companies));
  } catch (error: any) {
    yield put(newsQuerysActions.initializeNewsQueryEditViewFailed(error.data.message));
  }
}

function* editNewsQuery(action: AnyAction) {
  try {
    const editedNewsQuery: INewsQuery = yield call(
      newsQuerysService.editOne,
      action.newsQueryId,
      action.newsQuery,
    );
    yield put(newsQuerysActions.onEditOneSucceeded(editedNewsQuery));
  } catch (error: any) {
    yield put(newsQuerysActions.onEditOneFailed(error.data.message));
  }
}

function* runQuery(action: AnyAction) {
  try {
    const newsQuery: INewsQuery = yield call(newsQuerysService.runQuery, action.newsQueryId);
    yield put(newsQuerysActions.onRunQuerySucceeded(newsQuery));
  } catch (error: any) {
    yield put(newsQuerysActions.onRunQueryFailed(error.data.message));
  }
}

export default all([
  takeLatest(newsQuerysConstants.INITIALIZE_NEWS_QUERYS_LIST_VIEW_REQUESTED, listNewsQuerys),
  takeLatest(newsQuerysConstants.NEWS_QUERYS_ON_CHANGE_ACTIVE_QUERY, changeActive),
  takeLatest(newsQuerysConstants.NEWS_QUERYS_ON_DELETE_ONE_REQUESTED, deleteNews),
  takeLatest(newsQuerysConstants.NEWS_QUERYS_ON_DELETE_MANY_REQUESTED, deleteManyNews),
  takeLatest(newsQuerysConstants.INTIALIZE_NEWS_QUERYS_CREATE_VIEW_REQUESTED, initializeCreateView),
  takeLatest(newsQuerysConstants.NEWS_QUERYS_ON_CREATE_ONE_REQUESTED, createNewsQuery),
  takeLatest(newsQuerysConstants.INTIALIZE_NEWS_QUERYS_EDIT_VIEW_REQUESTED, initializeEditView),
  takeLatest(newsQuerysConstants.NEWS_QUERYS_ON_EDIT_ONE_REQUESTED, editNewsQuery),
  takeLatest(newsQuerysConstants.NEWS_QUERY_RUN_REQUESTED, runQuery),
]);
