import { get, post } from './api';

export async function loginUser(email: string, password: string) {
  return post('auth/admin/login', { email, password });
}

export async function getUser() {
  return get('users/me');
}

export const reSendEmailToChangePassword = (userId: string) => {
  return post('auth/re-send-email-password', { userId });
};
