import { GridColumns, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as insightsActions from 'src/redux/actions/insights';
import insightInfoSelector from 'src/redux/selectors/insights/insightInfo';
import insightsListSelector from 'src/redux/selectors/insights/insightList';

import history from 'src/helpers/history';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { IInsights } from 'src/types/insights';
import IReducerInfo from 'src/types/reducer';
import InsightsList from 'src/views/Insights/InsightsList';

import LayoutContainer from '../Layout/Layout';

const InsightsListContainer = () => {
  const dispatch = useDispatch();

  const insights: Partial<IInsights>[] = useTypedSelector(insightsListSelector());
  const insightInfo: IReducerInfo = useTypedSelector(insightInfoSelector());
  const textInsightColumns: GridColumns = [
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.data.category,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.data.description,
    },
  ];

  const videoInsightColumns: GridColumns = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.data.title,
    },
    {
      field: 'link',
      headerName: 'Link',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.data.link,
    },
    {
      field: 'subCategory',
      headerName: 'Sub Category',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.data.subCategory,
    },
  ];

  const onEdit = (rowId: string) => {
    history.push({
      pathname: `insights/edit/${rowId}`,
    });
  };

  const onDeleteOne = (rowId: string) => {
    dispatch(insightsActions.onDeleteOneRequested(rowId));
  };

  const onDeleteMany = (rowsId: string[]) => {
    dispatch(insightsActions.onDeleteManyRequested(rowsId));
  };

  const onAddNew = () => {
    history.push({
      pathname: '/insights/new',
    });
  };

  const onInitializeInsightsList = useCallback(() => {
    dispatch(insightsActions.initializeInsightsListView());
  }, [dispatch]);

  useEffect(() => {
    onInitializeInsightsList();
  }, [onInitializeInsightsList]);

  return (
    <LayoutContainer>
      <InsightsList
        textInsightColumns={textInsightColumns}
        videoInsightColumns={videoInsightColumns}
        error={insightInfo.info.error ? insightInfo.message : null}
        isLoading={insightInfo.info.loading}
        insights={insights}
        onAddNew={onAddNew}
        onEdit={onEdit}
        onDeleteOne={onDeleteOne}
        onDeleteMany={onDeleteMany}
      />
    </LayoutContainer>
  );
};

export default InsightsListContainer;
