export enum NewsTypes {
  SPECIFIC = 'Specific',
  ALL = 'All',
  INDUSTRY = 'Industry',
}

export enum NewsProviders {
  BING = 'Bing',
}

export enum NewsNavigationTabs {
  PUBLISHED_NEWS,
  DRAFT_NEWS,
}

export const NewsTabsMapper = {
  [NewsNavigationTabs.PUBLISHED_NEWS]: '/news',
  [NewsNavigationTabs.DRAFT_NEWS]: '/news/draft',
};
