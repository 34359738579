import { AnyAction } from 'redux';
import { takeLatest, all, call, put } from 'redux-saga/effects';

import * as newsActions from 'src/redux/actions/news';
import * as newsConstants from 'src/redux/constants/news';
import * as newsDraftService from 'src/redux/services/newsDraft';

import { MAX_DOCUMENTS_PER_PAGE } from 'src/helpers/constants';
import { IFilterResponse } from 'src/helpers/interfaces/http';
import { INewsDraft } from 'src/types/news';

function* listNewsDraft() {
  try {
    const response: IFilterResponse = yield call(
      newsDraftService.getBy,
      {},
      MAX_DOCUMENTS_PER_PAGE,
    );
    yield put(newsActions.initializeNewsDraftListViewSucceeded(response.items, response.total));
  } catch (error: any) {
    yield put(newsActions.initializeNewsDraftListViewFailed(error.data.message));
  }
}

function* editNewsDraft(action: AnyAction) {
  try {
    const editedNews: INewsDraft = yield call(
      newsDraftService.editOne,
      action.newId,
      action.newNotice,
    );
    if (action.publish) {
      yield call(newsDraftService.publish, action.newId);
    }
    yield put(newsActions.onNewsDraftEditOneSucceeded(editedNews, action.publish));
  } catch (error: any) {
    yield put(newsActions.onNewsDraftEditOneFailed(error.data.message));
  }
}

function* initializeEditView(action: AnyAction) {
  try {
    const news: INewsDraft = yield call(newsDraftService.getOne, action.newId);
    yield put(newsActions.initializeNewsDraftEditViewSucceeded(news));
  } catch (error: any) {
    yield put(newsActions.initializeNewsDraftEditViewFailed(error.data.message));
  }
}

function* filter(action: AnyAction) {
  try {
    const response: IFilterResponse = yield call(
      newsDraftService.getBy,
      action.filter,
      MAX_DOCUMENTS_PER_PAGE,
      action.page * MAX_DOCUMENTS_PER_PAGE,
    );
    yield put(newsActions.onNewsDraftFilterSucceeded(response.items, response.total));
  } catch (error: any) {
    yield put(newsActions.onNewsDraftFilterFailed(action.data.message));
  }
}

function* publish(action: AnyAction) {
  try {
    yield call(newsDraftService.publish, action.newsDraftId);
    yield put(newsActions.onNewsPublishSucceeded(action.newsDraftId));
  } catch (error: any) {
    yield put(newsActions.onNewsPublishFailed(error.data.message));
  }
}

function* purgeNews() {
  try {
    yield call(newsDraftService.purgeNews);
    const response: IFilterResponse = yield call(
      newsDraftService.getBy,
      {},
      MAX_DOCUMENTS_PER_PAGE,
    );
    yield put(newsActions.onPurgeNewsDraftSucceeded(response.items, response.total));
  } catch (error: any) {
    yield put(newsActions.onPurgeNewsDraftFailed(error.data.message));
  }
}

export default all([
  takeLatest(newsConstants.INITIALIZE_NEWS_DRAFT_LIST_VIEW_REQUESTED, listNewsDraft),
  takeLatest(newsConstants.NEWS_DRAFT_ON_EDIT_ONE_REQUESTED, editNewsDraft),
  takeLatest(newsConstants.NEWS_DRAFT_FILTER_REQUESTED, filter),
  takeLatest(newsConstants.NEWS_PUBLISH_REQUESTED, publish),
  takeLatest(newsConstants.INTIALIZE_NEWS_DRAFT_EDIT_VIEW_REQUESTED, initializeEditView),
  takeLatest(newsConstants.NEWS_DRAFT_ON_PURGE_REQUESTED, purgeNews),
]);
