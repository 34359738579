import { InputLabel, MenuItem, Select } from '@mui/material';
import styled from 'styled-components';

export const Input = styled(Select)`
  .MuiInput-root {
    color: ${(props) => props.theme.palette.secondary};
  }
`;

export const InputOption = styled(MenuItem)``;

export const Label = styled(InputLabel)`
  color: ${(props) => props.theme.palette.secondary} !important;
`;

export const WrapperSelectInput = styled.div`
  display: flex;
  flex-direction: row;
`;
