import { all } from 'redux-saga/effects';

import auth from './sagas/auth';
import company from './sagas/company';
import insights from './sagas/insights';
import materiality from './sagas/materiality';
import news from './sagas/news';
import newsDraft from './sagas/newsDraft';
import newsQuerys from './sagas/newsQuerys';
import user from './sagas/user';

export default function* rootSaga() {
  yield all([auth, user, company, news, newsDraft, newsQuerys, insights, materiality]);
}
