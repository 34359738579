import { FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { IFormInput } from 'src/components/Form/types';
import FileInput from 'src/components/Inputs/FileInput';
import SelectInput from 'src/components/Inputs/SelectInput';
import TextInput from 'src/components/Inputs/TextInput';
import { InisghtType } from 'src/helpers/enums/insights';
import { NewsTypes } from 'src/helpers/enums/news';
import { ICompany } from 'src/types/company';
import { INewInsightsVideo } from 'src/types/insights';
import CompanyList from 'src/views/News/components/Companies/CompanyList';
import IndustryList from 'src/views/News/components/Industry/IndustryList';

import { deleteCompanies, deleteIndustry } from './helpers';

export const validationSchemaVideo = Yup.object({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  link: Yup.string().required('Link is required'),
  showType: Yup.string().required('Show types is required'),
});

export const getInitialValuesInsightsVideoForm = (InsightToEdit?: INewInsightsVideo | null) => {
  return {
    title: InsightToEdit ? InsightToEdit.title : '',
    description: InsightToEdit ? InsightToEdit.description : '',
    link: InsightToEdit ? InsightToEdit.link : '',
    showType: InsightToEdit ? InsightToEdit.showType : NewsTypes.ALL,
    industries: InsightToEdit ? InsightToEdit.industries : [],
    companies: InsightToEdit ? InsightToEdit.companies : [],
    videoImage: InsightToEdit ? InsightToEdit.videoImage : ('' as string | File),
    subCategory: InsightToEdit ? InsightToEdit.subCategory : InisghtType.TIP,
  };
};

export const getInputsFormVideoInsights = (
  config: FormikProps<INewInsightsVideo>,
  allCompanies: ICompany[],
  allIndustries: string[],
) => {
  const inputs: IFormInput[] = [
    {
      key: 'title',
      input: (
        <TextInput
          id="title"
          name="title"
          placeholder="Title"
          error={
            config.touched.title && Boolean(config.errors.title)
              ? (config.errors.title as string)
              : ''
          }
          value={config.values.title}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'description',
      input: (
        <TextInput
          id="description"
          name="description"
          placeholder="Description"
          maxLength={250}
          error={
            config.touched.description && Boolean(config.errors.description)
              ? (config.errors.description as string)
              : ''
          }
          value={config.values.description}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'link',
      input: (
        <TextInput
          id="link"
          name="link"
          placeholder="Link"
          error={
            config.touched.link && Boolean(config.errors.link) ? (config.errors.link as string) : ''
          }
          value={config.values.link}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'videoImage',
      input: (
        <FileInput
          id="videoImage"
          name="videoImage"
          placeholder="Preview Image"
          error={
            config.touched.videoImage && Boolean(config.errors.videoImage)
              ? (config.errors.videoImage as string)
              : ''
          }
          value={config.values.videoImage}
          onChange={(newFile: File | string) => {
            config.setFieldValue('videoImage', newFile);
          }}
        />
      ),
    },
    {
      key: 'subCategory',
      input: (
        <SelectInput
          id="subCategory"
          name="subCategory"
          label="Sub Category"
          error={
            config.touched.subCategory && Boolean(config.errors.subCategory)
              ? (config.errors.subCategory as string)
              : ''
          }
          value={config.values.subCategory}
          options={[
            {
              value: InisghtType.TIP,
              text: InisghtType.TIP,
            },
            {
              value: InisghtType.COMMENT,
              text: InisghtType.COMMENT,
            },
          ]}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'showType',
      input: (
        <SelectInput
          id="showType"
          name="showType"
          label="Show Types"
          error={
            config.touched.showType && Boolean(config.errors.showType)
              ? (config.errors.showType as string)
              : ''
          }
          value={config.values.showType}
          pathI18nHelp={'HELP_TEXT_SHOW_TYPES'}
          options={[
            {
              value: NewsTypes.ALL,
              text: NewsTypes.ALL,
            },
            { value: NewsTypes.SPECIFIC, text: NewsTypes.SPECIFIC },
            {
              value: NewsTypes.INDUSTRY,
              text: NewsTypes.INDUSTRY,
            },
          ]}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'industries',
      input: (
        <IndustryList
          industry={config.values.industries}
          allIndustries={allIndustries}
          pathI18nHelp={'HELP_TEXT_INDUSTRY_LIST'}
          disabled={
            config.values.showType === NewsTypes.ALL ||
            config.values.showType === NewsTypes.SPECIFIC
          }
          onAddNew={(industry: string) => {
            config.values.industries.push(industry);
            config.setFieldValue('industries', config.values.industries);
          }}
          onDelete={(industryId: string) => {
            config.setFieldValue(
              'industries',
              deleteIndustry(config.values.industries, industryId),
            );
          }}
        />
      ),
    },
    {
      key: 'companies',
      input: (
        <CompanyList
          companies={config.values.companies}
          allCompaniesArray={allCompanies}
          pathI18n={'ADD_NEW_COMPANY_BUTTON'}
          pathI18nHelp={'HELP_TEXT_COMPANIES_LIST'}
          disabled={
            config.values.showType === NewsTypes.ALL ||
            config.values.showType === NewsTypes.INDUSTRY
          }
          showTable={config.values.showType === NewsTypes.SPECIFIC}
          onAddNew={(company: ICompany) => {
            const isContain = config.values.companies.find((element) => element.id === company.id);
            if (!isContain) {
              config.values.companies.push(company);
              config.setFieldValue('companies', config.values.companies);
            }
          }}
          onDelete={(companyId: string) => {
            config.setFieldValue('companies', deleteCompanies(config.values.companies, companyId));
          }}
        />
      ),
    },
  ];
  return inputs;
};
