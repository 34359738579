import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as companiesActions from 'src/redux/actions/company';
import companiesSelector from 'src/redux/selectors/company/companies';
import companyInfoSelector from 'src/redux/selectors/company/companyInfo';
import industriesSelector from 'src/redux/selectors/company/industries';

import { useTypedSelector } from 'src/hooks/typeSelector';
import { ICompany, INewCompany } from 'src/types/company';
import CompaniesNew from 'src/views/Companies/CompaniesNew';
import {
  getInitialValuesCompanyForm,
  getInputsFormCompany,
  validationSchema,
} from 'src/views/Companies/common/formProvider';

import LayoutContainer from '../Layout/Layout';

const CompaniesNewContainer = () => {
  const dispatch = useDispatch();
  const companies: ICompany[] | null = useTypedSelector(companiesSelector());
  const industries: string[] = useTypedSelector(industriesSelector());
  const companyInfo = useTypedSelector(companyInfoSelector());

  const form = useFormik({
    initialValues: getInitialValuesCompanyForm(),
    validationSchema,
    onSubmit: () => {
      onSubmit(form.values);
    },
  });

  const onSubmit = (newCompany: INewCompany) => {
    dispatch(companiesActions.onCreateOneRequested(newCompany));
  };

  const onInitializeCompaniesNewView = useCallback(() => {
    dispatch(companiesActions.onInitializeCompaniesNewView());
  }, [dispatch]);

  useEffect(() => {
    onInitializeCompaniesNewView();
  }, [onInitializeCompaniesNewView]);

  return (
    <LayoutContainer loading={!companies}>
      <CompaniesNew
        loading={companyInfo.info.loading}
        inputs={getInputsFormCompany(form, companies!, industries)}
        onSubmitForm={form.handleSubmit}
      />
    </LayoutContainer>
  );
};

export default CompaniesNewContainer;
