import { serialize } from 'object-to-formdata';
import { stringify } from 'query-string';

import { INewNotice, INews } from 'src/types/news';
import { removeExtraData } from 'src/views/News/common/helpers';

import { apiDelete, get, post, put } from './api';

export const getAll = () => {
  return get('news');
};

export const deleteMany = (newsIds: string[]) => {
  return apiDelete('news', {}, { newsIds });
};

export const getOne = (newId: string) => {
  return get(`news/${newId}`);
};

export const deleteOne = (companyId: string) => {
  return apiDelete(`news/${companyId}`);
};

export const editOne = (noticeId: string, notice: INewNotice) => {
  return put(`news/${noticeId}`, serialize(removeExtraData(notice)), {
    'Content-Type': 'multipart/form-data',
  });
};

export const createOne = (notice: INewNotice) => {
  const newNotice = removeExtraData(notice);
  return post(
    'news',
    serialize({
      ...newNotice,
      companies: newNotice.companies.map((c) => c.id),
    }),
    {
      'Content-Type': 'multipart/form-data',
    },
  );
};

export const getBy = (filter: Partial<INews>, limit = 10, offset = 0) => {
  return get(`news/by?${stringify(filter)}&limit=${limit}&offset=${offset}`);
};
