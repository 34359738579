import { FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { IFormInput } from 'src/components/Form/types';
import SelectInput from 'src/components/Inputs/SelectInput';
import TextInput from 'src/components/Inputs/TextInput';
import { TipsCategory } from 'src/helpers/enums/insights';
import { NewsTypes } from 'src/helpers/enums/news';
import { ICompany } from 'src/types/company';
import { INewInsightsTip } from 'src/types/insights';
import CompanyList from 'src/views/News/components/Companies/CompanyList';
import IndustryList from 'src/views/News/components/Industry/IndustryList';

import { deleteCompanies, deleteIndustry } from './helpers';

export const validationSchemaTip = Yup.object({
  category: Yup.string().required('Category is required'),
  description: Yup.string().required('Description is required'),
  showType: Yup.string().required('Show types is required'),
});

export const getInitialValuesInsightsTipForm = (InsightToEdit?: INewInsightsTip | null) => {
  return {
    category: InsightToEdit ? InsightToEdit.category : TipsCategory.COMPETITORS,
    description: InsightToEdit ? InsightToEdit.description : '',
    showType: InsightToEdit ? InsightToEdit.showType : NewsTypes.ALL,
    industries: InsightToEdit ? InsightToEdit.industries : [],
    companies: InsightToEdit ? InsightToEdit.companies : [],
  };
};

export const getInputsFormTipInsights = (
  config: FormikProps<INewInsightsTip>,
  allCompanies: ICompany[],
  allIndustries: string[],
) => {
  const inputs: IFormInput[] = [
    {
      key: 'category',
      input: (
        <SelectInput
          id="category"
          name="category"
          label="Tip category"
          error={
            config.touched.category && Boolean(config.errors.category)
              ? (config.errors.category as string)
              : ''
          }
          pathI18nHelp={'HELP_TEXT_TIP_CATEGORY'}
          value={config.values.category}
          options={[
            {
              value: TipsCategory.COMPETITORS,
              text: TipsCategory.COMPETITORS,
            },
            {
              value: TipsCategory.CONSUMERS,
              text: TipsCategory.CONSUMERS,
            },
            {
              value: TipsCategory.ENVIRONMENT,
              text: TipsCategory.ENVIRONMENT,
            },
            {
              value: TipsCategory.GOVERNANCE,
              text: TipsCategory.GOVERNANCE,
            },
            {
              value: TipsCategory.INVESTORS,
              text: TipsCategory.INVESTORS,
            },
            {
              value: TipsCategory.MOTIVEUPDATE,
              text: TipsCategory.MOTIVEUPDATE,
            },
            {
              value: TipsCategory.NEWS,
              text: TipsCategory.NEWS,
            },
            {
              value: TipsCategory.REGULATIONSFRAMEWORKS,
              text: TipsCategory.REGULATIONSFRAMEWORKS,
            },
            {
              value: TipsCategory.SOCIAL,
              text: TipsCategory.SOCIAL,
            },
            {
              value: TipsCategory.URGENT,
              text: TipsCategory.URGENT,
            },
          ]}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'description',
      input: (
        <TextInput
          id="description"
          name="description"
          placeholder="Description"
          multiline={true}
          multilineRows={5}
          error={
            config.touched.description && Boolean(config.errors.description)
              ? (config.errors.description as string)
              : ''
          }
          value={config.values.description}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'showType',
      input: (
        <SelectInput
          id="showType"
          name="showType"
          label="Show Types"
          error={
            config.touched.showType && Boolean(config.errors.showType)
              ? (config.errors.showType as string)
              : ''
          }
          value={config.values.showType}
          pathI18nHelp={'HELP_TEXT_SHOW_TYPES'}
          options={[
            {
              value: NewsTypes.ALL,
              text: NewsTypes.ALL,
            },
            { value: NewsTypes.SPECIFIC, text: NewsTypes.SPECIFIC },
            {
              value: NewsTypes.INDUSTRY,
              text: NewsTypes.INDUSTRY,
            },
          ]}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'industries',
      input: (
        <IndustryList
          industry={config.values.industries}
          allIndustries={allIndustries}
          pathI18nHelp={'HELP_TEXT_INDUSTRY_LIST'}
          disabled={
            config.values.showType === NewsTypes.ALL ||
            config.values.showType === NewsTypes.SPECIFIC
          }
          onAddNew={(industry: string) => {
            config.values.industries.push(industry);
            config.setFieldValue('industries', config.values.industries);
          }}
          onDelete={(industryId: string) => {
            config.setFieldValue(
              'industries',
              deleteIndustry(config.values.industries, industryId),
            );
          }}
        />
      ),
    },
    {
      key: 'companies',
      input: (
        <CompanyList
          companies={config.values.companies}
          allCompaniesArray={allCompanies}
          pathI18n={'ADD_NEW_COMPANY_BUTTON'}
          pathI18nHelp={'HELP_TEXT_COMPANIES_LIST'}
          disabled={
            config.values.showType === NewsTypes.ALL ||
            config.values.showType === NewsTypes.INDUSTRY
          }
          showTable={config.values.showType === NewsTypes.SPECIFIC}
          onAddNew={(company: ICompany) => {
            const isContain = config.values.companies.find((element) => element.id === company.id);
            if (!isContain) {
              config.values.companies.push(company);
              config.setFieldValue('companies', config.values.companies);
            }
          }}
          onDelete={(companyId: string) => {
            config.setFieldValue('companies', deleteCompanies(config.values.companies, companyId));
          }}
        />
      ),
    },
  ];
  return inputs;
};
