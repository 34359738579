import { People } from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';
import BusinessIcon from '@mui/icons-material/Business';
import InsightsIcon from '@mui/icons-material/Insights';
import StorageIcon from '@mui/icons-material/Storage';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as userActions from 'src/redux/actions/auth';
import userDataSelector from 'src/redux/selectors/auth/userData';

import ConfirmationDialog from 'src/components/ConfirmationDialog';
import Header from 'src/components/Layout/Header';
import { SIZES } from 'src/helpers/devices';
import { useTypedSelector } from 'src/hooks/typeSelector';

const HeaderContainer = () => {
  const dispatch = useDispatch();

  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] = useState<boolean>(false);

  const user = useTypedSelector(userDataSelector());

  useEffect(() => {
    const handleResize = (): void => {
      if (isMenuOpened && window.innerWidth > SIZES.mediumTablet) {
        setIsMenuOpened(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const onLogoutClick = () => {
    setIsMenuOpened(false);
    setIsConfirmationDialogOpened(true);
  };

  const handleLogOut = () => {
    dispatch(userActions.signOut());
  };

  const headerItems = [
    {
      icon: BusinessIcon,
      key: 'companies',
      label: i18next.t('HEADER:COMPANIES'),
      to: '/companies',
    },
    {
      icon: People,
      key: 'users',
      label: i18next.t('HEADER:USERS'),
      to: '/users',
    },

    {
      icon: ArticleIcon,
      key: 'news',
      label: i18next.t('HEADER:NEWS'),
      to: '/news',
      subItems: [
        {
          key: 'exploreNews',
          label: i18next.t('HEADER:EXPLORE_NEWS'),
          to: '/news-explore',
        },
        {
          key: 'manageNews',
          label: i18next.t('HEADER:MANAGE_NEWS'),
          to: '/news',
        },
      ],
    },
    {
      icon: InsightsIcon,
      key: 'Insights',
      label: i18next.t('HEADER:INSIGHTS'),
      to: '/insights',
    },
    {
      icon: StorageIcon,
      key: 'Materiality',
      label: i18next.t('HEADER:MATERIALITY'),
      to: '/materiality',
    },
  ];

  return (
    <>
      <Header
        isMenuOpened={isMenuOpened}
        items={headerItems}
        onCloseMenu={() => setIsMenuOpened(false)}
        onLogout={onLogoutClick}
        onOpenMenu={() => setIsMenuOpened(true)}
        user={user}
      />
      <ConfirmationDialog
        message={i18next.t('HEADER:CONFIRMATION_DIALOG_MESSAGE')}
        onCancelClick={() => setIsConfirmationDialogOpened(false)}
        onConfirmClick={handleLogOut}
        open={isConfirmationDialogOpened}
      />
    </>
  );
};

export default HeaderContainer;
