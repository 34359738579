import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as companyActions from 'src/redux/actions/company';
import companiesSelector from 'src/redux/selectors/company/companies';
import companyInfoSelector from 'src/redux/selectors/company/companyInfo';
import companyToEditSelector from 'src/redux/selectors/company/companyToEdit';
import industriesSelector from 'src/redux/selectors/company/industries';

import { useTypedSelector } from 'src/hooks/typeSelector';
import { ICompany, INewCompany } from 'src/types/company';
import CompaniesEdit from 'src/views/Companies/CompaniesEdit';
import {
  getInputsFormCompany,
  getInitialValuesCompanyForm,
  validationSchema,
} from 'src/views/Companies/common/formProvider';

import LayoutContainer from '../Layout/Layout';

const CompaniesEditContainer = () => {
  const dispatch = useDispatch();
  const routeParams = useRouteMatch<{ id: string }>();

  const companyToEdit = useTypedSelector(companyToEditSelector());
  const companyInfo = useTypedSelector(companyInfoSelector());
  const industries: string[] = useTypedSelector(industriesSelector());
  const companiesSelect: ICompany[] | null = useTypedSelector(companiesSelector());

  const form = useFormik({
    initialValues: getInitialValuesCompanyForm(companyToEdit),
    validationSchema,
    onSubmit: () => {
      onSubmit(companyToEdit!.id, form.values);
    },
  });

  const onSubmit = (id: string, company: INewCompany) => {
    dispatch(companyActions.onEditOneRequested(id, company));
  };

  const onInitializeCompaniesEdit = useCallback(() => {
    dispatch(companyActions.initializeCompaniesEditView(routeParams.params.id));
  }, [dispatch, routeParams.params.id]);

  useEffect(() => {
    if (companyToEdit) {
      form.setValues(getInitialValuesCompanyForm(companyToEdit));
    }
    // eslint-disable-next-line
  }, [companyToEdit]);

  useEffect(() => {
    onInitializeCompaniesEdit();
  }, [onInitializeCompaniesEdit]);

  return (
    <LayoutContainer loading={!companyToEdit || !companiesSelect}>
      <CompaniesEdit
        loading={companyInfo.info.loading}
        inputs={getInputsFormCompany(form, companiesSelect!, industries)}
        onSubmitForm={form.handleSubmit}
      />
    </LayoutContainer>
  );
};

export default CompaniesEditContainer;
