import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as userActions from 'src/redux/actions/user';
import companiesSelector from 'src/redux/selectors/company/companies';
import userInfoSelector from 'src/redux/selectors/user/userInfo';

import { Roles } from 'src/helpers/enums/roles';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { ICompany } from 'src/types/company';
import { INewUser } from 'src/types/user';
import UsersNew from 'src/views/Users/UsersNew';
import {
  getInitialValuesUserForm,
  getInputsFormUser,
  validationSchema,
} from 'src/views/Users/common/formProvider';

import LayoutContainer from '../Layout/Layout';

const UsersNewContainer = () => {
  const dispatch = useDispatch();

  const userInfo = useTypedSelector(userInfoSelector());
  const companies: ICompany[] | null = useTypedSelector(companiesSelector());

  const onSubmit = (newUser: INewUser) => {
    const userCompany = companies!.find((element) => element.id === newUser.company);
    if (newUser.role !== Roles.SALES && !userCompany?.lastUpdatedScore) {
      return dispatch(userActions.onSetInvalidUser());
    }
    dispatch(
      userActions.onCreateOneRequested(
        {
          ...newUser,
          email: newUser.email.toLowerCase().replace(/\s+/g, ''),
        },
        userCompany!.competitors,
      ),
    );
  };

  const onInitializeUsersNew = useCallback(() => {
    dispatch(userActions.initializeUsersCreateView());
  }, [dispatch]);

  const form = useFormik({
    initialValues: getInitialValuesUserForm(),
    validationSchema,
    onSubmit: () => {
      onSubmit(form.values);
    },
  });

  useEffect(() => {
    onInitializeUsersNew();
  }, [onInitializeUsersNew]);

  return (
    <LayoutContainer loading={!companies}>
      <UsersNew
        onSubmitForm={form.handleSubmit}
        loading={userInfo.info.loading}
        inputs={getInputsFormUser(form, companies)}
      />
    </LayoutContainer>
  );
};

export default UsersNewContainer;
