import { AnyAction } from 'redux';

import * as materialityConstants from 'src/redux/constants/materiality';

import IReducerInfo from 'src/types/reducer';
import { IMateriality } from 'src/views/Materiality/MaterialityList/types';

export interface IMaterialityReducerState {
  materiality: IMateriality[] | null;
  materialityInfo: IReducerInfo;
  totalMateriality: number;
}

const defaultState: IMaterialityReducerState = {
  materiality: null,
  materialityInfo: {
    info: {
      error: false,
      success: false,
      loading: false,
    },
    message: '',
  },
  totalMateriality: 0,
};

const setMateriality = (state: IMaterialityReducerState, materiality: IMateriality[] | null) => ({
  ...state,
  materiality,
});

const setMaterialityInfo = (
  state: IMaterialityReducerState,
  error: boolean,
  success: boolean,
  loading: boolean,
  message: string,
) => {
  return {
    ...state,
    materialityInfo: {
      info: {
        error,
        success,
        loading,
      },
      message,
    },
  };
};

const setTotalMateriality = (state: IMaterialityReducerState, totalMateriality: number) => ({
  ...state,
  totalMateriality,
});

const materialityReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case materialityConstants.INITIALIZE_MATERIALITY_LIST_VIEW_REQUESTED:
      return setMaterialityInfo(
        setMateriality(setTotalMateriality(state, 0), null),
        false,
        false,
        true,
        '',
      );
    case materialityConstants.MATERIALITY_ON_FILTER_REQUESTED:
      return setMaterialityInfo(state, false, false, true, '');
    case materialityConstants.INITIALIZE_MATERIALITY_LIST_VIEW_SUCCEEDED:
    case materialityConstants.MATERIALITY_ON_FILTER_SUCCEEDED:
      return setMaterialityInfo(
        setMateriality(setTotalMateriality(state, action.totalMateriality), action.materiality),
        false,
        true,
        false,
        '',
      );
    case materialityConstants.INITIALIZE_MATERIALITY_LIST_VIEW_FAILED:
    case materialityConstants.MATERIALITY_ON_FILTER_FAILED:
    case materialityConstants.MATERIALITY_ON_EDIT_ONE_FAILED:
      return setMaterialityInfo(state, true, false, false, action.errorMessage);
    default:
      return state;
  }
};

export default materialityReducer;
