import React, { useCallback, useEffect } from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useDispatch } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';

import * as appActions from 'src/redux/actions/app';

import history from 'src/helpers/history';

import PublicRoute from './components/PublicRoute';
import LoginContainer from './containers/Login';
import { useTypedSelector } from './hooks/typeSelector';
import isUserLoggedSelector from './redux/selectors/auth/isUserLogged';
import CompanyRoutes from './routes/company';
import InsightRoutes from './routes/insight';
import MaterialityRoutes from './routes/materiality';
import NewsRoutes from './routes/news';
import UserRoutes from './routes/user';

const App = () => {
  const dispatch = useDispatch();
  const isUserLogged = useTypedSelector(isUserLoggedSelector());

  const handleRouteChange = useCallback(() => {
    dispatch(appActions.changeRoute(history.location.pathname));
  }, [dispatch]);

  useEffect(() => {
    handleRouteChange();
    const unListen = history.listen(handleRouteChange);
    return unListen;
  }, [handleRouteChange]);

  return (
    <>
      <ReactNotification />
      <Switch>
        <PublicRoute
          path="/login"
          component={LoginContainer}
          restricted={true}
          isUserLogged={true}
        />
        {UserRoutes(isUserLogged)}
        {CompanyRoutes(isUserLogged)}
        {NewsRoutes(isUserLogged)}
        {InsightRoutes(isUserLogged)}
        {MaterialityRoutes(isUserLogged)}
        <Redirect to="/users" />
      </Switch>
    </>
  );
};

export default App;
