import styled from 'styled-components';

export const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;

export const ActionButtonsWrapper = styled.div``;

export const Title = styled.h2`
  font-size: 20px;
  color: ${(props) => props.theme.palette.secondary};
  font-weight: bold;
`;
