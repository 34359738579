import { SelectChangeEvent } from '@mui/material';
import { enum2array } from 'enum2array';
import i18next from 'i18next';
import React from 'react';

import TextButton from 'src/components/Buttons/TextButton';
import SelectInput from 'src/components/Inputs/SelectInput';
import { ISelectOption } from 'src/components/Inputs/SelectInput/types';
import { ScoreProviders } from 'src/types/score';

import './i18n';
import { ButtonWrapper, InputWrapper, Title } from './styles';
import { IStepProviderProps } from './types';

const StepProvider = (props: IStepProviderProps) => {
  const { onSubmit, onProviderChange, provider } = props;

  const scoreProviders: ISelectOption[] = enum2array(ScoreProviders).map((provider) => ({
    value: provider.title,
    text: provider.value,
  }));

  const handleProviderChange = (event: SelectChangeEvent<unknown>) => {
    onProviderChange(event.target.value as ScoreProviders);
  };

  return (
    <>
      <Title>{i18next.t('STEP_PROVIDER:TITLE')}</Title>
      <InputWrapper>
        <SelectInput
          id="provider"
          name="provider"
          label={i18next.t('STEP_PROVIDER:INPUT_LABEL')}
          value={provider || ''}
          options={scoreProviders}
          onChange={handleProviderChange}
        />
      </InputWrapper>
      <ButtonWrapper>
        <TextButton
          text={i18next.t('STEP_PROVIDER:BUTTON_TEXT')}
          isDisabled={!provider}
          isLoading={false}
          onSubmit={onSubmit}
        />
      </ButtonWrapper>
    </>
  );
};

export default StepProvider;
