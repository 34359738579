import { useEffect, useState } from 'react';

export const useFilter = <T>(
  onFilter: (filter: T, page: number) => void,
): [T, number, (newFilter: T) => void, (newPage: number) => void] => {
  const [filter, setFilter] = useState<T>({} as T);
  const [page, setPage] = useState(0);

  const handleFilterChange = (newFilter: T) => {
    if (JSON.stringify(newFilter) === '{}') {
      setFilter(newFilter);
    } else {
      setFilter((currentFilter) => ({ ...currentFilter, ...newFilter }));
    }
    setPage(0);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    onFilter(filter, page);
    // eslint-disable-next-line
  }, [filter, page]);

  return [filter, page, handleFilterChange, handlePageChange];
};
