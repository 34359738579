import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

import { IEditActionButtonProps } from './types';

const EditActionButton = (props: IEditActionButtonProps) => {
  const { onClick } = props;

  return (
    <IconButton color="inherit" data-testid="action-buttons-edit-button" onClick={onClick}>
      <Edit />
    </IconButton>
  );
};

export default EditActionButton;
