import { InputLabel } from '@mui/material';
import styled from 'styled-components';

export const CompanyWrapper = styled.div``;

export const CompaniesList = styled.div``;

export const Label = styled(InputLabel)`
  margin-bottom: 5px;
  color: ${(props) => props.theme.palette.secondary} !important;
`;

export const TableWrapper = styled.div``;
