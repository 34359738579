import React from 'react';

import PrivateRoute from 'src/components/PrivateRoute';
import CompaniesEditContainer from 'src/containers/Companies/CompaniesEdit';
import CompanyListContainer from 'src/containers/Companies/CompaniesList';
import CompaniesNewContainer from 'src/containers/Companies/CompaniesNew';

const CompanyRoutes = (isUserLogged: boolean) => {
  return [
    <PrivateRoute
      key="list"
      path="/companies"
      exact
      component={CompanyListContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="new"
      path="/companies/new"
      component={CompaniesNewContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="edit"
      path="/companies/edit/:id"
      component={CompaniesEditContainer}
      isUserLogged={isUserLogged}
    />,
  ];
};

export default CompanyRoutes;
