export const INITIALIZE_MATERIALITY_LIST_VIEW_REQUESTED =
  'INITIALIZE_MATERIALITY_LIST_VIEW_REQUESTED';

export const INITIALIZE_MATERIALITY_LIST_VIEW_SUCCEEDED =
  'INITIALIZE_MATERIALITY_LIST_VIEW_SUCCEEDED';

export const INITIALIZE_MATERIALITY_LIST_VIEW_FAILED = 'INITIALIZE_MATERIALITY_LIST_VIEW_FAILED';

export const MATERIALITY_ON_EDIT_ONE_REQUESTED = 'MATERIALITY_ON_EDIT_ONE_REQUESTED';

export const MATERIALITY_ON_EDIT_ONE_SUCCEEDEED = 'MATERIALITY_ON_EDIT_ONE_SUCCEEDEED';

export const MATERIALITY_ON_EDIT_ONE_FAILED = 'MATERIALITY_ON_EDIT_ONE_FAILED';

export const MATERIALITY_ON_FILTER_REQUESTED = 'MATERIALITY_ON_FILTER_REQUESTED';

export const MATERIALITY_ON_FILTER_SUCCEEDED = 'MATERIALITY_ON_FILTER_SUCCEEDED';

export const MATERIALITY_ON_FILTER_FAILED = 'MATERIALITY_ON_FILTER_FAILED';
