import { InputLabel, TextField } from '@mui/material';
import styled from 'styled-components';

export const Label = styled(InputLabel)`
  color: ${(props) => props.theme.palette.secondary} !important;
  font-size: 12px;
`;

export const Input = styled(TextField)`
  .MuiInputLabel-root {
    color: ${(props) => props.theme.palette.secondary};
  }
  label.Mui-focused {
    color: ${(props) => props.theme.palette.secondary};
  }
  label.Mui-focused {
    color: ${(props) => props.theme.palette.secondary};
  }
  .MuiFormLabel-filled {
    color: ${(props) => props.theme.palette.secondary};
  }
  .MuiInput-root {
    color: ${(props) => props.theme.palette.secondary};
  }
  .MuiInputBase-input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${(props) => props.theme.palette.secondary};
      background-color: ${(props) => props.theme.palette.secondary};
      background-clip: content-box !important;
    }
  }
`;

export const Flag = styled.img``;
