import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as userActions from 'src/redux/actions/user';
import userInfoSelector from 'src/redux/selectors/user/userInfo';
import usersListSelector from 'src/redux/selectors/user/usersList';

import ReferenceField from 'src/components/TableFields/ReferenceField';
import history from 'src/helpers/history';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { ICompany } from 'src/types/company';
import IReducerInfo from 'src/types/reducer';
import { IUser } from 'src/types/user';
import UsersList from 'src/views/Users/UsersList';

import LayoutContainer from '../Layout/Layout';

const UsersListContainer = () => {
  const dispatch = useDispatch();

  const users: Partial<IUser>[] = useTypedSelector(usersListSelector());
  const userInfo: IReducerInfo = useTypedSelector(userInfoSelector());

  const handleReSendEmail = (userId: string) => {
    dispatch(userActions.onReSendEmailToChangePassword(userId));
  };

  const columns: GridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'company',
      headerName: 'Company',
      flex: 1,
      renderCell: (params: GridRenderCellParams<ICompany>) => (
        <ReferenceField
          redirect={`companies/edit/${params.row.company.id}`}
          value={params.value.name}
        />
      ),
    },
  ];

  const onEdit = (rowId: string) => {
    history.push({
      pathname: `users/edit/${rowId}`,
    });
  };

  const onDeleteOne = (rowId: string) => {
    dispatch(userActions.onDeleteOneRequested(rowId));
  };

  const onDeleteMany = (rowsId: string[]) => {
    dispatch(userActions.onDeleteManyRequested(rowsId));
  };

  const onAddNew = () => {
    history.push({
      pathname: '/users/new',
    });
  };

  const onInitializeUsersList = useCallback(() => {
    dispatch(userActions.initializeUsersListView());
  }, [dispatch]);

  useEffect(() => {
    onInitializeUsersList();
  }, [onInitializeUsersList]);

  return (
    <LayoutContainer>
      <UsersList
        columns={columns}
        error={userInfo.info.error ? userInfo.message : null}
        isLoading={userInfo.info.loading}
        users={users}
        onAddNew={onAddNew}
        onEdit={onEdit}
        onDeleteOne={onDeleteOne}
        onDeleteMany={onDeleteMany}
        onResendPasswordEmail={handleReSendEmail}
      />
    </LayoutContainer>
  );
};

export default UsersListContainer;
