import { INewUser } from 'src/types/user';

import { apiDelete, get, post, put } from './api';

export const getAll = () => {
  return get('users');
};

export const deleteMany = (userIds: string[]) => {
  return apiDelete('users', {}, { userIds });
};

export const getOne = (userId: string) => {
  return get(`users/${userId}`);
};

export const deleteOne = (userId: string) => {
  return apiDelete(`users/${userId}`);
};

export const editOne = (userId: string, newUser: INewUser) => {
  return put(`users/${userId}`, { user: { ...newUser, password: '' } });
};

export const createOne = (newUser: INewUser, competitors: string[]) => {
  return post('users', { user: newUser, competitors });
};
