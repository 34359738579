import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as newsQueryActions from 'src/redux/actions/newsQuery';
import companiesSelector from 'src/redux/selectors/company/companies';
import industriesSelector from 'src/redux/selectors/company/industries';
import newsQueryInfoSelector from 'src/redux/selectors/newsQuerys/newsQueryInfo';
import newsQueryToEditSelector from 'src/redux/selectors/newsQuerys/newsQueryToEdit';

import { NewsTypes } from 'src/helpers/enums/news';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { ICompany } from 'src/types/company';
import { INewBingQuery } from 'src/types/newsQuery';
import NewsQuerysEdit from 'src/views/NewsQuerys/NewsQueryEdit';
import {
  getInitialValuesNewsForm,
  getInputsFormNewsQuerys,
  validationSchema,
} from 'src/views/NewsQuerys/common/bing.formProvider';

import LayoutContainer from '../../Layout/Layout';

const BingQueryEditContainer = () => {
  const dispatch = useDispatch();
  const routeParams = useRouteMatch<{ id: string }>();

  const newsQueryInfo = useTypedSelector(newsQueryInfoSelector());
  const newsQueryToEdit = useSelector(newsQueryToEditSelector());
  const companies: ICompany[] | null = useTypedSelector(companiesSelector());
  const industries: string[] = useTypedSelector(industriesSelector());

  const form = useFormik({
    initialValues: getInitialValuesNewsForm(newsQueryToEdit),
    validationSchema,
    onSubmit: () => {
      onSubmit(newsQueryToEdit!.id, form.values);
    },
  });

  const onSubmit = (id: string, newsQuery: INewBingQuery) => {
    let showTypeError = '';
    switch (form.values.showType) {
      case NewsTypes.INDUSTRY:
        showTypeError =
          form.values.industry.length === 0 ? 'One industry is at least required' : '';
        break;
      case NewsTypes.SPECIFIC:
        showTypeError =
          form.values.companies.length === 0 ? 'One company is at least required' : '';
        break;
    }
    if (showTypeError !== '') {
      form.setFieldError('showType', showTypeError);
    } else {
      dispatch(
        newsQueryActions.onEditOneRequested(id, {
          ...newsQuery,
          data: { q: newsQuery.query, mkt: newsQuery.mkt },
        }),
      );
    }
  };

  const onInitializeNewsQueryEditView = useCallback(() => {
    dispatch(newsQueryActions.initializeNewsQueryEditView(routeParams.params.id));
  }, [dispatch, routeParams.params.id]);

  useEffect(() => {
    if (newsQueryToEdit) {
      form.setValues(getInitialValuesNewsForm(newsQueryToEdit));
    }
    // eslint-disable-next-line
  }, [newsQueryToEdit]);

  useEffect(() => {
    onInitializeNewsQueryEditView();
  }, [onInitializeNewsQueryEditView]);

  return (
    <LayoutContainer loading={!companies || !newsQueryToEdit}>
      <NewsQuerysEdit
        loading={newsQueryInfo.info.loading}
        inputs={getInputsFormNewsQuerys(form, companies!, industries)}
        onSubmitForm={form.handleSubmit}
      />
    </LayoutContainer>
  );
};

export default BingQueryEditContainer;
