import { createBrowserHistory, createMemoryHistory, MemoryHistory } from 'history';

interface IHistoryState {
  from: string;
  params: string;
}

const history = createBrowserHistory<IHistoryState>();

export const memoryHistory = (initialEntries?: string[]): MemoryHistory =>
  createMemoryHistory({ initialEntries });

export default history;
