import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as newsQueryActions from 'src/redux/actions/newsQuery';
import companiesSelector from 'src/redux/selectors/company/companies';
import industriesSelector from 'src/redux/selectors/company/industries';
import newsQueryInfoSelector from 'src/redux/selectors/newsQuerys/newsQueryInfo';

import { NewsTypes } from 'src/helpers/enums/news';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { ICompany } from 'src/types/company';
import { INewBingQuery } from 'src/types/newsQuery';
import NewNewsQuery from 'src/views/NewsQuerys/NewNewsQuery';
import {
  getInitialValuesNewsForm,
  getInputsFormNewsQuerys,
  validationSchema,
} from 'src/views/NewsQuerys/common/bing.formProvider';

import LayoutContainer from '../../Layout/Layout';

const NewBingQueryContainer = () => {
  const dispatch = useDispatch();

  const newsQueryInfo = useTypedSelector(newsQueryInfoSelector());
  const companies: ICompany[] | null = useTypedSelector(companiesSelector());
  const industries: string[] = useTypedSelector(industriesSelector());

  const form = useFormik({
    initialValues: getInitialValuesNewsForm(),
    validationSchema,
    onSubmit: () => {
      onSubmit(form.values);
    },
  });

  const onSubmit = (newsQuery: INewBingQuery) => {
    let showTypeError = '';
    switch (form.values.showType) {
      case NewsTypes.INDUSTRY:
        showTypeError =
          form.values.industry.length === 0 ? 'One industry is at least required' : '';
        break;
      case NewsTypes.SPECIFIC:
        showTypeError =
          form.values.companies.length === 0 ? 'One company is at least required' : '';
        break;
    }
    if (showTypeError !== '') {
      form.setFieldError('showType', showTypeError);
    } else {
      dispatch(
        newsQueryActions.onCreateOneRequested({
          ...newsQuery,
          data: { q: newsQuery.query, mkt: newsQuery.mkt },
        }),
      );
    }
  };

  const onInitializeNewsQueryView = useCallback(() => {
    dispatch(newsQueryActions.initializeNewsQueryCreateView());
  }, [dispatch]);

  useEffect(() => {
    onInitializeNewsQueryView();
  }, [onInitializeNewsQueryView]);

  return (
    <LayoutContainer loading={!companies}>
      <NewNewsQuery
        inputs={getInputsFormNewsQuerys(form, companies!, industries)}
        loading={newsQueryInfo.info.loading}
        onSubmitForm={form.handleSubmit}
      />
    </LayoutContainer>
  );
};

export default NewBingQueryContainer;
