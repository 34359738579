import {
  IComment,
  ICommentValues,
  ITip,
  ITipValues,
  IVideo,
  IVideoValues,
} from 'src/types/insights';

import { InisghtType } from './enums/insights';
import { NewsTypes } from './enums/news';

export const getInsightType = (
  type: InisghtType,
  valuesTip: ITipValues,
  valuesComment: ICommentValues,
  valuesVideo: IVideoValues,
) => {
  switch (type) {
    case InisghtType.COMMENT:
      return {
        type: InisghtType.COMMENT,
        data: {
          signatureName: valuesComment.signatureName,
          signaturePosition: valuesComment.signaturePosition,
          description: valuesComment.description,
        } as IComment,
        showType: valuesComment.showType,
        companies:
          valuesComment.showType === NewsTypes.ALL
            ? []
            : valuesComment.showType === NewsTypes.SPECIFIC
            ? valuesComment.companies
            : [],
        industries:
          valuesComment.showType === NewsTypes.ALL
            ? []
            : valuesComment.showType === NewsTypes.INDUSTRY
            ? valuesComment.industries
            : [],
      };
    case InisghtType.VIDEO:
      return {
        type: InisghtType.VIDEO,
        data: {
          title: valuesVideo.title,
          description: valuesVideo.description,
          link: valuesVideo.link,
          videoImage: valuesVideo.videoImage,
          subCategory: valuesVideo.subCategory,
        } as IVideo,
        showType: valuesVideo.showType,
        companies:
          valuesVideo.showType === NewsTypes.ALL
            ? []
            : valuesVideo.showType === NewsTypes.SPECIFIC
            ? valuesVideo.companies
            : [],
        industries:
          valuesVideo.showType === NewsTypes.ALL
            ? []
            : valuesVideo.showType === NewsTypes.INDUSTRY
            ? valuesVideo.industries
            : [],
      };
    default:
      return {
        type: InisghtType.TIP,
        data: {
          category: valuesTip.category,
          description: valuesTip.description,
        } as ITip,
        showType: valuesTip.showType,
        companies:
          valuesTip.showType === NewsTypes.ALL
            ? []
            : valuesTip.showType === NewsTypes.SPECIFIC
            ? valuesTip.companies
            : [],
        industries:
          valuesTip.showType === NewsTypes.ALL
            ? []
            : valuesTip.showType === NewsTypes.INDUSTRY
            ? valuesTip.industries
            : [],
      };
  }
};
