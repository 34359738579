import i18next from 'i18next';
import React, { ChangeEvent, useEffect, useState } from 'react';

import Logo from 'src/assets/logos/logo.png';
import TextButton from 'src/components/Buttons/TextButton';
import PasswordInput from 'src/components/Inputs/PasswordInput';
import TextInput from 'src/components/Inputs/TextInput';

import './i18n.ts';
import {
  ButtonWrapper,
  InputsWrapper,
  InputWrapper,
  LogoWrapper,
  MotiveLogo,
  PersonIcon,
  Wrapper,
} from './styles';
import { ILoginViewProps } from './types';

const Login = (props: ILoginViewProps) => {
  const { loading, onSubmit } = props;
  const [loginDetails, setLoginDetails] = useState({ email: '', password: '' });

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoginDetails({ ...loginDetails, email: event.target.value });
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoginDetails({ ...loginDetails, password: event.target.value });
  };

  const handleSubmit = () => {
    const lowerCaseLoginDetails = {
      ...loginDetails,
      email: loginDetails.email.toLowerCase().replace(/\s+/g, ''),
    };
    onSubmit(lowerCaseLoginDetails);
  };

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.keyCode === 13 && loginDetails.email !== '' && loginDetails.password !== '') {
        handleSubmit();
      }
    };
    window.addEventListener('keyup', handleKeyUp, false);
    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  });

  return (
    <Wrapper data-testid="login-view">
      <InputsWrapper>
        <LogoWrapper>
          <MotiveLogo data-testid="logo" src={Logo} alt="Motive Logo" />
        </LogoWrapper>
        <InputWrapper>
          <TextInput
            isDisabled={false}
            error=""
            id={i18next.t('LOGIN_INPUT:EMAIL').toLowerCase()}
            name={i18next.t('LOGIN_INPUT:EMAIL')}
            placeholder={i18next.t('LOGIN_INPUT:EMAIL')}
            value={loginDetails.email}
            onChange={handleEmailChange}
            adornment={<PersonIcon />}
          />
        </InputWrapper>
        <InputWrapper>
          <PasswordInput
            isDisabled={false}
            error=""
            id={i18next.t('LOGIN_INPUT:PASSWORD').toLowerCase()}
            name={i18next.t('LOGIN_INPUT:PASSWORD')}
            placeholder={i18next.t('LOGIN_INPUT:PASSWORD')}
            value={loginDetails.password}
            onChange={handlePasswordChange}
          />
        </InputWrapper>
        <ButtonWrapper data-testid="button-block">
          <TextButton
            isDisabled={loginDetails.email === '' || loginDetails.password === ''}
            isLoading={loading}
            text={i18next.t('LOGIN_INPUT:LOGIN')}
            onSubmit={handleSubmit}
          />
        </ButtonWrapper>
      </InputsWrapper>
    </Wrapper>
  );
};

export default Login;
