import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const totalNewsDraftSelector = () =>
  createSelector(
    [(state: IReduxState) => state.newsDraft.totalNewsDraft],
    (totalNewsDraft) => totalNewsDraft,
  );

export default totalNewsDraftSelector;
