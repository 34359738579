import { AnyAction } from 'redux';

import { IInsights } from 'src/types/insights';
import IReducerInfo from 'src/types/reducer';

import * as insightConstants from '../constants/insights';

export interface IInsightsReducerState {
  insights: IInsights[] | null;
  insightToEdit: IInsights | null;
  insightInfo: IReducerInfo;
}

const defaultState: IInsightsReducerState = {
  insights: null,
  insightToEdit: null,
  insightInfo: {
    info: {
      error: false,
      success: false,
      loading: false,
    },
    message: '',
  },
};

const setInsights = (state: IInsightsReducerState, insights: IInsights[] | null) => {
  return {
    ...state,
    insights,
  };
};

const setInsightToEdit = (state: IInsightsReducerState, insightToEdit: IInsights | null) => {
  return {
    ...state,
    insightToEdit,
  };
};

const removeInsight = (state: IInsightsReducerState, idsToRemove: string[]) => {
  const newInsights = state.insights?.filter((u) => !idsToRemove.includes(u.id)) || null;
  return {
    ...state,
    insights: newInsights,
  };
};

const setInsightInfo = (
  state: IInsightsReducerState,
  error: boolean,
  success: boolean,
  loading: boolean,
  message: string,
) => {
  return {
    ...state,
    userInfo: {
      info: {
        error,
        success,
        loading,
      },
      message,
    },
  };
};

const setInsight = (state: IInsightsReducerState, insights: IInsights[] | null) => ({
  ...state,
  insights,
});

const insightsReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case insightConstants.INITIALIZE_INSIGHTS_LIST_VIEW_REQUESTED:
    case insightConstants.INSIGHTS_ON_DELETE_ONE_REQUESTED:
    case insightConstants.INSIGHTS_ON_DELETE_MANY_REQUESTED:
    case insightConstants.INSIGHTS_ON_EDIT_ONE_REQUESTED:
    case insightConstants.INTIALIZE_INSIGHTS_EDIT_VIEW_REQUESTED:
      return setInsightInfo(state, false, false, true, '');
    case insightConstants.INITIALIZE_INSIGHTS_LIST_VIEW_SUCCEEDED:
      return setInsightInfo(setInsights(state, action.insights), false, true, false, '');
    case insightConstants.INSIGHTS_ON_DELETE_ONE_SUCCEEDED:
      return setInsightInfo(removeInsight(state, [action.insightId]), false, true, false, '');
    case insightConstants.INSIGHTS_ON_DELETE_MANY_SUCCEEDED:
      return setInsightInfo(removeInsight(state, action.insightIds), false, true, false, '');
    case insightConstants.INSIGHTS_ON_CREATE_ONE_SUCCEEDED:
      return setInsightInfo(setInsight(state, action.insights), false, true, false, '');
    case insightConstants.INTIALIZE_INSIGHTS_EDIT_VIEW_SUCCEEDED:
      return setInsightInfo(setInsightToEdit(state, action.insights), false, true, false, '');
    case insightConstants.INSIGHTS_ON_EDIT_ONE_SUCCEEDED:
      return setInsightInfo(setInsightToEdit(state, action.insights), false, true, false, '');
    case insightConstants.INITIALIZE_INSIGHTS_LIST_VIEW_FAILED:
    case insightConstants.INSIGHTS_ON_DELETE_ONE_FAILED:
    case insightConstants.INSIGHTS_ON_DELETE_MANY_FAILED:
    case insightConstants.INSIGHTS_ON_CREATE_ONE_FAILED:
    case insightConstants.INSIGHTS_ON_EDIT_ONE_FAILED:
      return setInsightInfo(state, true, false, false, action.errorMessage);
    default:
      return state;
  }
};

export default insightsReducer;
