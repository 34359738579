import { ICompany } from 'src/types/company';

export const MAX_DOCUMENTS_PER_PAGE = 10;

export const DEFAULT_COMPANY: ICompany = {
  id: '',
  name: '',
  industry: '',
  country: '',
  logo: '',
  scores: [],
  competitors: [],
  lastUpdatedScore: [],
};
