import { AnyAction } from 'redux';
import { takeLatest, all, call, put } from 'redux-saga/effects';

import * as insightActions from 'src/redux/actions/insights';
import * as insightConstants from 'src/redux/constants/insights';
import * as companyService from 'src/redux/services/company';
import * as insightsService from 'src/redux/services/insights';

import { ICompany } from 'src/types/company';
import { IInsights } from 'src/types/insights';

function* listInsights() {
  try {
    const insights: IInsights[] = yield call(insightsService.getAll);
    yield put(insightActions.initializeInsightsListViewSucceeded(insights));
  } catch (error: any) {
    yield put(insightActions.initializeInsightsListViewFailed(error.data.message));
  }
}

function* deleteInsight(action: AnyAction) {
  try {
    yield call(insightsService.deleteOne, action.insightId);
    yield put(insightActions.onDeleteOneSucceeded(action.insightId));
  } catch (error: any) {
    yield put(insightActions.onDeleteOneFailed(error.data.message));
  }
}

function* deleteManyInsights(action: AnyAction) {
  try {
    const { countDeleted } = yield call(insightsService.deleteMany, action.insightIds);
    yield put(insightActions.onDeleteManySucceeded(action.insightIds, countDeleted));
  } catch (error: any) {
    yield put(insightActions.onDeleteManyFailed(error.data.message));
  }
}

function* initializeCreateView(action: AnyAction) {
  try {
    const companies: ICompany[] = yield call(companyService.getAll);
    yield put(insightActions.initializeInsightsCreateViewSucceeded(companies));
  } catch (error: any) {
    yield put(insightActions.initializeInsightsCreateViewFailed(error.data.message));
  }
}

function* createInsights(action: AnyAction) {
  try {
    yield call(insightsService.createOne, action.insights);
    const insights: IInsights[] = yield call(insightsService.getAll);
    yield put(insightActions.onCreateOneSucceeded(insights));
  } catch (error: any) {
    yield put(insightActions.onCreateOneFailed(error.data.message));
  }
}

function* initializeEditView(action: AnyAction) {
  try {
    const [insight, companies]: [IInsights, ICompany[]] = yield all([
      call(insightsService.getOne, action.insightsId),
      call(companyService.getAll),
    ]);
    yield put(insightActions.initializeInsightsEditViewSucceeded(insight, companies));
  } catch (error: any) {
    yield put(insightActions.initializeInsightsEditViewFailed(error.data.message));
  }
}

function* editInsight(action: AnyAction) {
  try {
    const parseCompaniesId = action.newInsights.companies.map((element: ICompany) => {
      return element.id;
    });
    const editedInsights: IInsights = yield call(insightsService.editOne, action.insightId, {
      ...action.newInsights,
      companies: parseCompaniesId,
    });
    yield put(
      insightActions.onEditOneSucceeded({
        ...editedInsights,
        companies: action.newInsights.companies,
      }),
    );
  } catch (error: any) {
    yield put(insightActions.onEditOneFailed(error.data.message));
  }
}

export default all([
  takeLatest(insightConstants.INITIALIZE_INSIGHTS_LIST_VIEW_REQUESTED, listInsights),
  takeLatest(insightConstants.INSIGHTS_ON_DELETE_ONE_REQUESTED, deleteInsight),
  takeLatest(insightConstants.INSIGHTS_ON_DELETE_MANY_REQUESTED, deleteManyInsights),
  takeLatest(insightConstants.INTIALIZE_INSIGHTS_CREATE_VIEW_REQUESTED, initializeCreateView),
  takeLatest(insightConstants.INSIGHTS_ON_CREATE_ONE_REQUESTED, createInsights),
  takeLatest(insightConstants.INTIALIZE_INSIGHTS_EDIT_VIEW_REQUESTED, initializeEditView),
  takeLatest(insightConstants.INSIGHTS_ON_EDIT_ONE_REQUESTED, editInsight),
]);
