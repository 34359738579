import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as authActions from 'src/redux/actions/auth';
import authInfoSelector from 'src/redux/selectors/auth/authInfo';

import { useTypedSelector } from 'src/hooks/typeSelector';
import IReducerInfo from 'src/types/reducer';
import Login from 'src/views/Login';
import { LoginDetails } from 'src/views/Login/types';

const LoginContainer = () => {
  const dispatch = useDispatch();

  const authInfo: IReducerInfo = useTypedSelector(authInfoSelector());

  const onInitializeLoginView = useCallback(() => {
    dispatch(authActions.initializeLogin());
  }, [dispatch]);

  useEffect(() => {
    onInitializeLoginView();
  }, [onInitializeLoginView]);

  const onSubmit = (details: LoginDetails) => {
    dispatch(authActions.loginRequested(details.email, details.password));
  };

  return <Login loading={authInfo.info.loading} onSubmit={onSubmit} />;
};

export default LoginContainer;
