import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const insightsListSelector = () =>
  createSelector([(state: IReduxState) => state.insights.insights], (insights) => {
    if (!insights) return [];
    return insights.map((u) => ({
      id: u.id,
      type: u.type,
      data: u.data,
    }));
  });

export default insightsListSelector;
