import { TextField } from '@mui/material';
import styled from 'styled-components';

export const Input = styled(TextField)`
  .MuiInputLabel-root {
    color: ${(props) => props.theme.palette.secondary};
  }
  label.Mui-focused {
    color: ${(props) => props.theme.palette.secondary};
  }
  label.Mui-focused {
    color: ${(props) => props.theme.palette.secondary};
  }
  .MuiFormLabel-filled {
    color: ${(props) => props.theme.palette.secondary};
  }
  .MuiInput-root {
    color: ${(props) => props.theme.palette.secondary};
  }
  .MuiInputBase-input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${(props) => props.theme.palette.secondary};
      background-color: ${(props) => props.theme.palette.secondary};
      background-clip: content-box !important;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.palette.common.white} inset !important;
  }
`;

export const WrapperTextInput = styled.div`
  display: flex;
  flex-direction: row;
`;
