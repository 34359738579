import { GridColumns, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid';
import i18next from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as newsActions from 'src/redux/actions/news';
import newsInfoSelector from 'src/redux/selectors/news/newsInfo';
import newsListSelector from 'src/redux/selectors/news/newsList';
import totalNewsSelector from 'src/redux/selectors/news/totalNews';

import TooltipField from 'src/components/TableFields/TooltipField';
import { NewsNavigationTabs } from 'src/helpers/enums/news';
import history from 'src/helpers/history';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { ICompany } from 'src/types/company';
import { INews } from 'src/types/news';
import IReducerInfo from 'src/types/reducer';
import NewsList from 'src/views/News/NewsList';
import 'src/views/News/NewsList/i18n';
import { NewsDraftThumbnail } from 'src/views/NewsDraft/NewsDraftList/styles';

import LayoutContainer from '../Layout/Layout';

const NewsListContainer = () => {
  const dispatch = useDispatch();

  const totalNews = useTypedSelector(totalNewsSelector());
  const news: GridRowModel[] = useTypedSelector(newsListSelector());
  const newsInfo: IReducerInfo = useTypedSelector(newsInfoSelector());
  const columns: GridColumns = [
    {
      field: 'showType',
      headerName: 'Show type',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => (
        <TooltipField
          value={params.value}
          tooltip={`${i18next.t(`NEWS_LIST:TOOLTIP_TITLE`)} ${i18next.t(
            `NEWS_LIST_TOOLTIP_${params.row.showType}:DESCRIPTION`,
            {
              industries: params.row.industries
                .map((industry: string) => industry.replace(/\//g, '\\'))
                .join(', '),
              companies: (params.row.companies || [])
                .map((company: ICompany) => company.name)
                .join(', '),
            },
          )}`}
        />
      ),
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'thumbnail',
      headerName: 'Thumbnail',
      flex: 1,
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => (
        <NewsDraftThumbnail src={params.value} />
      ),
    },
  ];

  const onEdit = (rowId: string) => {
    history.push({
      pathname: `/news/edit/${rowId}`,
    });
  };

  const onDeleteOne = (rowId: string) => {
    dispatch(newsActions.onDeleteOneRequested(rowId));
  };

  const onDeleteMany = (rowsId: string[]) => {
    dispatch(newsActions.onDeleteManyRequested(rowsId));
  };

  const onFilter = (filter: Partial<INews>, page: number) => {
    dispatch(newsActions.onNewsFilterRequested(filter, page));
  };

  const onNewsTabChange = (tab: NewsNavigationTabs) => {
    dispatch(newsActions.onNewsTabChange(tab));
  };

  const onAddNew = () => {
    history.push({
      pathname: '/news/new',
    });
  };

  const onInitializeNewsView = useCallback(() => {
    dispatch(newsActions.initializeNewsListView());
  }, [dispatch]);

  useEffect(() => {
    onInitializeNewsView();
  }, [onInitializeNewsView]);

  return (
    <LayoutContainer>
      <NewsList
        columns={columns}
        error={newsInfo.info.error ? newsInfo.message : null}
        isLoading={newsInfo.info.loading}
        news={news}
        totalNews={totalNews}
        onFilter={onFilter}
        onTabChange={onNewsTabChange}
        onAddNew={onAddNew}
        onEdit={onEdit}
        onDeleteOne={onDeleteOne}
        onDeleteMany={onDeleteMany}
      />
    </LayoutContainer>
  );
};

export default NewsListContainer;
