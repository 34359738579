import { InputAdornment } from '@mui/material';
import i18next from 'i18next';
import React, { ChangeEvent } from 'react';

import TooltipHelp from 'src/components/TooltipHelp';

import './i18n';
import { Input, WrapperTextInput } from './styles';
import { ITextInputProps } from './types';

const TextInput = (props: ITextInputProps) => {
  const {
    adornment = null,
    error = '',
    isDisabled,
    id,
    name,
    multiline = false,
    multilineRows = 1,
    placeholder,
    value,
    pathI18nHelp = '',
    maxLength = 'unset',
    onChange,
  } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  return (
    <WrapperTextInput>
      <Input
        data-testid="text-field"
        inputProps={{ 'data-testid': 'input', maxLength: maxLength }}
        fullWidth
        multiline={multiline}
        rows={multilineRows}
        error={error !== ''}
        id={id}
        name={name}
        label={placeholder}
        helperText={error}
        disabled={isDisabled}
        value={value}
        type="text"
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <>{adornment && <InputAdornment position="start">{adornment}</InputAdornment>}</>
          ),
        }}
      />
      {i18next.t(`TEXT_INPUT:${pathI18nHelp}`) !== '' && (
        <TooltipHelp text={i18next.t(`TEXT_INPUT:${pathI18nHelp}`)} />
      )}
    </WrapperTextInput>
  );
};

export default TextInput;
