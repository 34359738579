import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const materialityInfoSelector = () =>
  createSelector(
    [(state: IReduxState) => state.materiality.materialityInfo],
    (materialityInfo) => materialityInfo,
  );

export default materialityInfoSelector;
