import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import history from 'src/helpers/history';

import cookiesMiddleware from './middlewares/cookies.middleware';
import notificationsMiddleware from './middlewares/notifications.middleware';
import redirectMiddleware from './middlewares/redirect.middleware';
import reducers from './reducers';
import { IAuthReducerState } from './reducers/auth';
import { ICompanyReducerState } from './reducers/company';
import { IInsightsReducerState } from './reducers/insights';
import { IMaterialityReducerState } from './reducers/materiality';
import { INewsReducerState } from './reducers/news';
import { INewsDraftReducerState } from './reducers/newsDraft';
import { INewsQuerysReducerState } from './reducers/newsQuerys';
import { IUserReducerState } from './reducers/user';
import rootSaga from './sagas';

export const sagaMiddleware = createSagaMiddleware();

export interface IReduxState {
  auth: IAuthReducerState;
  user: IUserReducerState;
  company: ICompanyReducerState;
  news: INewsReducerState;
  newsDraft: INewsDraftReducerState;
  insights: IInsightsReducerState;
  newsQuerys: INewsQuerysReducerState;
  materiality: IMaterialityReducerState;
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  /* preloadedState, */
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      cookiesMiddleware,
      notificationsMiddleware,
      redirectMiddleware,
      sagaMiddleware,
    ),
  ),
);

sagaMiddleware.run(rootSaga);

export default store;
