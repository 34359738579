export const INITIALIZE_INSIGHTS_LIST_VIEW_REQUESTED = 'INITIALIZE_INSIGHTS_LIST_VIEW_REQUESTED';

export const INITIALIZE_INSIGHTS_LIST_VIEW_SUCCEEDED = 'INITIALIZE_INSIGHTS_LIST_VIEW_SUCCEEDED';

export const INITIALIZE_INSIGHTS_LIST_VIEW_FAILED = 'INITIALIZE_INSIGHTS_LIST_VIEW_FAILED';

export const INSIGHTS_ON_DELETE_ONE_REQUESTED = 'INSIGHTS_ON_DELETE_ONE_REQUESTED';

export const INSIGHTS_ON_DELETE_ONE_SUCCEEDED = 'INSIGHTS_ON_DELETE_ONE_SUCCEEDED';

export const INSIGHTS_ON_DELETE_ONE_FAILED = 'INSIGHTS_ON_DELETE_ONE_FAILED';

export const INSIGHTS_ON_DELETE_MANY_REQUESTED = 'INSIGHTS_ON_DELETE_MANY_REQUESTED';

export const INSIGHTS_ON_DELETE_MANY_SUCCEEDED = 'INSIGHTS_ON_DELETE_MANY_SUCCEEDED';

export const INSIGHTS_ON_DELETE_MANY_FAILED = 'INSIGHTS_ON_DELETE_MANY_FAILED';

export const INTIALIZE_INSIGHTS_CREATE_VIEW_REQUESTED = 'INTIALIZE_INSIGHTS_CREATE_VIEW_REQUESTED';

export const INTIALIZE_INSIGHTS_CREATE_VIEW_SUCCEEDED = 'INTIALIZE_INSIGHTS_CREATE_VIEW_SUCCEEDED';

export const INTIALIZE_INSIGHTS_CREATE_VIEW_FAILED = 'INTIALIZE_INSIGHTS_CREATE_VIEW_FAILED';

export const INSIGHTS_ON_CREATE_ONE_REQUESTED = 'INSIGHTS_ON_CREATE_ONE_REQUESTED';

export const INSIGHTS_ON_CREATE_ONE_SUCCEEDED = 'INSIGHTS_ON_CREATE_ONE_SUCCEEDED';

export const INSIGHTS_ON_CREATE_ONE_FAILED = 'INSIGHTS_ON_CREATE_ONE_FAILED';

export const INTIALIZE_INSIGHTS_EDIT_VIEW_REQUESTED = 'INTIALIZE_INSIGHTS_EDIT_VIEW_REQUESTED';

export const INTIALIZE_INSIGHTS_EDIT_VIEW_SUCCEEDED = 'INTIALIZE_INSIGHTS_EDIT_VIEW_SUCCEEDED';

export const INTIALIZE_INSIGHTS_EDIT_VIEW_FAILED = 'INTIALIZE_INSIGHTS_EDIT_VIEW_FAILED';

export const INSIGHTS_ON_EDIT_ONE_REQUESTED = 'INSIGHTS_ON_EDIT_ONE_REQUESTED';

export const INSIGHTS_ON_EDIT_ONE_SUCCEEDED = 'INSIGHTS_ON_EDIT_ONE_SUCCEEDED';

export const INSIGHTS_ON_EDIT_ONE_FAILED = 'INSIGHTS_ON_EDIT_ONE_FAILED';
