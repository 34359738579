import i18next from 'i18next';
import React from 'react';

import Table from 'src/components/Table';
import { IFilterInput } from 'src/components/Table/components/Filter/types';
import { useFilter } from 'src/hooks/useFilter';
import { ICompany } from 'src/types/company';

import './i18n';
import { CompaniesContainer, CompaniesContent } from './styles';
import { ICompaniesListProps } from './types';

const CompaniesList = (props: ICompaniesListProps) => {
  const {
    columns,
    error,
    isLoading,
    companies,
    totalCompanies,
    onFilter,
    onAddNew,
    onEdit,
    onDeleteMany,
    onDeleteOne,
  } = props;

  const [filter, page, setFilter, setPage] = useFilter<Partial<ICompany>>(onFilter);

  const filtersToApply: IFilterInput[] = [
    {
      label: 'Name',
      value: filter.name || '',
      type: 'text',
      onChange: (value: string) => setFilter({ name: value }),
    },
  ];

  return (
    <CompaniesContainer data-testid="companies-view">
      <CompaniesContent>
        <Table
          columns={columns}
          error={error}
          rows={companies}
          title={i18next.t('COMPANIES_LIST:TITLE')}
          loading={isLoading}
          totalRows={totalCompanies}
          autoHeight
          filter={filtersToApply}
          rowHeight={100}
          page={page}
          onNextPage={(currentPage: number) => setPage(currentPage)}
          onAddNew={onAddNew}
          onEdit={onEdit}
          onDeleteOne={onDeleteOne}
          onDeleteMany={onDeleteMany}
        />
      </CompaniesContent>
    </CompaniesContainer>
  );
};

export default CompaniesList;
