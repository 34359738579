import * as materialityConstants from 'src/redux/constants/materiality';

import { ICompany } from 'src/types/company';
import { IMateriality } from 'src/views/Materiality/MaterialityList/types';

export const initializeMaterialityListView = () => ({
  type: materialityConstants.INITIALIZE_MATERIALITY_LIST_VIEW_REQUESTED,
});

export const initializeMaterialityListViewSucceeded = (
  materiality: IMateriality[],
  totalMateriality: number,
  companies: ICompany[],
) => ({
  type: materialityConstants.INITIALIZE_MATERIALITY_LIST_VIEW_SUCCEEDED,
  materiality,
  totalMateriality,
  companies,
});

export const initializeMaterialityListViewFailed = (errorMessage: string) => ({
  type: materialityConstants.INITIALIZE_MATERIALITY_LIST_VIEW_FAILED,
  errorMessage,
});

export const onFilterRequested = (filter: Partial<IMateriality>, page: number) => ({
  type: materialityConstants.MATERIALITY_ON_FILTER_REQUESTED,
  filter,
  page,
});

export const onFilterSucceeded = (materiality: IMateriality[], totalMateriality: number) => ({
  type: materialityConstants.MATERIALITY_ON_FILTER_SUCCEEDED,
  materiality,
  totalMateriality,
});

export const onFilterFailed = (errorMessage: string) => ({
  type: materialityConstants.MATERIALITY_ON_FILTER_FAILED,
  errorMessage,
});

export const onEditOneRequested = (
  materialityId: string,
  newMateriality: Partial<IMateriality>,
) => ({
  type: materialityConstants.MATERIALITY_ON_EDIT_ONE_REQUESTED,
  materialityId,
  newMateriality,
});

export const onEditOneSucceeded = (materiality: IMateriality) => ({
  type: materialityConstants.MATERIALITY_ON_EDIT_ONE_SUCCEEDEED,
  materiality,
});

export const onEditOneFailed = (errorMessage: string) => ({
  type: materialityConstants.MATERIALITY_ON_EDIT_ONE_FAILED,
  errorMessage,
});
