import i18next from 'i18next';
import React, { useState } from 'react';

import StepWizard from 'src/components/StepWizard';
import { ScoreProviders } from 'src/types/score';

import StepProvider from './components/StepProvider';
import StepScore from './components/StepScore';
import './i18n';
import { StepperWrapper, WizardWrapper } from './styles';
import { IScoreWizardProps } from './types';

const ScoreWizard = (props: IScoreWizardProps) => {
  const { defaultScore, onAddNew, onEdit } = props;
  const [currentStep, setCurrentStep] = useState(defaultScore ? 1 : 0);
  const [scoreProvider, setScoreProvider] = useState<ScoreProviders | undefined>(
    defaultScore?.scoreProvider,
  );

  const stepLabels = [
    i18next.t('NEW_SCORE_WIZARD:STEP_PROVIDER'),
    i18next.t('NEW_SCORE_WIZARD:STEP_SCORE'),
  ];

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleProviderChange = (provider: ScoreProviders) => {
    setScoreProvider(provider);
  };

  const handleScoreSubmit = (score: string, scoreName: string) => {
    if (defaultScore) {
      onEdit(scoreProvider!, score, scoreName);
    } else {
      onAddNew(scoreProvider!, score, scoreName);
    }
  };

  const steps = [
    <StepProvider
      provider={scoreProvider}
      onProviderChange={handleProviderChange}
      onSubmit={handleNextStep}
    />,
    <StepScore onSubmit={handleScoreSubmit} defaultScore={defaultScore} />,
  ];

  return (
    <WizardWrapper>
      <StepperWrapper>
        <StepWizard steps={stepLabels} activeStep={currentStep} />
      </StepperWrapper>
      {steps[currentStep]}
    </WizardWrapper>
  );
};

export default ScoreWizard;
