import { IUser } from 'src/types/user';

import * as appConstants from '../constants/app';
import { IAPIResponse } from '../services/api';

export const changeRoute = (location: string) => ({
  type: appConstants.APP_ON_ROUTE_CHANGE,
  location,
});

export const initializePrivateRoute = () => ({
  type: appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_REQUESTED,
});

export const initializePrivateRouteSucceded = (user: IUser) => ({
  type: appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_SUCCEDED,
  user,
});

export const initializePrivateRouteFailed = (error: IAPIResponse) => ({
  type: appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_FAILED,
  error,
});

export const initializePublicRoute = (restricted: boolean) => ({
  type: appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_REQUESTED,
  restricted,
});

export const initializePublicRouteSucceded = (user: IUser, restricted: boolean) => ({
  type: appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_SUCCEDED,
  user,
  restricted,
});

export const initializePublicRouteFailed = (error: IAPIResponse) => ({
  type: appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_FAILED,
  error,
});
