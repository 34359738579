import * as authConstants from 'src/redux/constants/auth';

export const loginRequested = (email: string, password: string) => {
  return {
    type: authConstants.USER_ON_LOGIN_REQUESTED,
    email,
    password,
  };
};

export const loginSucceded = (authToken: string) => {
  return {
    type: authConstants.USER_ON_LOGIN_SUCCEEDED,
    authToken,
  };
};

export const loginFailed = (errorMessage: string) => {
  return {
    type: authConstants.USER_ON_LOGIN_FAILED,
    errorMessage,
  };
};

export const initializeLogin = () => {
  return {
    type: authConstants.USER_ON_INITIALIZE_LOGIN_VIEW,
  };
};

export const signOut = () => ({
  type: authConstants.USER_ON_SIGN_OUT,
});
