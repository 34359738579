import { Chip, TextField } from '@mui/material';
import styled from 'styled-components';

export const Input = styled(TextField)`
  .MuiInputLabel-root {
    color: ${(props) => props.theme.palette.secondary};
  }
  label.Mui-focused {
    color: ${(props) => props.theme.palette.secondary};
  }
  label.Mui-focused {
    color: ${(props) => props.theme.palette.secondary};
  }
  .MuiFormLabel-filled {
    color: ${(props) => props.theme.palette.secondary};
  }
  .MuiInput-root {
    color: ${(props) => props.theme.palette.secondary};
  }
  .MuiInputBase-input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${(props) => props.theme.palette.secondary};
      background-color: ${(props) => props.theme.palette.secondary};
      background-clip: content-box !important;
    }
  }
`;

export const ImageContainer = styled(Chip)`
  width: 150px;
  height: 50px !important;
  margin: 8px !important;
  display: flex !important;
  align-items: flex-start !important;
  background-color: unset !important;
  justify-content: flex-start !important;
`;

export const ImageUpload = styled.img`
  width: 100% !important;
  height: 100% !important;
`;
