import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const usersListSelector = () =>
  createSelector([(state: IReduxState) => state.user.users], (users) => {
    if (!users) return [];
    return users.map((u) => ({
      id: u.id,
      company: u.company,
      name: `${u.firstName} ${u.lastName}`,
      email: u.email,
      changedPassword: u.changedPassword,
      registerUrl: u.registerUrl,
    }));
  });

export default usersListSelector;
