import { AppBar, Button, ListItemText, SwipeableDrawer, Toolbar } from '@mui/material';
import styled from 'styled-components';

export const HeaderContainer = styled(AppBar)`
  && {
    background-color: ${(props) => props.theme.palette.secondary};
    top: 0;
  }
`;

export const ToolbarContainer = styled(Toolbar)`
  && {
    display: flex;
    justify-content: space-between;
  }
`;

export const AccountButton = styled(Button)`
  && {
    font-size: 16px;
    color: ${(props) => props.theme.palette.common.white};
  }
`;

export const RightMenuWrapper = styled.div`
  display: flex;
`;

export const LeftMenuWrapper = styled.div`
  display: flex;
`;

export const LogoWrapper = styled.div`
  align-self: center;
`;

export const DrawerContainer = styled(SwipeableDrawer)`
  && {
    width: 300px;
    flex-shrink: 0;

    .MuiDrawer-paper {
      width: 300px;
      box-sizing: border-box;
    }

    .MuiListItem-root {
      color: inherit;
    }

    .MuiListItem-root.active {
      background-color: ${(props) => props.theme.palette.secondary};

      .MuiListItemIcon-root,
      .MuiListItemText-root {
        color: white;
      }
    }
  }
`;

export const DrawerHeader = styled.div`
  display: 'flex';
  align-items: 'center';
  padding: 20px;
  justify-content: 'flex-end';
`;

export const MenuItemText = styled.span`
  margin: 0 20px;
`;

export const WrapperSubItems = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListSubItemText = styled(ListItemText)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 60px;
`;
