import { ADMIN_TOKEN, APP_ENV } from 'src/configs/configs';

import { getCookie } from './cookies';

export const getAuthToken = () => {
  return (
    getCookie(APP_ENV === 'production' ? `${ADMIN_TOKEN}` : `${ADMIN_TOKEN}_${APP_ENV}`) ||
    undefined
  );
};
