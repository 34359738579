import { stringify } from 'query-string';

import { IMateriality } from 'src/views/Materiality/MaterialityList/types';

import { get, put } from './api';

export const getBy = (filter: Partial<IMateriality>, limit = 10, offset = 0) => {
  return get(`data/materialityRankings?${stringify(filter)}&limit=${limit}&offset=${offset}`);
};

export const editOne = async (materialityId: string, editedMateriality: Partial<IMateriality>) => {
  return put(`data/materialityRankings/${materialityId}`, editedMateriality);
};
