import { Tab, Tabs } from '@mui/material';
import i18next from 'i18next';
import React from 'react';

import { InsightNavigationTabs } from 'src/helpers/enums/insights';

import './i18n';
import { IInsightsNavigationProps } from './types';

const InsightsNavigation = (props: IInsightsNavigationProps) => {
  const { currentTab, onChangeTab } = props;

  const handleChange = (event: React.SyntheticEvent, newValue: InsightNavigationTabs) => {
    onChangeTab(newValue);
  };

  return (
    <Tabs value={currentTab} onChange={handleChange}>
      <Tab label={i18next.t('INSIGHT_NAVIGATION_TABS:TEXT_INSIGHT')} />
      <Tab label={i18next.t('INSIGHT_NAVIGATION_TABS:VIDEO_INSIGHT')} />
    </Tabs>
  );
};

export default InsightsNavigation;
