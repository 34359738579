import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as newsActions from 'src/redux/actions/news';
import companiesSelector from 'src/redux/selectors/company/companies';
import industriesSelector from 'src/redux/selectors/company/industries';
import newsInfoSelector from 'src/redux/selectors/news/newsInfo';

import { NewsTypes } from 'src/helpers/enums/news';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { ICompany } from 'src/types/company';
import { INewNotice } from 'src/types/news';
import NoticeNew from 'src/views/News/NoticeNew';
import {
  getInitialValuesNewsForm,
  getInputsFormNews,
  validationSchema,
} from 'src/views/News/common/formProvider';

import LayoutContainer from '../Layout/Layout';

const NoticeNewContainer = () => {
  const dispatch = useDispatch();

  const newsInfo = useTypedSelector(newsInfoSelector());
  const companies: ICompany[] | null = useTypedSelector(companiesSelector());
  const industries: string[] = useTypedSelector(industriesSelector());

  const form = useFormik({
    initialValues: getInitialValuesNewsForm(),
    validationSchema,
    onSubmit: () => {
      onSubmit(form.values);
    },
  });

  const onSubmit = (newNotice: INewNotice) => {
    let showTypeError = '';
    switch (form.values.showType) {
      case NewsTypes.INDUSTRY:
        showTypeError =
          form.values.industry.length === 0 ? 'One industry is at least required' : '';
        break;
      case NewsTypes.SPECIFIC:
        showTypeError =
          form.values.companies.length === 0 ? 'One company is at least required' : '';
        break;
    }
    if (showTypeError !== '') {
      form.setFieldError('showType', showTypeError);
    } else {
      dispatch(newsActions.onCreateOneRequested(newNotice));
    }
  };

  const onInitializeNewsView = useCallback(() => {
    dispatch(newsActions.initializeNewsCreateView());
  }, [dispatch]);

  useEffect(() => {
    onInitializeNewsView();
  }, [onInitializeNewsView]);

  return (
    <LayoutContainer loading={!companies}>
      <NoticeNew
        loading={newsInfo.info.loading}
        inputs={getInputsFormNews(form, companies!, industries)}
        onSubmitForm={form.handleSubmit}
      />
    </LayoutContainer>
  );
};

export default NoticeNewContainer;
