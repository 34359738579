import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'NEW_SCORE_WIZARD', {
  BUTTON_TEXT: 'Next',
  TITLE: 'Select score provider',
  STEP_PROVIDER: 'Score provider',
  STEP_SCORE: 'Score',
  INPUT_LABEL: 'Score provider',
});
