import { Visibility } from '@mui/icons-material';
import styled from 'styled-components';

export const NewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
`;

export const NewsContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const VisiblityIcon = styled(Visibility)`
  fill: ${(props) => props.theme.palette.secondary} !important;
`;

export const NewsDraftThumbnail = styled.img`
  border-radius: 10px;
  height: 100px;
`;
