import * as companyConstants from 'src/redux/constants/company';

import { ICompany, INewCompany } from 'src/types/company';

export const initializeCompaniesListView = () => ({
  type: companyConstants.INITIALIZE_COMPANIES_LIST_VIEW_REQUESTED,
});

export const initializeCompaniesListViewSucceeded = (
  companies: ICompany[],
  totalCompanies: number,
) => ({
  type: companyConstants.INTIIALIZE_COMPANIES_LIST_VIEW_SUCCEEDED,
  companies,
  totalCompanies,
});

export const initializeCompaniesListViewFailed = (errorMessage: string) => ({
  type: companyConstants.INTIIALIZE_COMPANIES_LIST_VIEW_FAILED,
  errorMessage,
});

export const initializeCompaniesEditView = (companyId: string) => ({
  type: companyConstants.INTIALIZE_COMPANIES_EDIT_VIEW_REQUESTED,
  companyId,
});

export const initializeCompaniesEditViewSucceeded = (
  company: ICompany,
  allCompanies: ICompany[],
) => ({
  type: companyConstants.INTIALIZE_COMPANIES_EDIT_VIEW_SUCCEEDED,
  company,
  allCompanies,
});

export const initializeCompaniesEditViewFailed = (errorMessage: string) => ({
  type: companyConstants.INTIALIZE_COMPANIES_EDIT_VIEW_FAILED,
  errorMessage,
});

export const onDeleteOneRequested = (companyId: string) => ({
  type: companyConstants.COMPANIES_ON_DELETE_ONE_REQUESTED,
  companyId,
});

export const onDeleteOneSucceeded = (companyId: string) => ({
  type: companyConstants.COMPANIES_ON_DELETE_ONE_SUCCEEDED,
  companyId,
});

export const onDeleteOneFailed = (errorMessage: string) => ({
  type: companyConstants.COMPANIES_ON_DELETE_ONE_FAILED,
  errorMessage,
});

export const onDeleteManyRequested = (companyIds: string[]) => ({
  type: companyConstants.COMPANIES_ON_DELETE_MANY_REQUESTED,
  companyIds,
});

export const onDeleteManySucceeded = (companyIds: string[], countDeleted: number) => ({
  type: companyConstants.COMPANIES_ON_DELETE_MANY_SUCCEEDED,
  companyIds,
  countDeleted,
});

export const onDeleteManyFailed = (errorMessage: string) => ({
  type: companyConstants.COMPANIES_ON_DELETE_MANY_FAILED,
  errorMessage,
});

export const onEditOneRequested = (companyId: string, newCompany: INewCompany) => ({
  type: companyConstants.COMPANIES_ON_EDIT_ONE_REQUESTED,
  companyId,
  newCompany,
});

export const onEditOneSucceeded = (company: ICompany) => ({
  type: companyConstants.COMPANIES_ON_EDIT_ONE_SUCCEEDED,
  company,
});

export const onEditOneFailed = (errorMessage: string) => ({
  type: companyConstants.COMPANIES_ON_EDIT_ONE_FAILED,
  errorMessage,
});

export const onCreateOneRequested = (company: INewCompany) => ({
  type: companyConstants.COMPANIES_ON_CREATE_ONE_REQUESTED,
  company,
});

export const onCreateOneSucceeded = (companies: ICompany[]) => ({
  type: companyConstants.COMPANIES_ON_CREATE_ONE_SUCCEEDED,
  companies,
});

export const onCreateOneFailed = (errorMessage: string) => ({
  type: companyConstants.COMPANIES_ON_CREATE_ONE_FAILED,
  errorMessage,
});

export const onInitializeCompaniesNewView = () => ({
  type: companyConstants.INITIALIZE_NEW_COMPANIES_VIEW_REQUESTED,
});

export const onInitializeCompaniesNewViewSucceeded = (companies: ICompany[]) => ({
  type: companyConstants.INITIALIZE_NEW_COMPANIES_VIEW_SUCCEEDED,
  companies,
});

export const onInitializeCompaniesNewViewFailed = (errorMessage: string) => ({
  type: companyConstants.INITIALIZE_NEW_COMPANIES_VIEW_FAILED,
  errorMessage,
});

export const onFilterRequested = (filter: Partial<ICompany>, page: number) => ({
  type: companyConstants.COMPANIES_FILTER_REQUESTED,
  filter,
  page,
});

export const onFilterSucceeded = (companies: ICompany[], totalCompanies: number) => ({
  type: companyConstants.COMPANIES_FILTER_SUCCEEDED,
  companies,
  totalCompanies,
});

export const onFilterFailed = (errorMessage: string) => ({
  type: companyConstants.COMPANIES_FILTER_FAILED,
  errorMessage,
});
