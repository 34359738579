import i18next from 'i18next';
import React from 'react';

import Table from 'src/components/Table';
import DeleteActionButton from 'src/components/Table/components/Delete';
import { IFilterInput } from 'src/components/Table/components/Filter/types';
import { NewsNavigationTabs } from 'src/helpers/enums/news';
import { ActionTypes } from 'src/helpers/enums/tableActions';
import { useFilter } from 'src/hooks/useFilter';
import { INewsDraft } from 'src/types/news';
import { NavigationTabsWrapper } from 'src/views/News/NewsList/styles';
import NewsNavigation from 'src/views/News/components/NewsNavigation';

import './i18n';
import { NewsContainer, NewsContent, VisiblityIcon } from './styles';
import { INewsDraftListProps } from './types';

const NewsDraftList = (props: INewsDraftListProps) => {
  const {
    columns,
    totalNews,
    isLoading,
    news,
    onTabChange,
    onPublish,
    onFilter,
    onEdit,
    onPurgeNews,
  } = props;

  const [filter, page, setFilter, setPage] = useFilter<Partial<INewsDraft>>(onFilter);

  const filtersToApply: IFilterInput[] = [
    {
      label: 'Query',
      value: filter.queryName || '',
      type: 'text',
      onChange: (value: string) => setFilter({ queryName: value }),
    },
    {
      label: 'Title',
      value: filter.title || '',
      type: 'text',
      onChange: (value: string) => setFilter({ title: value }),
    },
  ];

  const customRowActions = [
    {
      button: <VisiblityIcon />,
      disabled: () => false,
      loading: isLoading,
      tooltip: i18next.t('NEWS_DRAFT_LIST:PUBLISH'),
      onClick: onPublish,
    },
  ];

  return (
    <NewsContainer data-testid="news-draft-view">
      <NavigationTabsWrapper>
        <NewsNavigation currentTab={NewsNavigationTabs.DRAFT_NEWS} onChangeTab={onTabChange} />
        <DeleteActionButton onClick={onPurgeNews} label="Purge News" disabled={false} />
      </NavigationTabsWrapper>
      <NewsContent>
        <Table
          columns={columns}
          rows={news}
          totalRows={totalNews}
          title={i18next.t('NEWS_DRAFT_LIST:TITLE')}
          loading={isLoading}
          autoHeight
          toolbarActions={[]}
          includeCheckboxes={false}
          rowActions={[ActionTypes.EDIT]}
          customRowActions={customRowActions}
          filter={filtersToApply}
          rowHeight={100}
          page={page}
          onNextPage={(currentPage: number) => setPage(currentPage)}
          onEdit={onEdit}
        />
      </NewsContent>
    </NewsContainer>
  );
};

export default NewsDraftList;
