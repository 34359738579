import React from 'react';

import PrivateRoute from 'src/components/PrivateRoute';
import InsightsEditContainer from 'src/containers/Insights/InsightsEdit';
import InsightsListContainer from 'src/containers/Insights/InsightsList';
import InsightsNewContainer from 'src/containers/Insights/insightsNew';

const InsightRoutes = (isUserLogged: boolean) => {
  return [
    <PrivateRoute
      key="list"
      path="/insights"
      exact
      component={InsightsListContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="new"
      path="/insights/new"
      component={InsightsNewContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="edit"
      path="/insights/edit/:id"
      component={InsightsEditContainer}
      isUserLogged={isUserLogged}
    />,
  ];
};

export default InsightRoutes;
