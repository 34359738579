import { Autocomplete, Box } from '@mui/material';
import i18next from 'i18next';
import React, { useCallback } from 'react';

import { countries, CountryType } from './countries';
import './i18n';
import { Flag, Input, Label } from './styles';
import { ICountrySelectProps } from './types';

const CountrySelect = (props: ICountrySelectProps) => {
  const { value = '', onChange, error = '', isDisabled } = props;

  const handleChange = (event: any, country: CountryType | null) => {
    onChange(country!.label);
  };

  const getCountry = useCallback(() => {
    return countries.find((c) => c.label === value);
  }, [value]);

  return (
    <Autocomplete
      id="country-select"
      fullWidth
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.label}
      onChange={handleChange}
      value={getCountry() || null}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <Flag
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
          />
          {option.label} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <>
          <Label id="countries-list">{i18next.t('COUNTRIES_LIST:INPUT_LABEL')}</Label>
          <Input
            {...params}
            error={error !== ''}
            disabled={isDisabled}
            helperText={error}
            id="countries-list"
            name="countries-list"
          />
        </>
      )}
    />
  );
};

export default CountrySelect;
