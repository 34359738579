import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const newsDraftListSelector = () =>
  createSelector([(state: IReduxState) => state.newsDraft.newsDraft], (news) => {
    if (!news) return [];
    return news.map((n) => ({
      id: n.id,
      title: n.title,
      description: n.description,
      thumbnail: n.thumbnail,
      showType: n.showType,
      industries: n.industries,
      companies: n.companies,
      queryName: n.queryName,
    }));
  });

export default newsDraftListSelector;
