import styled from 'styled-components';

export const DataLabel = styled.p`
  color: ${(props) => props.theme.palette.secondary};
  font-size: 14px;
  font-family: ${(props) => props.theme.fontFamilies.regular};
  margin-bottom: 3px;
`;

export const DataText = styled.p`
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  font-size: 20px;
  word-break: break-all;
`;

export const InputsWrapper = styled.div`
  width: 568px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 30px;
  background-color: ${(props) => props.theme.palette.common.white};
`;

export const UserData = styled.p`
  margin-bottom: 20px;
`;
