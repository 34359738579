import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const insightInfoSelector = () =>
  createSelector(
    [(state: IReduxState) => state.insights.insightInfo],
    (insightInfo) => insightInfo,
  );

export default insightInfoSelector;
