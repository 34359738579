export const INITIALIZE_COMPANIES_LIST_VIEW_REQUESTED = 'INITIALIZE_COMPANIES_LIST_VIEW_REQUESTED';

export const INTIIALIZE_COMPANIES_LIST_VIEW_SUCCEEDED = 'INTIIALIZE_COMPANIES_LIST_VIEW_SUCCEEDED';

export const INTIIALIZE_COMPANIES_LIST_VIEW_FAILED = 'INTIIALIZE_COMPANIES_LIST_VIEW_FAILED';

export const COMPANIES_ON_DELETE_ONE_REQUESTED = 'COMPANIES_ON_DELETE_ONE_REQUESTED';

export const COMPANIES_ON_DELETE_ONE_SUCCEEDED = 'COMPANIES_ON_DELETE_ONE_SUCCEEDED';

export const COMPANIES_ON_DELETE_ONE_FAILED = 'COMPANIES_ON_DELETE_ONE_FAILED';

export const COMPANIES_ON_DELETE_MANY_REQUESTED = 'COMPANIES_ON_DELETE_MANY_REQUESTED';

export const COMPANIES_ON_DELETE_MANY_SUCCEEDED = 'COMPANIES_ON_DELETE_MANY_SUCCEEDED';

export const COMPANIES_ON_DELETE_MANY_FAILED = 'COMPANIES_ON_DELETE_MANY_FAILED';

export const COMPANIES_ON_EDIT_ONE_REQUESTED = 'COMPANIES_ON_EDIT_ONE_REQUESTED';

export const COMPANIES_ON_EDIT_ONE_SUCCEEDED = 'COMPANIES_ON_EDIT_ONE_SUCCEEDED';

export const COMPANIES_ON_EDIT_ONE_FAILED = 'COMPANIES_ON_EDIT_ONE_FAILED';

export const COMPANIES_ON_CREATE_ONE_REQUESTED = 'COMPANIES_ON_CREATE_ONE_REQUESTED';

export const COMPANIES_ON_CREATE_ONE_SUCCEEDED = 'COMPANIES_ON_CREATE_ONE_SUCCEEDED';

export const COMPANIES_ON_CREATE_ONE_FAILED = 'COMPANIES_ON_CREATE_ONE_FAILED';

export const INTIALIZE_COMPANIES_EDIT_VIEW_REQUESTED = 'INTIALIZE_COMPANIES_EDIT_VIEW_REQUESTED';

export const INTIALIZE_COMPANIES_EDIT_VIEW_SUCCEEDED = 'INTIALIZE_COMPANIES_EDIT_VIEW_SUCCEEDED';

export const INTIALIZE_COMPANIES_EDIT_VIEW_FAILED = 'INTIALIZE_COMPANIES_EDIT_VIEW_FAILED';

export const INITIALIZE_NEW_COMPANIES_VIEW_REQUESTED = 'INITIALIZE_NEW_COMPANIES_VIEW_REQUESTED';

export const INITIALIZE_NEW_COMPANIES_VIEW_SUCCEEDED = 'INITIALIZE_NEW_COMPANIES_VIEW_SUCCEEDED';

export const INITIALIZE_NEW_COMPANIES_VIEW_FAILED = 'INITIALIZE_NEW_COMPANIES_VIEW_FAILED';

export const COMPANIES_FILTER_REQUESTED = 'COMPANIES_FILTER_REQUESTED';

export const COMPANIES_FILTER_SUCCEEDED = 'COMPANIES_FILTER_SUCCEEDED';

export const COMPANIES_FILTER_FAILED = 'COMPANIES_FILTER_FAILED';
