import { NewsProviders, NewsTypes } from 'src/helpers/enums/news';
import { ICompany } from 'src/types/company';
import { INewQuery } from 'src/types/newsQuery';

export const deleteCompanies = (oldCompanies: ICompany[], companyId: string) => {
  return oldCompanies.filter((element) => element.id !== companyId);
};

export const deleteIndustry = (oldIndustries: string[], industryId: string) => {
  return oldIndustries.filter((element) => element !== industryId);
};

export const removeExtraData = (notice: Partial<INewQuery>) => {
  switch (notice.showType) {
    case NewsTypes.SPECIFIC:
      return { ...notice, industry: [] };
    case NewsTypes.INDUSTRY:
      return { ...notice, companies: [] };
    default:
      return { ...notice, industry: [], companies: [] };
  }
};

export const MapQueryDataToString = (data: any) => ({
  [NewsProviders.BING]: `"${data.q}" in ${data.mkt} market`,
});
