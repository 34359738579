import styled from 'styled-components';

export const WizardWrapper = styled.div`
  height: 350px;
  padding: 30px;
  display: flex;
  flex-direction: column;
`;

export const StepperWrapper = styled.div``;
