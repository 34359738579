import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const newsQueryInfoSelector = () =>
  createSelector(
    [(state: IReduxState) => state.newsQuerys.newsQueryInfo],
    (newsQueryInfo) => newsQueryInfo,
  );

export default newsQueryInfoSelector;
