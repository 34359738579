import React from 'react';

import StepCompany from './components/StepCompany';
import { WizardWrapper } from './styles';
import { INewCompanyWizardProps } from './types';

const NewCompanyWizard = (props: INewCompanyWizardProps) => {
  const { onAddNew, companies } = props;

  const handleCompaniesSubmit = (id: string) => {
    const newCompany = companies.find((element) => element.id === id);
    onAddNew(newCompany!);
  };
  return (
    <WizardWrapper>
      <StepCompany onSubmit={handleCompaniesSubmit} allCompanies={companies}></StepCompany>
    </WizardWrapper>
  );
};

export default NewCompanyWizard;
