import i18next from 'i18next';
import React from 'react';

import Form from 'src/components/Form';

import './i18n';
import { FormWrapper, Title } from './styles';
import { IUserNewProps } from './types';

const UsersNew = (props: IUserNewProps) => {
  const { inputs, onSubmitForm, loading } = props;

  return (
    <FormWrapper>
      <Title> {i18next.t('USERS_NEW:TITLE')}</Title>
      <Form
        inputs={inputs}
        onSubmit={onSubmitForm}
        isLoading={loading}
        submitText={i18next.t('USERS_NEW:SUBMIT_BUTTON')}
      />
    </FormWrapper>
  );
};

export default UsersNew;
