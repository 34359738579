import * as userConstants from 'src/redux/constants/user';

import { ICompany } from 'src/types/company';
import { INewUser, IUser } from 'src/types/user';

export const initializeUsersListView = () => ({
  type: userConstants.INITIALIZE_USERS_LIST_VIEW_REQUESTED,
});

export const initializeUsersListViewSucceeded = (users: IUser[]) => ({
  type: userConstants.INTIIALIZE_USERS_LIST_VIEW_SUCCEEDED,
  users,
});

export const initializeUsersListViewFailed = (errorMessage: string) => ({
  type: userConstants.INTIIALIZE_USERS_LIST_VIEW_FAILED,
  errorMessage,
});

export const initializeUsersEditView = (userId: string) => ({
  type: userConstants.INTIALIZE_USERS_EDIT_VIEW_REQUESTED,
  userId,
});

export const initializeUsersEditViewSucceeded = (user: IUser, companies: ICompany[]) => ({
  type: userConstants.INTIALIZE_USERS_EDIT_VIEW_SUCCEEDED,
  user,
  companies,
});

export const initializeUsersEditViewFailed = (errorMessage: string) => ({
  type: userConstants.INTIALIZE_USERS_EDIT_VIEW_FAILED,
  errorMessage,
});

export const initializeUsersCreateView = () => ({
  type: userConstants.INTIALIZE_USERS_CREATE_VIEW_REQUESTED,
});

export const initializeUsersCreateViewSucceeded = (companies: ICompany[]) => ({
  type: userConstants.INTIALIZE_USERS_CREATE_VIEW_SUCCEEDED,
  companies,
});

export const initializeUsersCreateViewFailed = (errorMessage: string) => ({
  type: userConstants.INTIALIZE_USERS_CREATE_VIEW_FAILED,
  errorMessage,
});

export const onDeleteOneRequested = (userId: string) => ({
  type: userConstants.USERS_ON_DELETE_ONE_REQUESTED,
  userId,
});

export const onDeleteOneSucceeded = (userId: string) => ({
  type: userConstants.USERS_ON_DELETE_ONE_SUCCEEDED,
  userId,
});

export const onDeleteOneFailed = (errorMessage: string) => ({
  type: userConstants.USERS_ON_DELETE_ONE_FAILED,
  errorMessage,
});

export const onDeleteManyRequested = (userIds: string[]) => ({
  type: userConstants.USERS_ON_DELETE_MANY_REQUESTED,
  userIds,
});

export const onDeleteManySucceeded = (userIds: string[], countDeleted: number) => ({
  type: userConstants.USERS_ON_DELETE_MANY_SUCCEEDED,
  userIds,
  countDeleted,
});

export const onDeleteManyFailed = (errorMessage: string) => ({
  type: userConstants.USERS_ON_DELETE_MANY_FAILED,
  errorMessage,
});

export const onEditOneRequested = (userId: string, newUser: INewUser) => ({
  type: userConstants.USERS_ON_EDIT_ONE_REQUESTED,
  userId,
  newUser,
});

export const onEditOneSucceeded = (user: IUser) => ({
  type: userConstants.USERS_ON_EDIT_ONE_SUCCEEDED,
  user,
});

export const onEditOneFailed = (errorMessage: string) => ({
  type: userConstants.USERS_ON_EDIT_ONE_FAILED,
  errorMessage,
});

export const onCreateOneRequested = (user: INewUser, companyCompetitors: ICompany[]) => ({
  type: userConstants.USERS_ON_CREATE_ONE_REQUESTED,
  user,
  companyCompetitors,
});

export const onCreateOneSucceeded = (users: IUser[]) => ({
  type: userConstants.USERS_ON_CREATE_ONE_SUCCEEDED,
  users,
});

export const onCreateOneFailed = (errorMessage: string) => ({
  type: userConstants.USERS_ON_CREATE_ONE_FAILED,
  errorMessage,
});

export const onReSendEmailToChangePassword = (userId: string) => ({
  type: userConstants.USER_ON_RE_SEND_EMAIL_TO_CHANGE_PASSWORD_REQUESTED,
  userId,
});

export const onReSendEmailToChangePasswordSucceeded = (userId: string, sentUrl: string) => ({
  type: userConstants.USER_ON_RE_SEND_EMAIL_TO_CHANGE_PASSWORD_SUCCEEDED,
  sentUrl,
  userId,
});

export const onReSendEmailToChangePasswordFailed = (errorMessage: IUser) => ({
  type: userConstants.USER_ON_RE_SEND_EMAIL_TO_CHANGE_PASSWORD_FAILED,
  errorMessage,
});

export const onSetInvalidUser = () => ({
  type: userConstants.USER_ON_SET_INVALID_COMPANY,
});
