import moment from 'moment';
import 'moment-timezone';
import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

import { MapQueryDataToString } from 'src/views/NewsQuerys/common/helpers';

const newsQuerysListSelector = () =>
  createSelector([(state: IReduxState) => state.newsQuerys.newsQuerys], (newsQuerys) => {
    if (!newsQuerys) return [];
    return newsQuerys.map((n) => ({
      id: n.id,
      name: n.name,
      provider: n.provider,
      searchData: MapQueryDataToString(n.data)[n.provider],
      lastProcessedDate: n.lastProcessedDate
        ? moment.tz(n.lastProcessedDate, moment.tz.guess()).format('YYYY-MM-DD [at] HH:mm:ss')
        : 'This query has not been run yet',
      active: n.active,
    }));
  });

export default newsQuerysListSelector;
