import React from 'react';

import StepIndustry from './components/StepIndustry';
import { WizardWrapper } from './styles';
import { INewIndustryWizardProps } from './types';

const NewIndustryWizard = (props: INewIndustryWizardProps) => {
  const { onAddNew, industries } = props;

  const handleScoreSubmit = (industry: string) => {
    onAddNew(industry);
  };

  return (
    <WizardWrapper>
      <StepIndustry onSubmit={handleScoreSubmit} industries={industries} />
    </WizardWrapper>
  );
};

export default NewIndustryWizard;
