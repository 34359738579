import { NewsTypes } from 'src/helpers/enums/news';
import { ICompany } from 'src/types/company';
import { INewInsights } from 'src/types/insights';

export const deleteCompanies = (oldCompanies: ICompany[], companyId: string) => {
  return oldCompanies.filter((element) => element.id !== companyId);
};

export const deleteIndustry = (oldIndustries: string[], industryId: string) => {
  return oldIndustries.filter((element) => element !== industryId);
};

export const removeExtraData = (insights: INewInsights) => {
  switch (insights.showType) {
    case NewsTypes.SPECIFIC:
      return { ...insights, industries: [] };
    case NewsTypes.INDUSTRY:
      return { ...insights, companies: [] };
    default:
      return { ...insights, industries: [], companies: [] };
  }
};
