import React, { useEffect } from 'react';

import TextButton from '../Buttons/TextButton';
import { InputsWrapper, InputWrapper, FormContainer, SubmitButtonWrapper } from './styles';
import { IFormProps } from './types';

const Form = (props: IFormProps) => {
  const { inputs, isLoading, onSubmit, submitText = 'Submit' } = props;

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.keyCode === 13 && !isLoading) {
        onSubmit();
      }
    };
    window.addEventListener('keyup', handleKeyUp, false);
    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  });

  return (
    <FormContainer data-testid="form" autoComplete="off">
      <InputsWrapper>
        {inputs.map((input) => {
          return (
            <InputWrapper data-testid={`form-input-${input.key}`} key={input.key}>
              {input.input}
            </InputWrapper>
          );
        })}
      </InputsWrapper>
      <SubmitButtonWrapper data-testid="form-submit-button-block">
        <TextButton
          text={submitText}
          isLoading={isLoading}
          onSubmit={onSubmit}
          isDisabled={false}
        />
      </SubmitButtonWrapper>
    </FormContainer>
  );
};

export default Form;
