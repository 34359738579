import i18next from 'i18next';
import React from 'react';

import Form from 'src/components/Form';

import './i18n';
import { FormWrapper, Title } from './styles';
import { INoticeEdit } from './types';

const NoticeEdit = (props: INoticeEdit) => {
  const { loading, inputs, onSubmitForm } = props;

  return (
    <FormWrapper>
      <Title> {i18next.t('NEWS_EDIT:TITLE')}</Title>
      <Form
        inputs={inputs}
        onSubmit={onSubmitForm}
        isLoading={loading}
        submitText={i18next.t('NEWS_EDIT:SUBMIT_BUTTON')}
      />
    </FormWrapper>
  );
};

export default NoticeEdit;
