import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

import { HelpIcon } from './styles';
import { ITooltipHelpProps } from './types';

const TooltipHelp = (props: ITooltipHelpProps) => {
  const { text } = props;

  return (
    <Tooltip title={text} data-testid={'button-tooltip-help'}>
      <IconButton>
        <HelpIcon />
      </IconButton>
    </Tooltip>
  );
};

export default TooltipHelp;
