import styled from 'styled-components';

export const InsightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
`;

export const InsightsContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const NavigationWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
