import i18next from 'i18next';
import React from 'react';

import TextButton from 'src/components/Buttons/TextButton';
import Form from 'src/components/Form';

import './i18n';
import { FormWrapper, PublishButtonWrapper, Title } from './styles';
import { INewsDraftEditProps } from './types';

const NoticeEdit = (props: INewsDraftEditProps) => {
  const { loading, inputs, onSubmitForm } = props;

  return (
    <FormWrapper>
      <Title> {i18next.t('NEWS_DRAFT_EDIT:TITLE')}</Title>
      <Form
        inputs={inputs}
        onSubmit={() => onSubmitForm(false)}
        isLoading={loading}
        submitText={i18next.t('NEWS_DRAFT_EDIT:SUBMIT_BUTTON')}
      />
      <PublishButtonWrapper>
        <TextButton
          text={i18next.t('NEWS_DRAFT_EDIT:PUBLISH')}
          isLoading={loading}
          onSubmit={() => onSubmitForm(true)}
          isDisabled={false}
        />
      </PublishButtonWrapper>
    </FormWrapper>
  );
};

export default NoticeEdit;
