import { AnyAction } from 'redux';

import { INewsQuery } from 'src/types/newsQuery';
import IReducerInfo from 'src/types/reducer';

import * as newsQuerysConstants from '../constants/newsQuery';

export interface INewsQuerysReducerState {
  newsQuerys: INewsQuery[] | null;
  newsQuerysToEdit: INewsQuery | null;
  newsQueryInfo: IReducerInfo;
}

const defaultState: INewsQuerysReducerState = {
  newsQuerys: null,
  newsQuerysToEdit: null,
  newsQueryInfo: {
    info: {
      error: false,
      success: false,
      loading: false,
    },
    message: '',
  },
};

const setNewsQuerys = (state: INewsQuerysReducerState, newsQuerys: INewsQuery[] | null) => {
  return {
    ...state,
    newsQuerys,
  };
};

const updateNewsQuerys = (state: INewsQuerysReducerState, newsQuerysUpdate: INewsQuery) => {
  const updatedNewsQuerys = state.newsQuerys?.map((element) => {
    if (element.id === newsQuerysUpdate.id) {
      return newsQuerysUpdate;
    }
    return element;
  });
  return {
    ...state,
    newsQuerys: updatedNewsQuerys,
  };
};

const setNewsQuerysInfo = (
  state: INewsQuerysReducerState,
  error: boolean,
  success: boolean,
  loading: boolean,
  message: string,
) => {
  return {
    ...state,
    newsQueryInfo: {
      info: {
        error,
        success,
        loading,
      },
      message,
    },
  };
};

const setNewQueryToEdit = (
  state: INewsQuerysReducerState,
  newsQuerysToEdit: INewsQuery | null,
) => ({
  ...state,
  newsQuerysToEdit,
});

const removeNewsQuerys = (state: INewsQuerysReducerState, idsToRemove: string[]) => {
  const newNewsQuerys = state.newsQuerys?.filter((u) => !idsToRemove.includes(u.id)) || null;
  return {
    ...state,
    newsQuerys: newNewsQuerys,
  };
};

const newsQuerysReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case newsQuerysConstants.INITIALIZE_NEWS_QUERYS_LIST_VIEW_REQUESTED:
    case newsQuerysConstants.NEWS_QUERYS_ON_DELETE_ONE_REQUESTED:
    case newsQuerysConstants.NEWS_QUERYS_ON_DELETE_MANY_REQUESTED:
    case newsQuerysConstants.NEWS_QUERY_RUN_REQUESTED:
      return setNewsQuerysInfo(state, false, false, true, '');
    case newsQuerysConstants.INTIIALIZE_NEWS_QUERYS_LIST_VIEW_SUCCEEDED:
      return setNewsQuerysInfo(setNewsQuerys(state, action.newsQuerys), false, true, false, '');
    case newsQuerysConstants.NEWS_QUERY_RUN_SUCCEEDED:
      return updateNewsQuerys(setNewsQuerysInfo(state, false, true, false, ''), action.newsQuerys);
    case newsQuerysConstants.NEWS_QUERYS_ON_CHANGE_ACTIVE_QUERY_SUCCEEDED:
      return updateNewsQuerys(state, action.newsQuerys);
    case newsQuerysConstants.NEWS_QUERYS_ON_DELETE_ONE_SUCCEEDED:
      return setNewsQuerysInfo(
        removeNewsQuerys(state, [action.newQueryId]),
        false,
        true,
        false,
        '',
      );
    case newsQuerysConstants.NEWS_QUERYS_ON_DELETE_MANY_SUCCEEDED:
      return setNewsQuerysInfo(removeNewsQuerys(state, action.newQueryIds), false, true, false, '');
    case newsQuerysConstants.NEWS_QUERYS_ON_CREATE_ONE_SUCCEEDED:
      return setNewsQuerysInfo(setNewsQuerys(state, action.newsQuery), false, true, false, '');
    case newsQuerysConstants.INTIALIZE_NEWS_QUERYS_EDIT_VIEW_SUCCEEDED:
      return setNewsQuerysInfo(setNewQueryToEdit(state, action.newsQuery), false, true, false, '');
    case newsQuerysConstants.NEWS_QUERYS_ON_CHANGE_ACTIVE_QUERY_FAILED:
    case newsQuerysConstants.NEWS_QUERYS_ON_DELETE_ONE_FAILED:
    case newsQuerysConstants.NEWS_QUERYS_ON_DELETE_MANY_FAILED:
    case newsQuerysConstants.NEWS_QUERYS_ON_CREATE_ONE_FAILED:
    case newsQuerysConstants.INTIALIZE_NEWS_QUERYS_EDIT_VIEW_FAILED:
    case newsQuerysConstants.INTIIALIZE_NEWS_QUERYS_LIST_VIEW_FAILED:
      return setNewsQuerysInfo(state, true, false, false, action.errorMessage);
    case newsQuerysConstants.NEWS_QUERYS_ON_EDIT_ONE_SUCCEEDED:
      return setNewsQuerysInfo(setNewQueryToEdit(state, action.newsQuery), false, true, false, '');
    default:
      return state;
  }
};

export default newsQuerysReducer;
