import { FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { IFormInput } from 'src/components/Form/types';
import FileInput from 'src/components/Inputs/FileInput';
import SelectInput from 'src/components/Inputs/SelectInput';
import TextInput from 'src/components/Inputs/TextInput';
import { NewsTypes } from 'src/helpers/enums/news';
import { ICompany } from 'src/types/company';
import { INewNotice, INews } from 'src/types/news';

import CompanyList from '../components/Companies/CompanyList';
import IndustryList from '../components/Industry/IndustryList';
import { deleteCompanies, deleteIndustry } from './helpers';

export const validationSchema = Yup.object({
  title: Yup.string().required('Title is required').trim(),
  description: Yup.string().required('Description is required').trim(),
  link: Yup.string().required('Link is required').trim(),
  thumbnail: Yup.string().trim(),
  showType: Yup.string().required('Show types is required'),
});

export const getInitialValuesNewsForm = (newsToEdit?: INews | null) => {
  return {
    title: newsToEdit ? newsToEdit.title : '',
    description: newsToEdit ? newsToEdit.description : '',
    link: newsToEdit ? newsToEdit.link : '',
    thumbnail: newsToEdit ? newsToEdit.thumbnail : ('' as string | File),
    showType: newsToEdit ? newsToEdit.showType : NewsTypes.ALL,
    industry: newsToEdit ? newsToEdit.industry : [],
    companies: newsToEdit ? newsToEdit.companies : [],
  };
};

export const getInputsFormNews = (
  config: FormikProps<INewNotice>,
  allCompanies: ICompany[],
  allIndustries: string[],
) => {
  const inputs: IFormInput[] = [
    {
      key: 'title',
      input: (
        <TextInput
          id="title"
          name="title"
          placeholder="Title"
          error={
            config.touched.title && Boolean(config.errors.title)
              ? (config.errors.title as string)
              : ''
          }
          value={config.values.title}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'description',
      input: (
        <TextInput
          id="description"
          name="description"
          placeholder="Description"
          multiline={true}
          multilineRows={5}
          error={
            config.touched.description && Boolean(config.errors.description)
              ? (config.errors.description as string)
              : ''
          }
          value={config.values.description}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'link',
      input: (
        <TextInput
          id="link"
          name="link"
          placeholder="Link"
          error={
            config.touched.link && Boolean(config.errors.link) ? (config.errors.link as string) : ''
          }
          value={config.values.link}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'thumbnail',
      input: (
        <FileInput
          id="thumbnail"
          name="thumbnail"
          value={config.values.thumbnail}
          placeholder="Thumbnail"
          error={
            config.touched.thumbnail && Boolean(config.errors.thumbnail)
              ? (config.errors.thumbnail as string)
              : ''
          }
          onChange={(newFile: File | string) => config.setFieldValue('thumbnail', newFile)}
        />
      ),
    },
    {
      key: 'showType',
      input: (
        <SelectInput
          id="showType"
          name="showType"
          label="Show Types"
          error={
            config.touched.showType && Boolean(config.errors.showType)
              ? (config.errors.showType as string)
              : ''
          }
          pathI18nHelp={'HELP_TEXT_SHOW_TYPES'}
          value={config.values.showType}
          options={[
            {
              value: NewsTypes.ALL,
              text: NewsTypes.ALL,
            },
            { value: NewsTypes.SPECIFIC, text: NewsTypes.SPECIFIC },
            {
              value: NewsTypes.INDUSTRY,
              text: NewsTypes.INDUSTRY,
            },
          ]}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'industry',
      input: (
        <IndustryList
          industry={config.values.industry}
          allIndustries={allIndustries}
          pathI18nHelp={'HELP_TEXT_INDUSTRY_LIST'}
          disabled={
            config.values.showType === NewsTypes.ALL ||
            config.values.showType === NewsTypes.SPECIFIC
          }
          onAddNew={(industry: string) => {
            config.values.industry.push(industry);
            config.setFieldValue('industry', config.values.industry);
          }}
          onDelete={(industryId: string) => {
            config.setFieldValue('industry', deleteIndustry(config.values.industry, industryId));
          }}
        />
      ),
    },
    {
      key: 'companies',
      input: (
        <CompanyList
          companies={config.values.companies}
          allCompaniesArray={allCompanies}
          pathI18n={'ADD_NEW_COMPANY_BUTTON'}
          pathI18nHelp={'HELP_TEXT_COMPANIES_LIST'}
          disabled={
            config.values.showType === NewsTypes.ALL ||
            config.values.showType === NewsTypes.INDUSTRY
          }
          showTable={config.values.showType === NewsTypes.SPECIFIC}
          onAddNew={(company: ICompany) => {
            const isContain = config.values.companies.find((element) => element.id === company.id);
            if (!isContain) {
              config.values.companies.push(company);
              config.setFieldValue('companies', config.values.companies);
            }
          }}
          onDelete={(companyId: string) => {
            config.setFieldValue('companies', deleteCompanies(config.values.companies, companyId));
          }}
        />
      ),
    },
  ];
  return inputs;
};
