import { FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { IFormInput } from 'src/components/Form/types';
import SelectInput from 'src/components/Inputs/SelectInput';
import TextInput from 'src/components/Inputs/TextInput';
import { NewsTypes } from 'src/helpers/enums/news';
import { ICompany } from 'src/types/company';
import { INewInsightsComment } from 'src/types/insights';
import CompanyList from 'src/views/News/components/Companies/CompanyList';
import IndustryList from 'src/views/News/components/Industry/IndustryList';

import { deleteCompanies, deleteIndustry } from './helpers';

export const validationSchemaComment = Yup.object({
  signatureName: Yup.string().required('Signature Name is required'),
  signaturePosition: Yup.string(),
  description: Yup.string().required('Description is required'),
  showType: Yup.string().required('Show types is required'),
});

export const getInitialValuesInsightsCommentForm = (InsightToEdit?: INewInsightsComment | null) => {
  return {
    signatureName: InsightToEdit ? InsightToEdit.signatureName : '',
    signaturePosition: InsightToEdit ? InsightToEdit.signaturePosition : '',
    description: InsightToEdit ? InsightToEdit.description : '',
    showType: InsightToEdit ? InsightToEdit.showType : NewsTypes.ALL,
    industries: InsightToEdit ? InsightToEdit.industries : [],
    companies: InsightToEdit ? InsightToEdit.companies : [],
  };
};

export const getInputsFormCommentInsights = (
  config: FormikProps<INewInsightsComment>,
  allCompanies: ICompany[],
  allIndustries: string[],
) => {
  const inputs: IFormInput[] = [
    {
      key: 'signatureName',
      input: (
        <TextInput
          id="signatureName"
          name="signatureName"
          placeholder="Person Name"
          error={
            config.touched.signatureName && Boolean(config.errors.signatureName)
              ? (config.errors.signatureName as string)
              : ''
          }
          value={config.values.signatureName}
          isDisabled={false}
          onChange={config.handleChange}
          pathI18nHelp="HELP_TEXT_COMMENT_PERSON_NAME"
        />
      ),
    },
    {
      key: 'signaturePosition',
      input: (
        <TextInput
          id="signaturePosition"
          name="signaturePosition"
          placeholder="Job title"
          error={
            config.touched.signaturePosition && Boolean(config.errors.signaturePosition)
              ? (config.errors.signaturePosition as string)
              : ''
          }
          value={config.values.signaturePosition}
          isDisabled={false}
          onChange={config.handleChange}
          pathI18nHelp="HELP_TEXT_COMMENT_JOB_TITLE"
        />
      ),
    },
    {
      key: 'description',
      input: (
        <TextInput
          id="description"
          name="description"
          placeholder="Description"
          multiline={true}
          multilineRows={5}
          error={
            config.touched.description && Boolean(config.errors.description)
              ? (config.errors.description as string)
              : ''
          }
          value={config.values.description}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'showType',
      input: (
        <SelectInput
          id="showType"
          name="showType"
          label="Show Types"
          error={
            config.touched.showType && Boolean(config.errors.showType)
              ? (config.errors.showType as string)
              : ''
          }
          pathI18nHelp={'HELP_TEXT_SHOW_TYPES'}
          value={config.values.showType}
          options={[
            {
              value: NewsTypes.ALL,
              text: NewsTypes.ALL,
            },
            { value: NewsTypes.SPECIFIC, text: NewsTypes.SPECIFIC },
            {
              value: NewsTypes.INDUSTRY,
              text: NewsTypes.INDUSTRY,
            },
          ]}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'industries',
      input: (
        <IndustryList
          industry={config.values.industries}
          allIndustries={allIndustries}
          disabled={
            config.values.showType === NewsTypes.ALL ||
            config.values.showType === NewsTypes.SPECIFIC
          }
          pathI18nHelp={'HELP_TEXT_INDUSTRY_LIST'}
          onAddNew={(industry: string) => {
            config.values.industries.push(industry);
            config.setFieldValue('industries', config.values.industries);
          }}
          onDelete={(industryId: string) => {
            config.setFieldValue(
              'industries',
              deleteIndustry(config.values.industries, industryId),
            );
          }}
        />
      ),
    },
    {
      key: 'companies',
      input: (
        <CompanyList
          companies={config.values.companies}
          allCompaniesArray={allCompanies}
          pathI18n={'ADD_NEW_COMPANY_BUTTON'}
          pathI18nHelp={'HELP_TEXT_COMPANIES_LIST'}
          disabled={
            config.values.showType === NewsTypes.ALL ||
            config.values.showType === NewsTypes.INDUSTRY
          }
          showTable={config.values.showType === NewsTypes.SPECIFIC}
          onAddNew={(company: ICompany) => {
            const isContain = config.values.companies.find((element) => element.id === company.id);
            if (!isContain) {
              config.values.companies.push(company);
              config.setFieldValue('companies', config.values.companies);
            }
          }}
          onDelete={(companyId: string) => {
            config.setFieldValue('companies', deleteCompanies(config.values.companies, companyId));
          }}
        />
      ),
    },
  ];
  return inputs;
};
