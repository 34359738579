import React from 'react';

import { ActionTypes } from 'src/helpers/enums/tableActions';

import AddActionButton from '../Add';
import DeleteActionButton from '../Delete';
import { ActionButtonsWrapper, Title, ToolbarWrapper } from './styles';
import { IToolbarProps } from './types';

const Toolbar = (props: IToolbarProps) => {
  const {
    title,
    onAddNew,
    onBulkDelete,
    toolbarActions = [ActionTypes.CREATE, ActionTypes.BULK_DELETE],
  } = props;

  return (
    <ToolbarWrapper>
      <Title>{title}</Title>
      <ActionButtonsWrapper>
        {toolbarActions.includes(ActionTypes.CREATE) && (
          <AddActionButton
            onClick={onAddNew}
            label="Add new"
            disabled={!toolbarActions.includes(ActionTypes.CREATE)}
          />
        )}
        {toolbarActions.includes(ActionTypes.BULK_DELETE) && (
          <DeleteActionButton
            onClick={onBulkDelete}
            label="Bulk delete"
            disabled={!toolbarActions.includes(ActionTypes.BULK_DELETE)}
          />
        )}
      </ActionButtonsWrapper>
    </ToolbarWrapper>
  );
};

export default Toolbar;
