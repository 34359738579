import i18next from 'i18next';
import React from 'react';

import Table from 'src/components/Table';

import './i18n';
import { NewsQuerysContainer, NewsQuerysContent, RunQuery } from './styles';
import { INewsQuerysListProps } from './types';

const NewsQueryList = (props: INewsQuerysListProps) => {
  const {
    columns,
    error,
    isLoading,
    newsQuery,
    onAddNew,
    onEdit,
    onDeleteMany,
    onDeleteOne,
    onRunQuery,
  } = props;
  const customRowActions = [
    {
      button: <RunQuery />,
      disabled: () => false,
      loading: isLoading,
      tooltip: i18next.t('NEWS_QUERYS_LIST:RUN_QUERY'),
      onClick: onRunQuery,
    },
  ];

  return (
    <NewsQuerysContainer data-testid="news-view">
      <NewsQuerysContent>
        <Table
          columns={columns}
          error={error}
          rows={newsQuery}
          title={i18next.t('NEWS_QUERYS_LIST:TITLE')}
          loading={isLoading}
          autoHeight
          customRowActions={customRowActions}
          rowHeight={100}
          onAddNew={onAddNew}
          onEdit={onEdit}
          onDeleteOne={onDeleteOne}
          onDeleteMany={onDeleteMany}
        />
      </NewsQuerysContent>
    </NewsQuerysContainer>
  );
};

export default NewsQueryList;
