import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'INDUSTRY', {
  TITLE: 'Industry',
  ADD_NEW_BUTTON: 'Add industry',
  TABLE_TITLE: 'Industry',
  HELP_TEXT_INDUSTRY_LIST:
    'Add, edit or remove the industries that will see this information. It must be at least one if the showType is "Industry".',
});
