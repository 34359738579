import { GridColumns, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as companyActions from 'src/redux/actions/company';
import companiesListSelector from 'src/redux/selectors/company/companiesList';
import companyInfoSelector from 'src/redux/selectors/company/companyInfo';
import totalCompaniesSelector from 'src/redux/selectors/company/totalCompanies';

import history from 'src/helpers/history';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { ICompany } from 'src/types/company';
import IReducerInfo from 'src/types/reducer';
import CompaniesList from 'src/views/Companies/CompaniesList';
import { CompanyLogo } from 'src/views/Companies/CompaniesList/styles';

import LayoutContainer from '../Layout/Layout';

const CompanyListContainer = () => {
  const dispatch = useDispatch();

  const totalCompanies = useTypedSelector(totalCompaniesSelector());
  const companies: GridRowModel[] = useTypedSelector(companiesListSelector());
  const companyInfo: IReducerInfo = useTypedSelector(companyInfoSelector());
  const columns: GridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'industry',
      headerName: 'Industry',
      flex: 1,
    },
    {
      field: 'logo',
      headerName: 'Logo',
      flex: 1,
      align: 'center',
      renderCell: (params: GridRenderCellParams<string>) => <CompanyLogo src={params.value} />,
    },
  ];

  const onEdit = (rowId: string) => {
    history.push({
      pathname: `companies/edit/${rowId}`,
    });
  };

  const onDeleteOne = (rowId: string) => {
    dispatch(companyActions.onDeleteOneRequested(rowId));
  };

  const onDeleteMany = (rowsId: string[]) => {
    dispatch(companyActions.onDeleteManyRequested(rowsId));
  };

  const onFilter = (filter: Partial<ICompany>, page: number) => {
    dispatch(companyActions.onFilterRequested(filter, page));
  };

  const onAddNew = () => {
    history.push({
      pathname: '/companies/new',
    });
  };

  const onInitializeCompaniesView = useCallback(() => {
    dispatch(companyActions.initializeCompaniesListView());
  }, [dispatch]);

  useEffect(() => {
    onInitializeCompaniesView();
  }, [onInitializeCompaniesView]);

  return (
    <LayoutContainer>
      <CompaniesList
        columns={columns}
        error={companyInfo.info.error ? companyInfo.message : null}
        isLoading={companyInfo.info.loading}
        companies={companies}
        totalCompanies={totalCompanies}
        onFilter={onFilter}
        onAddNew={onAddNew}
        onEdit={onEdit}
        onDeleteOne={onDeleteOne}
        onDeleteMany={onDeleteMany}
      />
    </LayoutContainer>
  );
};

export default CompanyListContainer;
