import MuiModal from '@mui/material/Modal';
import React from 'react';

import { ModelContent } from './styles';
import { IModalProps } from './types';

const Modal = (props: IModalProps) => {
  const { open, onClose, children } = props;

  return (
    <MuiModal data-testid="modal-block" open={open} onClose={onClose}>
      <ModelContent>{children}</ModelContent>
    </MuiModal>
  );
};

export default Modal;
