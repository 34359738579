import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const totalCompaniesSelector = () =>
  createSelector(
    [(state: IReduxState) => state.company.totalCompanies],
    (totalCompanies) => totalCompanies,
  );

export default totalCompaniesSelector;
