import i18next from 'i18next';
import React from 'react';

import Form from 'src/components/Form';

import './i18n';
import { FormWrapper, Title } from './styles';
import { ICompaniesNewProps } from './types';

const CompaniesNew = (props: ICompaniesNewProps) => {
  const { loading, inputs, onSubmitForm } = props;

  return (
    <FormWrapper>
      <Title> {i18next.t('COMPANY_NEW:TITLE')}</Title>
      <Form
        inputs={inputs}
        onSubmit={onSubmitForm}
        isLoading={loading}
        submitText={i18next.t('COMPANY_NEW:SUBMIT_BUTTON')}
      />
    </FormWrapper>
  );
};

export default CompaniesNew;
