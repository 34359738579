import { INewQuery, INewsQuery } from 'src/types/newsQuery';
import { removeExtraData } from 'src/views/NewsQuerys/common/helpers';

import { apiDelete, get, post, put } from './api';

export const getAll = () => {
  return get('newsQuerys');
};

export const deleteMany = (newsQuerysIds: string[]) => {
  return apiDelete('newsQuerys', {}, { newsQuerysIds });
};

export const getOne = (newsQueryId: string) => {
  return get(`newsQuerys/${newsQueryId}`);
};

export const deleteOne = (newsQueryId: string) => {
  return apiDelete(`newsQuerys/${newsQueryId}`);
};

export const editOne = (newsQuerysId: string, newsQuery: Partial<INewsQuery>) => {
  const newQuery = removeExtraData(newsQuery);
  return put(`newsQuerys/${newsQuerysId}`, {
    ...newQuery,
    companies: newQuery.companies?.map((c) => c.id) || [],
  });
};

export const createOne = (newNewsQuery: INewQuery) => {
  const newQuery = removeExtraData(newNewsQuery);
  return post('newsQuerys', {
    ...newQuery,
    companies: newQuery.companies?.map((c) => c.id) || [],
  });
};

export const runQuery = (newsQueryId: string) => {
  return post(`newsQuerys/run/${newsQueryId}`, {});
};
