import { FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { IFormInput } from 'src/components/Form/types';
import FileInput from 'src/components/Inputs/FileInput';
import SelectInput from 'src/components/Inputs/SelectInput';
import TextInput from 'src/components/Inputs/TextInput';
import { ICompany, INewCompany } from 'src/types/company';
import { IProviderScore, ScoreProviders } from 'src/types/score';
import { deleteCompanies } from 'src/views/News/common/helpers';
import CompanyList from 'src/views/News/components/Companies/CompanyList';

import CountrySelect from '../CompaniesEdit/components/CountrySelect.ts';
import ScoreList from '../CompaniesEdit/components/ScoreList';
import { addNewScore, deleteScore, editScore } from './helpers';

export const validationSchema = Yup.object({
  name: Yup.string().required('Company name is required').trim(),
  industry: Yup.string().required('Industry is required').trim(),
});

export const getInitialValuesCompanyForm = (companyToEdit?: ICompany | null) => {
  return {
    name: companyToEdit ? companyToEdit.name : '',
    logo: companyToEdit ? companyToEdit.logo : ('' as string | File),
    country: companyToEdit ? companyToEdit.country : '',
    industry: companyToEdit ? companyToEdit.industry : '',
    scores: companyToEdit ? companyToEdit.scores : [],
    competitors: companyToEdit ? companyToEdit.competitors : [],
  };
};

export const getInputsFormCompany = (
  config: FormikProps<INewCompany>,
  allCompanies: ICompany[],
  industries: string[],
) => {
  const inputs: IFormInput[] = [
    {
      key: 'name',
      input: (
        <TextInput
          id="name"
          name="name"
          placeholder="Company Name"
          error={
            config.touched.name && Boolean(config.errors.name) ? (config.errors.name as string) : ''
          }
          value={config.values.name}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'logo',
      input: (
        <FileInput
          id="logo"
          name="logo"
          value={config.values.logo}
          placeholder="Logo"
          error={
            config.touched.logo && Boolean(config.errors.logo) ? (config.errors.logo as string) : ''
          }
          onChange={(newFile: File | string) => {
            config.setFieldValue('logo', newFile);
          }}
        />
      ),
    },
    {
      key: 'country',
      input: (
        <CountrySelect
          value={config.values.country}
          onChange={(country: string) => config.setFieldValue('country', country)}
          error={
            config.touched.country && Boolean(config.errors.country)
              ? (config.errors.country as string)
              : ''
          }
          isDisabled={false}
        />
      ),
    },
    {
      key: 'industry',
      input: (
        <SelectInput
          id="industry"
          name="industry"
          label="Industry"
          error={
            config.touched.industry && Boolean(config.errors.industry)
              ? (config.errors.industry as string)
              : ''
          }
          value={config.values.industry}
          disabled={false}
          onChange={config.handleChange}
          options={industries.map((i) => ({
            value: i,
            text: i,
          }))}
        />
      ),
    },
    {
      key: 'scores',
      input: (
        <ScoreList
          scores={config.values.scores}
          onAddNew={(provider: ScoreProviders, score: IProviderScore) =>
            config.setFieldValue('scores', addNewScore(config.values.scores, provider, score))
          }
          onDelete={(scoreId: string) => {
            config.setFieldValue('scores', deleteScore(config.values.scores, scoreId));
          }}
          onEdit={(provider: ScoreProviders, newScore: IProviderScore) => {
            config.setFieldValue('scores', editScore(config.values.scores, provider, newScore));
          }}
        />
      ),
    },
    {
      key: 'competitors',
      input: (
        <CompanyList
          showTable
          companies={config.values.competitors}
          allCompaniesArray={allCompanies}
          disabled={config.values.competitors.length === 5}
          pathI18n={'ADD_NEW_COMPETITORS_BUTTON'}
          pathI18nHelp={'HELP_TEXT_COMPETITORS'}
          onAddNew={(newCompany: ICompany) => {
            const isContain = config.values.competitors.find(
              (element) => element.id === newCompany.id,
            );
            if (!isContain) {
              config.values.competitors.push(newCompany);
              config.setFieldValue('competitors', config.values.competitors);
            }
          }}
          onDelete={(companyId: string) => {
            config.setFieldValue(
              'competitors',
              deleteCompanies(config.values.competitors, companyId),
            );
          }}
        />
      ),
    },
  ];
  return inputs;
};
