import { InputAdornment } from '@mui/material';
import React, { ChangeEvent } from 'react';

import { ImageContainer, ImageUpload, Input } from './styles';
import { IFileInputProps } from './types';

const FileInput = (props: IFileInputProps) => {
  const {
    adornment = null,
    error = '',
    onlyImages = true,
    id,
    name,
    placeholder,
    onChange,
    value,
  } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0] !== undefined) {
      onChange(event.target.files[0]);
    }
  };

  const handleDelete = () => {
    onChange('');
  };

  return (
    <>
      <Input
        data-testid="text-field"
        inputProps={{
          'data-testid': 'input',
          accept: onlyImages ? 'image/png, image/jpeg, image/jpg' : '*',
        }}
        fullWidth
        error={error !== ''}
        id={id}
        name={name}
        label={placeholder}
        helperText={error}
        type="file"
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <>{adornment && <InputAdornment position="start">{adornment}</InputAdornment>}</>
          ),
        }}
      />
      {value !== '' && value !== undefined && (
        <ImageContainer
          avatar={
            <ImageUpload src={typeof value === 'string' ? value : URL.createObjectURL(value)} />
          }
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

export default FileInput;
