import styled from 'styled-components';

export const FilterWrapper = styled.div`
  width: 25%;
  max-width: 400px;
  height: auto;
  background-color: ${(props) => props.theme.palette.common.white};
  padding: 10px 20px;
`;

export const FilterItemWrapper = styled.div`
  margin: 20px 0;
  background-color: ${(props) => props.theme.palette.common.white};
`;
