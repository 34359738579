import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import history from 'src/helpers/history';

import authReducer from './auth';
import companyReducer from './company';
import insightsReducer from './insights';
import materialityReducer from './materiality';
import newsReducer from './news';
import newsDraftReducer from './newsDraft';
import newsQuerysReducer from './newsQuerys';
import userReducer from './user';

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  user: userReducer,
  company: companyReducer,
  news: newsReducer,
  newsDraft: newsDraftReducer,
  insights: insightsReducer,
  newsQuerys: newsQuerysReducer,
  materiality: materialityReducer,
});

export default rootReducer;
