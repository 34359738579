import styled from 'styled-components';

export const FormWrapper = styled.div`
  max-width: 800px;
  padding: 30px;
  margin: 30px auto;
`;

export const PublishButtonWrapper = styled.div`
  margin: 0 auto;
  width: 120px;
`;

export const Title = styled.h2`
  font-size: 28px;
  color: ${(props) => props.theme.palette.secondary};
  text-align: center;
  margin-bottom: 30px;
`;
