import { Add } from '@mui/icons-material';
import styled from 'styled-components';

import { ILabelProps } from './types';

export const Label = styled.p<ILabelProps>`
  margin-left: 5px;
  font-size: 18px;
  color: ${(props) => (props.isDisabled ? 'grey' : props.theme.palette.secondary)};
`;

export const AddIcon = styled(Add)`
  color: ${(props) => props.theme.palette.secondary};
`;
