import { GridColumns } from '@mui/x-data-grid';
import i18next from 'i18next';
import React, { useState } from 'react';
import uniqid from 'uniqid';

import Modal from 'src/components/Modal';
import Table from 'src/components/Table';
import AddActionButton from 'src/components/Table/components/Add';
import { ScoreProviders, TEMPORARY_ID_PREFIX } from 'src/types/score';

import ScoreWizard from '../ScoreWizard';
import './i18n';
import { ScoresWrapper, ScoresList, Label } from './styles';
import { IScoreListProps, IScoreRow } from './types';

const ScoreList = (props: IScoreListProps) => {
  const { scores, onAddNew, onDelete, onEdit } = props;
  const [showWizard, setShowWizard] = useState(false);
  const [scoreToEdit, setScoreToEdit] = useState<IScoreRow | undefined>(undefined);

  const handleAddConfirm = (provider: ScoreProviders, score: string, scoreName: string) => {
    onAddNew(provider, {
      id: uniqid(TEMPORARY_ID_PREFIX),
      score,
      scoreName,
    });
    setShowWizard(false);
  };

  const handleAddNew = () => {
    setScoreToEdit(undefined);
    setShowWizard(true);
  };

  const handleEditConfirm = (provider: ScoreProviders, newScore: string, newScoreName: string) => {
    onEdit(provider, {
      id: scoreToEdit?.id!,
      score: newScore,
      scoreName: newScoreName,
    });
    setShowWizard(false);
  };

  const handleDelete = (scoreId: string) => {
    onDelete(scoreId);
  };

  const handleEdit = (scoreId: string) => {
    const scoreToEdit = rows.find((row) => row.id === scoreId);
    setScoreToEdit(scoreToEdit);
    setShowWizard(true);
  };

  const columns: GridColumns = [
    {
      field: 'scoreProvider',
      headerName: 'Score provider',
      flex: 1,
    },
    {
      field: 'scoreLabel',
      headerName: 'Score Label',
      flex: 1,
    },
    {
      field: 'score',
      headerName: 'Score',
      flex: 1,
    },
  ];

  const rows: IScoreRow[] = scores
    .map((providerScores) => {
      return providerScores.scores.map((score) => ({
        id: score.id,
        scoreProvider: providerScores.provider,
        scoreLabel: score.scoreName,
        score: score.score,
      }));
    })
    .flat();

  return (
    <ScoresWrapper>
      <Modal open={showWizard} onClose={() => setShowWizard(false)}>
        <ScoreWizard
          onAddNew={handleAddConfirm}
          onEdit={handleEditConfirm}
          defaultScore={scoreToEdit}
        />
      </Modal>
      <ScoresList>
        {rows.length > 0 && (
          <>
            <Label>{i18next.t('SCORE_LIST:TITLE')}</Label>
            <Table
              columns={columns}
              rows={rows}
              title={i18next.t('SCORE_LIST:TITLE')}
              loading={false}
              toolbar={false}
              pageSize={5}
              includeCheckboxes={false}
              autoHeight
              onEdit={handleEdit}
              onDeleteOne={handleDelete}
            />
          </>
        )}
      </ScoresList>
      <AddActionButton
        label={i18next.t('SCORE_LIST:ADD_NEW_BUTTON')}
        onClick={handleAddNew}
        helpText={i18next.t('SCORE_LIST:HELP_TEXT')}
      />
    </ScoresWrapper>
  );
};

export default ScoreList;
