import { GridColumns } from '@mui/x-data-grid';
import i18next from 'i18next';
import React, { useState } from 'react';

import Modal from 'src/components/Modal';
import Table from 'src/components/Table';
import AddActionButton from 'src/components/Table/components/Add';
import { ActionTypes } from 'src/helpers/enums/tableActions';

import NewIndustryWizard from '../NewIndustryWizard';
import './i18n';
import { IndustyWrapper, IndustyList } from './styles';
import { IndustryListProps } from './types';

const IndustryList = (props: IndustryListProps) => {
  const { industry, onAddNew, disabled, onDelete, allIndustries, pathI18nHelp = '' } = props;
  const [showWizard, setShowWizard] = useState(false);

  const handleAddNew = (industry: string) => {
    onAddNew(industry);
    setShowWizard(false);
  };

  const columns: GridColumns = [
    {
      field: 'industry',
      headerName: 'Industry',
      flex: 1,
    },
  ];
  const rows = industry
    .map((element) => {
      return {
        id: element,
        industry: element,
      };
    })
    .flat();

  const handleDelete = (industryId: string) => {
    onDelete(industryId);
  };

  return (
    <IndustyWrapper>
      <Modal open={showWizard} onClose={() => setShowWizard(false)}>
        <NewIndustryWizard onAddNew={handleAddNew} industries={allIndustries} />
      </Modal>
      <IndustyList>
        {rows.length > 0 && !disabled && (
          <>
            <Table
              columns={columns}
              rows={rows}
              title={i18next.t('INDUSTRY:TABLE_TITLE')}
              loading={rows.some((row) => !row.id)}
              toolbar={false}
              pageSize={5}
              includeCheckboxes={false}
              autoHeight
              onDeleteOne={handleDelete}
              rowActions={[ActionTypes.DELETE]}
            />
          </>
        )}
      </IndustyList>
      <AddActionButton
        label={i18next.t('INDUSTRY:ADD_NEW_BUTTON')}
        onClick={() => setShowWizard(true)}
        disabled={disabled}
        helpText={i18next.t(`INDUSTRY:${pathI18nHelp}`)}
      />
    </IndustyWrapper>
  );
};

export default IndustryList;
